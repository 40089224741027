import React from "react";
import {createRoot} from "react-dom/client";
import { BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import store, {persistor} from "./redux/store";
import './assets/fonts/Roboto-Regular.ttf'
import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
      <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </BrowserRouter>
    </Provider>,
  // console.log = console.warn = console.error = () => {},
  );
