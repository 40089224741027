import React, { useEffect, useState } from "react";
import Aside from "./Aside/Aside";
import Map from "./Map/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faThumbsUp, faUserCheck, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { BsChatLeftTextFill, BsThreeDotsVertical } from "react-icons/bs";
import "../../assets/styles/Profile/Profile.css";
import { useNavigate } from "react-router-dom";
import { MdApartment, MdLocalPhone, MdOutlineLanguage } from "react-icons/md";
import axios from "axios";
import { useSelector } from "react-redux";
import { Server2, Socket } from "../../redux/actions/auth/auth.actions";
import GalleryProfile from "../../components/GalleryProfile";
import { Dropdown } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import CoverImage from "../My-Profile/CoverImage";
let screenSize = window.innerWidth;

const UserProfile = ({ Data }) => {
  const userdata = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const UserData =  Data;
  const intrest = UserData.profile.interests;
  const langs = UserData.profile.languages;
  const [RequestCheck, setRequestCheck] = useState("");
  const [RequestAccept, setRequestAccept] = useState("");
  const [UserFollower, setUserFollower] = useState("");
  const [UserFriend, setUserFriend] = useState("");
  const [blockItems, setBlockItems] = useState([]);
  const [chatBox, setChatBox] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [UserFriends, setUserFriends] = useState([]);
  const [GallaryImg, setGallaryImg] = useState([]);
  const [NewData, setNewData] = useState();
  const [UpdateProfile, setUpdateProfile] = useState(false);
  const [ImageProfile, setImageProfile] = useState("");
  const [ImageCover, setImageCover] = useState("");
  const [ProfilePicView, setProfilePicView] = useState(false);
  const [CoverPicView, setCoverPicView] = useState(false);

  useEffect(() =>{
    const handler = (data) =>{
      console.log("i, socket, am working, ", data)
      setUpdateProfile(true) 
      if(data.friend === true){
        setUserFriend("Friend")
        setRequestCheck("");
        setRequestAccept("");
      }
      else if(data.friend === false)
        setUserFriend("")
      else if(data.follow === true)
        setUserFollower("Follower")
      else if(data.follow === false)
        setUserFollower("")
    }
    Socket.on("loadProfile",handler)
    return () => {
      Socket.off("loadProfile",handler)
    }
  },[Socket])
  useEffect(() => {
    GetUserBlock();
    GetUserData();
    var config = {
      method: "get",
      url: Server2 + `/users/ProfileFriends?id=${userdata?._id}&profile=${UserData?._id}`,
    };
    axios(config)
      .then(function (response) {
        setUserFriends([]);
        if (response.data) {
          setUserFriends(response.data);
        }
        response.data.map((e) => {
          if (UserData.type === true) {
            if (e?._id === userdata?._id) {
              setUserFollower("Follower");
            }
          }
          return ""
        });
        response.data.map((e) => {
          if (UserData.type === false) {
            if (e?._id === userdata?._id) {
              setUserFriend("Friend");
            }
          }
          return ""
        });
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });

    let data = {
      user: UserData._id,
    };
    var config2 = {
      method: "post",
      url: Server2 + "/users/get-gallery-images",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config2)
      .then((response) => {
        setGallaryImg(response.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
    getOpeningHours()
    setUpdateProfile(false)
  }, [UpdateProfile]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClick = (e) => {
    navigate(`/Profiless/${e?._id}`, {
      state: {
        item: e._id,
      },
    });
  };
  const GetUserData = async () => {
    await fetch(
      Server2 + `/users/userprofile?id=${userdata._id}&user=${UserData._id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then(async (response) => {
      const data = await response.json();
      data?.user?.friend_requests_receive.map((e) => {
        if (e === userdata._id) {
          setRequestCheck("Friend");
        }
        return("");
      });
      data?.user?.friend_requests_sent.map((e) => {
        if (e === userdata._id) {
          setRequestAccept("Accept");
        }
        return("");
      });
    });
  };

  const handleModelClose = (isChanged) => {
    setCoverPicView(false)
    setProfilePicView(false)
    console.log(isChanged,"picture changed")
    if(isChanged)
      console.log("first")
      // updateNew()
  }

  const FollowPage = () => {
    var data = JSON.stringify({
      id: userdata._id,
      user: UserData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/users/follow-business",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setUserFollower("Follower");
          const data = {
          notify: UserData?.notifications?.friend_requests,
          to: UserData?._id,
          from: userdata?._id,
          follow: true
        };
        Socket.emit("follow-request", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const UnFollowPage = () => {
    var data = JSON.stringify({
      id: userdata._id,
      user: UserData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/users/unfollow-business",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setUserFollower("")
        setConfirmOpen(false)
        var data = {
          from: userdata?._id,
          follow: false
        };
        Socket.emit("unfollow-request", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const FriendRequest = () => {
    setRequestCheck("");
    var data = JSON.stringify({
      id: userdata._id,
      friend: UserData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/users/friend-request",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setRequestCheck("Friend");
        const data = {
          notify: UserData?.notifications?.friend_requests,
          to: UserData?._id,
          from: userdata?._id,
          friend:"Friend"
        };
        Socket.emit("friend-request", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const AcceptFriendRequest = () => {
    setUserFriend("");
    var data = JSON.stringify({
      id: userdata._id,
      user: UserData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/users/accept-friend-request",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setUserFriend("Friend");
        setRequestAccept("");
        const data = {
          to: UserData?._id,
          from: userdata?._id,
          friend: true
        };
        Socket.emit("accept-request", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const UnFriend = () => {
    setUserFriend("");
    var data = JSON.stringify({
      id: userdata?._id,
      user: UserData?._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/users/unfriend",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setTimeout(() => {
          setConfirmOpen(false)
        }, 1000)
        const data = {
          to: UserData?._id,
          from: userdata?._id,
          friend: false
        };
        Socket.emit("unfriend-request", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetUserBlock = () => {
    var config = {
      method: "GET",
      url: Server2 + `/users/getBlockList?id=${userdata?._id}`,
    };
    axios(config)
      .then((response) => {
        console.log("respnose use block list", response.data);
        setBlockItems(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  // both blocks and unblocks a user
  const blockUser = (e) => {

    var config;
    let data = JSON.stringify({
      id: e,
      block_user: UserData?._id,
      unblock_user: UserData?._id,
    });

    if(blockItems.some(user => {return user._id === UserData._id})){
      config = {
        method: "post",
        url: Server2 + "/users/unblock",
        headers: { "Content-Type": "application/json" },
        data: data,
      };
      console.log("yes", blockItems.some(user => {return user._id === UserData._id}))
    } else {
      config = {
        method: "post",
        url: Server2 + "/users/block",
        headers: { "Content-Type": "application/json" },
        data: data,
      };
      blockItems.map(user => {console.log(user._id);return(user)})
      console.log("no", userdata._id)
    }

    axios(config)
      .then(function (response) {
        console.log(response.data, "BlockUser");
        GetUserBlock()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getOpeningHours = () => {
    fetch(
      Server2 + `/users/userprofile?id=${userdata._id}&user=${UserData._id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then(async (response) => {
      const data = await response.json();
      setNewData(data.user)
    });
  }
  // const refCheck = useRef(null);
  // useLayoutEffect(() => {
  //   console.log(refCheck.current.offsetWidth);
  //   console.log(refCheck.current.offsetHeight, "height");
  // }, []);
  return (
    <>
      <div>
        {UserData?.privacy?.profile_info_private === true && UserFriend === "" && UserFollower === "" ? (
          <>
            <div style={{ height: "80vh" }}>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "coloum",
                  margin: "auto",
                }}
                className="fs-5 mt-4"
              >
                This user’s Profile is private
              </p>
            </div>
          </>
        ) : (
          <div className="px-3 bg-white">
            <div>
              {/* Cover Image */}
              <div className="img-cover rounded-3 ">
                <img
                  className="cover"
                  src={UserData?.cover_pic ? UserData?.cover_pic : "/Images/dummy2.png"}
                  alt='user`s coverImage'
                  style={{cursor: 'pointer', maxHeight: screenSize < 768 ? "160px" : "400px"}}
                  onClick={(e) => {
                    setCoverPicView(true)
                    setImageCover(UserData?.cover_pic ? UserData?.cover_pic : "/Images/dummy2.png");
                  }}
                />
              </div>

              {/* Profile Image and Name */}
              <div className="profile row d-flex px-0 justify-content-center align-items-center">
                <div className="col-lg-2 col-md-3 col-12 order-md-1 order-2 text-center text-md-end">
                  <div style={{minWidth: 175}}>
                  <img
                    className="img-admin img-fluid shadow"
                    src={UserData?.profile_pic ? UserData?.profile_pic : "/img/upload-photo.png"}
                    alt='User`s ProfileImage'
                    style={{cursor: 'pointer', minWidth: 160}}
                    onClick={(e) => {
                      setProfilePicView(true)
                      setImageProfile(UserData?.profile_pic ? UserData?.profile_pic : "/img/upload-photo.png");
                    }}
                  />
                  {UserData?.is_online === true ?
                    <img
                      src="/Images/contact-online.png"
                      className=""
                      style={{
                          position: "relative",
                          left: "-15px",
                          top: "40px"
                        }}
                      height={14}
                      alt="dot sign"
                    /> :
                    <img
                      src="/Images/contact-idle.png"
                      className=""
                      style={{
                          position: "relative",
                          left: "-15px",
                          top: "40px"
                        }}
                      height={14}
                      alt="dot sign"
                    />
                  }
                  </div>
                </div>
                <div className="col-lg-5 col-md-3 col-sm-12 order-md-2 order-1 pt-3 ">
                  <p
                    className="fw-bold fs-3 text-lg-start text-md-start text-center text-white"
                    style={{ textShadow: "1px 1px #000000" }}
                  >
                    {UserData.fname} {UserData.lname}
                  </p>
                </div>
                <div className="col-lg-5 col-md-6 order-md-2 "></div>
              </div>

              {/* Friend Requests / Follows */}
              <div className="row add-chat">
                {/* Side Panel starts */}
                <div className="offset-md-8 offset-7 col-md-4 col-5 pb-3 pt-4 pt-sm-0">
                  { confirmOpen === false ? null :
                    <div className="text-center" style={{ backgroundColor: "#d2f8d2", borderRadius: "8px" }}>
                      <p className="pt-1"> Are you sure you want to {UserData.type === true ? "unfollow" : "unfriend"}  {UserData?.fname} {UserData?.lname} ?
                      </p>
                      <div className="d-flex justify-content-center flex-column flex-lg-row align-items-center gap-3 pb-2 mb-1">
                        <button className="border-0 rounded-2 py-2 px-md-3 px-2 " onClick={() => setConfirmOpen(false)}>Cancel</button>
                        <button className="border-0 rounded-2 p-2 bg-primary text-white" onClick={() => {
                          UserData.type === true ? UnFollowPage() : UnFriend()
                        }}>Confirm</button>
                      </div>
                    </div>
                  }
                  <div className="d-flex justify-content-end align-items-center">
                    {UserData.type === true ? (
                      UserFollower === "Follower" ? (
                        <Dropdown className="border-0 rounded-2 add-btn w-50 fw-bold">
                          <Dropdown.Toggle  className="w-100 border-0" id="dropdown-basic" aria-expanded="false" style={{backgroundColor:"#6148a1"}}>
                            <button className="border-0 rounded-2 add-btn w-50 fw-bold" data-bs-toggle="button">
                              { Loading === true ? 
                                <div className="d-flex justify-content-center" >
                                  <div className="loading3"></div>
                                </div>
                              :
                                <div className="d-flex align-items-center gap-2 justify-content-center">
                                  <div>
                                    <FaCheck className="btn-icons"/>
                                  </div>
                                  <span className="d-none d-lg-inline d-xl-inline postition-relative">
                                    Following
                                    <AiFillCaretDown style={{ position: "absolute", right: "10%", top: "35%" }} />
                                  </span>
                                </div>
                              }
                            </button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100 text-center">
                            <Dropdown.Item eventKey="1" onClick={() => setConfirmOpen(true)}>
                              <FontAwesomeIcon className="btn-icons me-2 fw-bold" icon={faThumbsDown} /> Unfollow
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <button type="button" className="border-0 rounded-2 add-btn w-50 p-2 fw-bold" data-bs-toggle="button" onClick={() => FollowPage()} >
                          { Loading=== true ?
                            <div className="d-flex justify-content-center">
                              <div className="loading3"></div>
                            </div>
                          :
                            <div className="d-flex align-items-center gap-3 justify-content-center">
                              <div>
                                <FontAwesomeIcon className="btn-icons fw-bold" icon={faThumbsUp} />
                              </div>
                              <span className="d-none d-lg-inline d-xl-inline">
                                Follow
                              </span>
                            </div>
                          }
                        </button>
                      )
                    ) : RequestCheck === "Friend" && userdata.type === false ? (
                      <button
                        type="button"
                        className="border-0 rounded-2 add-btn w-50 p-2 fw-bold"
                        data-bs-toggle="button"
                      >
                        <FontAwesomeIcon
                          className="btn-icons me-2 fw-bold"
                          icon={faUserCheck}
                        />
                        <span className="d-none d-lg-inline d-xl-inline">
                          Friend Request Sent
                        </span>
                      </button>
                    ) : RequestAccept === "Accept" && userdata.type === false ? (
                      <button
                        type="button"
                        className="border-0 rounded-2 add-btn w-50 p-2 fw-bold"
                        data-bs-toggle="button"
                        onClick={() => AcceptFriendRequest()}
                      >
                        <FontAwesomeIcon className="btn-icons me-2 fw-bold" icon={faUserCheck} />
                        <span className="d-none d-lg-inline d-xl-inline">
                          Accept Friend Request
                        </span>
                      </button>
                    ) : UserFriend === "Friend" && userdata.type === false ? (
                      <Dropdown className="border-0 rounded-2 add-btn w-50 fw-bold">
                        <Dropdown.Toggle className="w-100 border-0" id="dropdown-basic" aria-expanded="false" style={{backgroundColor: "#6148a1"}}>
                          <button type="button" className="bg-transparent border-0 text-white" data-bs-toggle="button" >
                            { Loading === true ? 
                              <div className="d-flex justify-content-center">
                                <div className="loading3"></div>
                              </div> 
                            :
                              <>
                                <FontAwesomeIcon className="btn-icons me-2 fw-bold" icon={faUserCheck} />
                                <span className="d-none d-lg-inline d-xl-inline postition-relative">
                                  Friends <AiFillCaretDown style={{ position: "absolute", right: "10%", top: "35%" }} />
                                </span>
                              </>
                            }
                          </button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100 text-center">
                          <Dropdown.Item eventKey="1" onClick={() => setConfirmOpen(true)} >
                            Unfriend
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : userdata.type === false ? (
                      <button
                        type="button"
                        className="border-0 rounded-2 add-btn w-50 p-2 fw-bold"
                        data-bs-toggle="button"
                        onClick={() => FriendRequest()}
                      >
                        { Loading === true ?
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "5%",
                            }}
                          >
                            <div className="loading3"></div>
                          </div> 
                        :
                          <>
                            <FontAwesomeIcon className="btn-icons me-2 fw-bold" icon={faUserPlus} />
                            <span className="d-none d-lg-inline d-xl-inline">
                              Add as Friend
                            </span>
                          </>
                        }
                      </button>
                    ) : null }
                    {/* check for the check: new messages from outside friends group */}
                    {UserData?.privacy?.wall_private === false && UserFriend === "" && UserFollower === "" ? ( null ) : (
                      <button
                        type="button"
                        className=" border-0 rounded-2 chat-btn w-50 ms-2 py-2 px-3 fw-bold"
                        style={{height: 38}}
                        data-bs-toggle="button"
                        onClick={() => setChatBox(true)}
                      >
                        <div className="d-flex justify-content-center gap-2 align-items-center">
                          <BsChatLeftTextFill className="btn-icons fw-bold" />
                          <span className="d-none d-lg-inline d-xl-inline">
                            {UserData.type === false ? "Chat" : "Invite to chat"}
                          </span>
                        </div>
                      </button>
                    )}
                    <Dropdown>
                      <Dropdown.Toggle
                        className="border-0 bg-transparent togglebtn d-flex px-0 px-md-2"
                        style={{maxWidth: 35}}
                        variant=""
                        id="dropdown-basic"
                        aria-expanded="false"
                      >
                        <BsThreeDotsVertical className="" size={20} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        <Dropdown.Item
                          onClick={() => blockUser(userdata._id)}
                        >
                          {blockItems.some((user) => {return user._id === UserData._id})? "Ublock":"Block"}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            {/* About and Gallery */}
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                {/* ref={refCheck} > */}
                {UserData.type === false ? (
                  <>
                    <div
                      className="text-xl-start text-lg-start text-center mx-2 pt-1 pb-2"
                      style={{ height: "200px", overflow: "auto" }}
                    >
                      <p className="fw-bold text-dark">About Me</p>
                      <p
                        className=""
                        style={{
                          fontSize: "12px",
                          font: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {UserData.profile.about_me}
                      </p>
                      <p className="fw-bold text-dark">Summary</p>
                      <div className="">
                        <p
                          className="fw-bold text-dark"
                        >
                          Interests: &nbsp;
                          {intrest.map((inter, index) => {
                            const last = intrest.slice(-1)[0]
                            return (
                              <span
                                key={index}
                                className=""
                                style={{
                                  fontSize: "12px",
                                  font: "Roboto",
                                  fontWeight: "300",
                                }}
                              >
                                {inter}{inter === last ? null : ","} &nbsp;
                              </span>
                            )
                          })}
                        </p>
                        <p className="fw-bold text-dark">
                          Languages: &nbsp;
                          {langs.map((item, index) => {
                            const last = langs.slice(-1)[0]
                            return (

                              <span
                                key={index}
                                style={{
                                  fontSize: "12px",
                                  font: "Roboto",
                                  fontWeight: "300",
                                }}
                              >
                                {item}{item === last ? null : ","} &nbsp;
                              </span>
                            )
                          })}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-2">
                      <p
                        className="fw-bold text-dark ps-2"
                        style={{ fontSize: "16px" }}
                      >
                        Business Summary
                      </p>
                      <p
                        className="text-dark ps-2 mt-1"
                        style={{ fontSize: "12px", lineHeight: "18px" }}
                      >
                        {UserData.business_info.business_summary === null
                          ? " "
                          : UserData.business_info.business_summary}
                      </p>
                    </div>
                    {/* Business Offers */}
                    <div className="border1"></div>
                    <div className="rounded-3 shadow p-3 mb-3">
                      {/* Contacts */}
                      <div className="row text-dark">
                        <div className="pt-2 pe-xxl-4 pe-xl-3 pe-lg-2">
                          <span className="fw-bold fs-6">Business Offer</span>
                          <p className="" style={{
                            minHeight: "30px",
                            maxHeight: "fit-content",
                            // overflow: "auto",
                            marginBottom: "5px",
                          }}>
                            {UserData?.business_info?.about_company.map((about, Index) => {
                              const last = UserData?.business_info?.about_company.slice(-1)[0]
                              return (
                                <span
                                  key={Index}
                                  className=""
                                  style={{
                                    fontSize: "12px",
                                    font: "Roboto",
                                    fontWeight: "300",
                                  }}
                                >
                                  {about}{about === last ? null : ","} &nbsp;
                                </span>
                              )
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="border1"></div>
                    <div className="rounded-3 shadow p-4">
                      {/* Contacts */}
                      <div className="row text-dark mb-2 text-size w-100">
                        <div className="pe-xxl-4 pe-xl-3 pe-lg-2 col-6"  >
                          <span className="fw-bold text-heading-size">Opening Hours</span>
                          <table>
                            {/* <tbody style={{fontSize:"12px"}}> */}
                            <tbody>
                              <tr>
                                <td>Monday</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[0]?.Monday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[0]?.Monday?.opening
                                    ? NewData?.business_info?.business_hours[0]?.Monday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[0]?.Monday?.closing
                                      ? NewData?.business_info?.business_hours[0]?.Monday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td>Tuesday</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[1]?.Tuesday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[1]?.Tuesday?.opening
                                    ? NewData?.business_info?.business_hours[1]?.Tuesday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[1]?.Tuesday?.closing
                                      ? NewData?.business_info?.business_hours[1]?.Tuesday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td>Wednesday&nbsp;</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[2]?.Wednesday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[2]?.Wednesday?.opening
                                    ? NewData?.business_info?.business_hours[2]?.Wednesday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[2]?.Wednesday?.closing
                                      ? NewData?.business_info?.business_hours[2]?.Wednesday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td>Thursday</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[3]?.Thursday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[3]?.Thursday?.opening
                                    ? NewData?.business_info?.business_hours[3]?.Thursday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[3]?.Thursday?.closing
                                      ? NewData?.business_info?.business_hours[3]?.Thursday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td>Friday</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[4]?.Friday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[4]?.Friday?.opening
                                    ? NewData?.business_info?.business_hours[4]?.Friday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[4]?.Friday?.closing
                                      ? NewData?.business_info?.business_hours[4]?.Friday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td>Saturday</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[5]?.Saturday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[5]?.Saturday?.opening
                                    ? NewData?.business_info?.business_hours[5]?.Saturday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[5]?.Saturday?.closing
                                      ? NewData?.business_info?.business_hours[5]?.Saturday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td>Sunday</td>
                                <td>:&nbsp;</td>
                                {NewData?.business_info?.business_hours[6]?.Sunday?.isOff === true ? 
                                  <td>Closed</td> :                            
                                  <td>{NewData?.business_info?.business_hours[6]?.Sunday?.opening
                                    ? NewData?.business_info?.business_hours[6]?.Sunday?.opening : "09:00 AM"}
                                    &nbsp;-&nbsp;
                                    {NewData?.business_info?.business_hours[6]?.Sunday?.closing
                                      ? NewData?.business_info?.business_hours[6]?.Sunday?.closing : "09:00 PM"}
                                  </td>
                                }
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="ps-xxl-5 ps-xl-4 ps-lg-3 col-6">
                          <span className="fw-bold  pb-2 text-heading-size">
                            About Business
                          </span>
                          <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                            <MdLocalPhone
                              className="me-2 icon-size"
                              style={{minWidth:"20px"}}
                              color="#6599AF"
                            />
                            <span className="fw-bold text-size  ">
                              Business Contact:
                            </span>
                            {/* <p className="bg-success">&nbsp; +{UserData.business_info.phone_number}</p> */}
                            <span className="p-text-size">&nbsp;+{UserData.business_info.phone_number}</span>
                          </div>
                          <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                            <MdApartment
                              className="me-2 icon-size"
                              style={{minWidth:"20px"}}
                              color="#6599AF"
                            />
                            <span className="fw-bold text-size ">
                              Business Category:
                            </span>
                            {/* <p className="F-text-size">&nbsp; {UserData.business_category}</p> */}
                            <span className="F-text-size">&nbsp;{UserData.business_category}</span>
                          </div>
                          <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                            <MdOutlineLanguage
                              className="me-2 icon-size"
                              style={{minWidth:"20px"}}
                              color="#6599AF"
                            />
                            <span className="fw-bold text-size ">
                              Website URL:
                            </span>
                            {UserData.business_info.address !== null ? (
                              <a
                                href={`https://${UserData.business_info.address}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none ms-2 adress-size"
                                style={{
                                  color: "#6148a1",
                                }}
                              >
                                {UserData?.business_info?.address}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <GalleryProfile GallaryImg={GallaryImg} />
                    </div>
                    {/* ^ Gallary */}
                  </>
                )}
                {
                  UserData.type === false ?
                    <div className="border rounded-3 shadow mt-3">
                      <div className="border1 mt-n1"></div>
                      <div className="py-3 px-2">
                        <GalleryProfile GallaryImg={GallaryImg} />
                      </div>
                    </div>
                    :
                    ""
                }
                {/* Friends */}
                <div
                  className="friends border rounded-3 shadow"
                  style={{
                    height: "fit-content",
                    marginBottom: "0px",
                    marginTop: UserData.type === false ? "1rem" : "2rem",
                  }}
                >
                  <div className="border1 mt-n1"></div>
                  {UserData?.privacy?.friend_list_private === false || UserFriend !== "" || UserFollower !== "" ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="friends mt-2 ms-3">
                          {UserData?.fname}'s Connections ({UserFriends.length})
                        </p>
                      </div>
                      <div className="friends_row ps-3 my-2" style={{ height: "fit-content", marginBottom: "0px", paddingBottom: "0px" }}>
                        {UserFriends.length === 0 ?
                          <p className=" fs-6 text-capitalize rounded text-center pt-3" style={{ backgroundColor: "#ececec", width: "25%", height: "70px", marginBottom: "20px" }}>
                            No Connections Found
                          </p> :
                          UserFriends.map((friends, index) => {
                          return (
                            <div
                              className=" col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 px-1 mx-1 text-center"
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() => handleClick(friends)}
                            >
                              <img
                                className="img rounded-5 "
                                src={
                                  friends.profile_pic
                                    ? friends.profile_pic
                                    : "/img/upload-photo.png"
                                }
                                style={{ width: "40px", height: "40px" }}
                                alt="Admin"
                              />
                              {friends?.is_online=== true ?
                                <img
                                  src="/Images/contact-online.png"
                                  className=""
                                  style={{
                                    position: "relative",
                                    right: "11px"
                                    , top: "17px"
                                  }}
                                  height={9}
                                  alt="dot sign"
                                /> :
                                <img
                                  src="/Images/contact-idle.png"
                                  className=""
                                  style={{
                                    position: "relative",
                                    right: "11px"
                                    , top: "17px"
                                  }}
                                  height={9}
                                  alt="dot sign"
                                />}

                              <p className="jacob" style={{ wordWrap: friends?.fname.length > 10 ? "break-word" : "normal" }}>
                                {friends.fname}
                                <br />
                                {friends.lname}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center" }} className="py-2 ">
                      <p className="my-5">This user’s friend list is private</p>{" "}
                    </div>
                  )}
                </div>
                {/* Map Start */}
                <div
                  className=""
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <Map UserData={UserData} />
                </div>
                {/* ^ Map End */}
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <Aside
                  setChatBox={() => setChatBox((e) => !e)}
                  chatBox={chatBox}
                  item={UserData}
                  UserFriend={UserFriend}
                  UserFollower={UserFollower}
                />
                {/* ^ New Post Div */}
              </div>
              {/* ^ Col-4 */}
            </div>
          </div>
        )}
      </div>
      {ProfilePicView === true ? (
        <CoverImage
          style={{zIndex: "2000"}}
          src={ImageProfile}
          handleClose={(isChanged) => handleModelClose(isChanged)}
          type={"profile"}
          user={UserData._id}
        />
      ) : null}
      {CoverPicView === true ? (
        <CoverImage
          style={{zIndex: "2000"}}
          src={ImageCover}
          handleClose={(isChanged) => handleModelClose(isChanged)}
          type={"Cover"}
          user={UserData._id}
        />
      ) : null}
    </>
  );
};

export default UserProfile;