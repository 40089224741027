import React, { useEffect, useRef, useState } from 'react'
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MapLoc = (OrignalCom) => {
    const MapFunc = () =>{
    const [coords, setcoords] = useState(null);
  const [maps, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  useEffect(() => {
      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        accessToken: process.env.REACT_APP_MAPBOX_KEY,
        style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
          center:[0 , 0],
        // zoom: 14,
        minZoom:6,
        maxZoom:9,
      });
      const NavigationControl = new mapboxgl.NavigationControl({
        showCompass:false,
      })
      map.current.addControl(NavigationControl, "bottom-right");
      map.current.scrollZoom.disable()
    map.current.dragPan.disable()
      var geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });
      map.current.addControl(geolocate);
      map.current.on("load", function () {
        geolocate.trigger();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              var lat = position.coords.latitude;
              var lng = position.coords.longitude;
              setcoords({ lat, lng });
            },
            function (error) {
  
            }
          );
        }
      });
      
      setMap(map.current);
  
      return () => map.current.remove();

  },[]);
  return <OrignalCom setcoords = {(e) =>setcoords(e)} coords = {coords} mapContainerRef={mapContainerRef} map={maps} /> 
}
  return MapFunc;
}
export default MapLoc

export const LocMarker = ({map , coords , setcoords}) =>{
    useEffect(() =>{
      if(coords != null){
        // const marker = new mapboxgl.Marker({  draggable: true })
        const marker = new mapboxgl.Marker()
        .setLngLat([
          coords.lng,
          coords.lat,
        ])
        .addTo(map);
        function onDragEnd(event) {
          const lngLat = event.lngLat;
          const lat = lngLat.lat
          const lng = lngLat.lng
          marker.setLngLat([lng , lat])
          setcoords({ lng , lat })
          }
           
          map.on('click', onDragEnd);
        return () => marker.remove();
      }
    })
    return <div className="Marker"></div>;
  }
