import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button, Col } from "reactstrap";
import { useDispatch} from "react-redux";
import { resetPassword } from "../redux/actions/auth/auth.actions";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
const ForgotPassword = ({ setForgotFalg, ForgotFalg }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setError] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string("Please fill in the field Thank You").email().required(),
  });
  const handleSubmit = (values) => {
    const resetData = {
      email: values.email,
    };
    dispatch(resetPassword(resetData)).then((data) => {
      setError(data);
    });
  };
  const gobackFunc = () => {
    setForgotFalg(false);
    navigate("/", { replace: true });
  };

  function clearError() {
    return errorMsg.state === "success" ? (
      <div className="mb-3 text-success text-center">{errorMsg.message}</div>
    ) : (
      <div className="mb-3 error-text text-center">{errorMsg.message}</div>
    );
  }
  return (
    <>
      <div className="mt-4 text-center">
        <div>
          <span
            style={{
              color: "black",
              fontWeight: "700",
              fontSize: "16px",
              marginLeft:"-12px"
            }}
          >
            Restore your password
          </span>
        </div>
        <div className="mt-3 mb-3">
          <span className="font12 px-3">
            Enter your email address and we'll get you back on track.
          </span>
        </div>
      </div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, isValid, dirty }) => (
          <Form onSubmit={handleSubmit}>
            {errorMsg !== null ? clearError() : ""}

            <Col lg="12" md="12" sm="12" xs="12" className="px-3 mx-auto">
              <div className="position-relative" onClick={() => setError("")}>
                <label className="LabelForgot">Email Address</label>
                <MdEmail className="MdinfoForgot" color="black" size={20} />
                <input
                  name="email"
                  className="form-control input-btn email mx-auto"
                  placeholder="Email Address"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="d-flex justify-content-center align-items-end gap-2 px-3 mt-4 mb-4">
                <div className="position-relative">
                  <BiChevronLeft className="forgotAiLeft" size={27} />
                  <button
                    className="backToLogin"
                    style={{ cursor: "grab" }}
                    onClick={() => {
                      gobackFunc();
                      setError("");
                    }}
                  >
                    Back
                  </button>
                </div>
                <Button
                  type="submit"
                  className="forgotSubmit"
                  disabled={!(dirty && isValid)}
                >
                  <b>Restore Password</b>
                </Button>
              </div>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default ForgotPassword;