import React, { useEffect} from "react";
import { Col, Row } from "reactstrap";
import NavbarHome from "./NavbarNew/NavbarHome";
import '../../assets/styles/home.css'
import CompHome from "./Home First/CompHome";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import FootNav from "../User_Profile/Footer/Footer-nav/FootNav";
import { useState } from "react";
import axios from "axios";
import { useRef } from "react";
 const Home = () => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const currentCity = useSelector((state) => state?.auth?.userCity);
  const navigate = useNavigate()
  const cityfirst = userData?.location?.extraCityToFollow0?.cityName;
  const city1 = cityfirst?cityfirst.split(","):null;
  const [NavMaps ,setNavMaps] = useState(null)
  const UpRef = useRef(null)
  
  // if(userData?.type === true && userData?.subscription===undefined){
  //   navigate("/fourthStep")
  // }
  useEffect(()=>{
    var config = {
      method: "GET",
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentCity?currentCity:city1[0]}.json?access_token=` + process.env.REACT_APP_MAPBOX_KEY,
    };
    axios(config).then((response)=>{
      var locations = [
        response?.data?.features[0]?.center[0],
        response?.data?.features[0]?.center[1],
      ]
      localStorage.setItem("location",locations);
    })
  },[]) // eslint-disable-line react-hooks/exhaustive-deps
  function zoom() {
    document.body.style.zoom = "0%" 
}
  return (
    <>
    <div className="background" onLoad={()=> zoom()}>
      <Row>
        <Col md="12" lg="12" sm="12" xm="12">
        <div ref={UpRef}>
        <NavbarHome NavMaps={NavMaps}  />
        </div>
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="12" sm="12" xm="12">
          <CompHome setNavMaps={(e) =>setNavMaps(e)} NavMaps = {NavMaps} UpRef={UpRef}/>
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="12" sm="12" xm="12">
          <FootNav />
        </Col>
      </Row>
      </div>
    </>
  );
};

export default Home;
