import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { BiChevronRight } from "react-icons/bi";


function Microblogs({ isMicro,MicroData,isActiv,microlist,EventLoading }) {
  return (
    <>
      <div className="microblogs shadow mt-2 ">
        <div className=" microblogs-header p-2 d-flex justify-content-between align-items-center">
          <div>
            <img className="ms-2" src={"/Images/Union.png"} alt="Icon" />
            <span className="mb-name ms-3">My Microblogs </span>
          </div>
          <div
            className="add-mb d-flex"
            onClick={() => {
              isMicro();
              window.scrollTo(0, 0);
            }}
          >
            <span className="add">Add New</span>
            <BsPlusLg className=" ms-2 plus-btn" />
          </div>
        </div>
        <div className="microblogs-body pe-3 py-3">
          <div className="microblogs-scrol px-3">
            {EventLoading=== true? <div style={{display:"flex", justifyContent:"center",margin:"5%"}}><div className="loading2"></div></div> :
              microlist.map((items , index) => {
              let date = new Date(items.created_date).toString().split(" ");
              return (
                <div
                  className="border mb-2 microHover rounded-3 border-dark d-flex justify-content-between"
                  key={index}
                  onClick={() =>{
                    window.scrollTo(0, 0);
                    MicroData([items])
                    isActiv()
                  }}
                >
                  <div className="p-2 pb-0 d-flex">
                    <img
                      className="img-fluid box-img"
                      src={
                        items.microblog_img === null
                          ? "/Images/Event1.png"
                          : items.microblog_img
                      }
                      alt="Icon"
                    />
                    <div className="ps-3 py-0 ">
                      <span className="box-span1 fw-bold">{items.name}</span>
                      <br />
                      <span className="box-span2 text-nowrap ">
                        {`${date[0]}` +
                            " " +
                            `${date[1]}` +
                            " " +
                            `${date[2]}`+
                            " " +
                            `${date[3]}`}
                      </span>
                    </div>
                  </div>
                  <button className=" btn send-mb px-2  " type="button">
                    <BiChevronRight className="arrow-send " />
                  </button>
                </div>
              );
            })}
            {/* ^ Events Box1 */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Microblogs;
