import React, { useEffect } from 'react'
import "../../assets/styles/home.css";
import { Container, Navbar } from "react-bootstrap";
import FootNav from '../User_Profile/Footer/Footer-nav/FootNav'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Demo = () => {
  const NormalUser = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate()
  const [Loading, setLoading] = useState(false)
  const [Isportrait, setIsportrait] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 3000)
  const onClick = () => {
    if (NormalUser === undefined) {
      navigate("/")
    } else {
      navigate("/home", {
        state: {
          item: [
            NormalUser.location.coordinates[0].coords.lng,
            NormalUser.location.coordinates[0].coords.lat,
          ],
        },
      });
    }
  };
  window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
    const portrait = e.matches;

    if (portrait) {
      setIsportrait(true)
    } else {
      setIsportrait(false)
    }
});
  return (
    <>
      <div className='overflow-hidden'>
        {
          Isportrait?
        <div className="m-3 mb-3">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg=""
            variant="dark"
            className="rounded-3 position-relative"
          >
            <Container fluid className="">
              <Navbar.Brand
                className="ps-1 ps-xxl-2 ps-xl-2 ps-lg-2 me-0"
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/img/LogoNav.png"
                  height={30}
                  alt="ibouge"
                  onClick={() => onClick()}
                />
                <span style={{color: "white", position: "relative", top: "-8px", fontWeight:"300", fontSize: "0.75rem"}}>&#174;</span>
              </Navbar.Brand>
            </Container>
          </Navbar>
        </div>:null
        }
        <div className='mx-3' style={{
          width: "100",
          height: Isportrait?"calc(100vh - 153px)":"100vh",
          background: "white"
        }}>
          {Loading === true ?
            <div style={{ display: "flex", justifyContent: "center", height: "100vh", margin: "5%" }}>
              <div className="loading">
              </div>
            </div>
            :
            <iframe
      width="100%"
      height="100%"
      src={"video/ibouge.mp4"}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      playsInline
      title="Embedded youtube"
    />}
        </div>
        {
          Isportrait?
        <div className='mt-3'>
          <FootNav />
        </div>:null
        }
      </div>
    </>
  )
}

export default Demo
