import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";
import '../../../assets/styles/Profile/Profile.css'
import axios from "axios";
export default function Map({userData}) {
  const [cityname , setCityName] = useState("")
  const coords = userData?.location?.coordinates[0]?.coords
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(coords?.lng);
  const [lat] = useState(coords?.lat);
  const [zoom] = useState(8);
  const [API_KEY] = useState(process.env.REACT_APP_MAPBOX_API_KEY);
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
  
  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.scrollZoom.disable()
    map.current.dragPan.disable()
    new mapboxgl.Marker({ color: "#614A7E" })
    .setLngLat([coords?.lng,coords?.lat])
    .addTo(map.current);
    var config = {
      method: "GET",
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords?.lng},${coords?.lat}.json?access_token=` + process.env.REACT_APP_MAPBOX_KEY,
    };
    axios(config).then((response)=>{
      setCityName(response.data.features[2].place_name);
    })
    .catch(function (error) {
      console.log(error);
    });
  });
  const ref = useRef(null);
  useLayoutEffect(() => {
    console.log(ref.current.offsetWidth);
    console.log(ref.current.offsetHeight , "height");
  }, [ref]);


  return (
    <div className="">
      <div className="mt-3 mb-3 map-wrappp border rounded-3 shadow" ref={ref}>
        <div className="border rounded-2">
          <div ref={mapContainer} className="mappp" />
        </div>
        <p className="california ms-2 mt-1">{cityname}</p>
      </div>
    </div>
  );
}
