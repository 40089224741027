import React, { useEffect, useRef, useState } from "react";
import { IoMdSettings, IoMdCamera } from "react-icons/io";
import { MdAdminPanelSettings, MdNotificationsActive } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import ProfileSetting from "./ProfileSettting/ProfileSetting";
import "../../assets/styles/Settings/Settings.css";
import NavbarHome from "../home/NavbarNew/NavbarHome";
import GeneralAcc from "./Accounts/GeneralAcc";
import Privacy from "./Privacy/Privacy";
import Notifications from "./Notifications/Notifications";
import axios from "axios";
import ModalImage from "react-modal-image";
import { useDispatch, useSelector } from "react-redux";
import { CoverPic, ProfilePic, Server2, UserAccountDeactivate, UserProfileUpdate, logout } from "../../redux/actions/auth/auth.actions";
import { useLocation, useNavigate } from "react-router-dom";
import FootNav from "../User_Profile/Footer/Footer-nav/FootNav";
import { AiFillCamera } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeactiveButtons from "../../components/DeactiveButtons";

function MainSettings() {
  const userData = useSelector((state) => state?.auth?.user?.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation();
  const { item } = state;
  const [Settings, setSettings] = useState(item)
  useEffect(() => {
    dispatch(UserProfileUpdate(userData?._id))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Image Selection Start
  const [ImageCover, setImageCover] = useState("");
  // const [filesCover, setFilesCover] = useState("");
  const [IsUploadCover, setIsUploadCover] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState({
    type: false,
    status: "Deactive"
  });
  const inputFileCover = useRef(null);
  // to open file explore
  const openInputFileCover = (e) => {
    inputFileCover.current.click();
  };
  const notify = () => toast.success("Profile has been updated", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  // to display image
  const handleImageChangeCover = (e) => {
    inputFileCover.current.click();
    // setFilesCover(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImageCover(e.target.result);
        setIsUploadCover(true);
      };
      reader.readAsDataURL(e.target.files[0]);
      ApiCover();
    }
  };
  const ApiCover = () => {
    let data = new FormData();
    data.append("userId", userData?._id);
    data.append("file", inputFileCover.current.files[0]);
    data.append("albumName", "all-cover-pictures");
    data.append("userEmail", userData?.email);
    let config = {
      method: "post",
      url: Server2 + "/users/coverpic",
      data: data,
    };
    console.log(data, "dataCover")

    axios(config)
      .then(function (response) {
        const newSet = {
          message: "User Logged in Successfully.",
          status: 200,
          user: response.data
        }
        dispatch(CoverPic(newSet))
        console.log(response.data, "i am responce");
        if (response.data) {
          notify()
        }
      })
      .catch(function (error) {
        console.log(error, "i am error");
      });
  };
  // Image Selection Start
  const [Image, setImage] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const inputFile = useRef(null);
  // to open file explore
  const openInputFile = (e) => {
    inputFile.current.click();
  };
  // to display image
  const handleImageChange = (e) => {
    inputFile.current.click();
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUpload(true);
      };
      reader.readAsDataURL(e.target.files[0]);
      Api();
    }
  };
  const Api = () => {
    let data = new FormData();
    data.append("userId", userData._id);
    data.append("file", inputFile.current.files[0]);
    data.append("albumName", "all-profile-pictures");
    data.append("userEmail", userData.email);
    let config = {
      method: 'post',
      url: Server2 + '/users/profilepic',
      data: data
    };

    axios(config)
      .then(function (response) {
        const newSet = {
          message: "User Logged in Successfully.",
          status: 200,
          user: response.data
        }
        dispatch(ProfilePic(newSet))
        if (response.data) {
          notify();
        }
        console.log(response.data, "i am responce");
      })
      .catch(function (error) {
        console.log(error, "i am error");
      });
  }
  // API'S   activate Starts
  const PayloadDeactive = {
    email: userData?.email,
    id: userData?._id,
    status: false

  };
  const Activate = () => {
    dispatch(UserAccountDeactivate(PayloadDeactive)).then((res) => {
      console.log(res, "i am res")
      if (res?.data) {
        navigate("/home", {
          state: {
            item: [
              userData.location.coordinates[0].coords.lng,
              userData.location.coordinates[0].coords.lat,
            ],
          },
        });
      }
    });
  };
  const DeleteAccount = () =>{
    var data = {
      id: userData?._id,
      email: userData?.email,
      status: true
    };
    let config = {
      method: "post",
      url: Server2 + "/users/deletion-apply",
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.status){
          dispatch(logout());
          navigate("/", { replace: true });
          localStorage.clear();
        }
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  }
  return (


    <div className="bg-white">
      {(userData?.is_deactivated === true) ?
        <div className="s-main rounded-3 mx-3" >
          <div className="s-header py-3">
            <span className="fw-bold mx-4 fs-6">Settings</span>
          </div>
          <div className="s-body p-3 d-flex" style={{ height: "87vh" }}>

            <div className="s-inner-main overflow-hidden rounded-3 shadow position-relative bg-light">
              <div>
                <p className="fw-bold fs-6 text-center">Oops! Your Account is currently Deactivated</p>
                {
                  confirmOpen.type === false ? null :
                    <div className="text-center mt-2" style={{ backgroundColor: "#d2f8d2", borderRadius: "8px" }}>
                      <p className="pt-1"> Are you sure you want to {confirmOpen.status === "Delete" ? "Delete" : "Activate"} Account? </p>
                      <div className="d-flex justify-content-evenly pb-2 mb-1">
                        <button className="border-0 rounded-2 w-25 p-2 " onClick={() => setConfirmOpen({
                          type: false,
                          status: "Deactive"
                        })}>Cancel</button>
                        <button className="border-0 rounded-2 w-25 p-2 bg-primary text-white"
                          onClick={() => {
                            confirmOpen.status==="Delete"?
                            DeleteAccount()
                            :Activate()}}
                        >Confirm</button>
                      </div>
                    </div>
                }
                <DeactiveButtons setConfirmOpen={(e) => {
                  setConfirmOpen(e)
                }} />
              </div>
            </div>
          </div>
        </div>
        :
        <>
          <NavbarHome />
          <div className="s-main rounded-3 mx-3">
            <div className="s-header  py-3">
              <span className="fw-bold mx-4 fs-6">Settings</span>
            </div>
            <div className="s-body p-3 ">
              <div className="s-inner-main overflow-hidden rounded-3 shadow position-relative">
                <div className="overflow-hidden text-center">
                  <div
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "0px",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    onClick={openInputFileCover}
                    onChange={handleImageChangeCover}
                  >
                    <AiFillCamera size={28} color={"White"} />
                    <span className="ms-1"></span>
                  </div>
                  {IsUploadCover ? (
                    <ModalImage
                    hideDownload	
                      className="Settingcover"
                      small={ImageCover}
                      large={ImageCover}
                      alt={`${userData.fname} ${userData.lname}`}
                    />
                  ) : (
                    <div>
                      <ModalImage
                      hideDownload	
                        className="Settingcover"
                        small={
                          userData?.cover_pic
                            ? userData?.cover_pic
                            : "/Images/dummy2.png"
                        }
                        large={
                          userData?.cover_pic
                            ? userData?.cover_pic
                            : "/Images/dummy2.png"
                        }
                        alt={`${userData?.fname} ${userData?.lname}`}
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    id="file"
                    className="rounded-circle photouser"
                    ref={inputFileCover}
                    onClick={openInputFileCover}
                    onChange={handleImageChangeCover}
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                  />

                  <div style={{ position: "absolute", top: "30px" }} className="w-100 mx-auto">
                    {IsUpload ? (
                      <img
                        src={Image}
                        className="ms-img img-fluid"
                        height={110}
                        alt="uploaded"
                      />
                    ) : (

                      <img
                        className="ms-img img-fluid"
                        src={userData?.profile_pic ? userData?.profile_pic : "/img/upload-photo.png"}
                        alt="Icon"
                      />

                    )}
                    <input
                      type="file"
                      id="file"
                      className="rounded-circle photouser"
                      ref={inputFile}
                      onClick={openInputFile}
                      accept="image/png, image/jpeg"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <IoMdCamera
                      className="s-camera p-2 rounded-5 "
                      onClick={openInputFile}
                      onChange={handleImageChange}
                    />
                    <ToastContainer />
                  </div>
                </div>
                <div className="s-inner-body pt-5 bg-white">
                  <div className="px-3 pb-2 pt-2 d-flex align-items-center justify-content-between">
                    <div
                      className="s-icons rounded-2 p-3"
                      onClick={() => {
                        setSettings("1")
                      }
                      }
                      style={
                        Settings === "1"
                          ? { backgroundColor: "#6148A1" }
                          : { backgroundColor: "#FEA115" }
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCircleUser}
                        className="text-white fs-3"
                      />
                    </div>
                    <div
                      className="s-icons rounded-2 p-3"
                      onClick={() => setSettings("2")}
                      style={
                        Settings === "2"
                          ? { backgroundColor: "#6148A1" }
                          : { backgroundColor: "#FEA115" }
                      }
                    >
                      <IoMdSettings className="text-white fs-3" />
                    </div>
                    <div
                      className="s-icons rounded-2 p-3"
                      onClick={() => setSettings("3")}
                      style={
                        Settings === "3"
                          ? { backgroundColor: "#6148A1" }
                          : { backgroundColor: "#FEA115" }
                      }
                    >
                      <MdAdminPanelSettings className="text-white fs-3" />
                    </div>
                    <div
                      className="s-icons rounded-2 p-3"
                      onClick={() => setSettings("4")}
                      style={
                        Settings === "4"
                          ? { backgroundColor: "#6148A1" }
                          : { backgroundColor: "#FEA115" }
                      }
                    >
                      <MdNotificationsActive className="text-white fs-3" />
                    </div>
                  </div>
                  {Settings === "1" ? (
                    <ProfileSetting />
                  ) : Settings === "2" ? (
                    <GeneralAcc />
                  ) : Settings === "3" ? (
                    <Privacy />
                  ) : (
                    <Notifications />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      }
      <FootNav />
    </div>
  );
}

export default MainSettings;
