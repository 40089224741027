export const LOADING = 'LOADING';
//login Types
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL='USER_FAIL';
export const LOGOUT = 'LOGOUT';
//Create Account Types
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAIL= 'NEW_USER_FAIL';
//user Link Check Types
export const LINK_USER_SUCCESS='LINK_USER_SUCCESS';
export const LINK_USER_FAIL='LINK_USER_FAIL';

//forgot Password Types

export const FORGOT_PASSWORD_SUCCESSS='FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL='FORGOT_PASSWORD_FAIL';

//New Password
export const NEW_PASSWORD_SUCCESS='NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAIL='NEW_PASSWORD_FAIL';


// google api
export const GOOGLE_SIGNUP_SUCCESS="GOOGLE_SIGNUP_SUCCESS";
export const GOOGLE_SIGNUP_FAIL="GOOGLE_SIGNUP_FAIL";
//facebook api
export const FACEBOOK_SIGNUP_SUCCESS="FACEBOOK_SIGNUP_SUCCESS";
export const FACEBOOK_SIGNUP_FAIL="FACEBOOK_SIGNUP_FAIL";
// google api
export const GOOGLE_AUTH_SUCCESS="GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL="GOOGLE_AUTH_FAIL";
//facebook api
export const FACEBOOK_AUTH_SUCCESS="FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL="FACEBOOK_AUTH_FAIL";


// first step bussiness 
export const FIRST_BUSSINESS_SUCCESS="FIRST_BUSSINESS_SUCCESS";
export const FIRST_BUSSINESS_FAIL="FIRST_BUSSINESS_FAIL";
// SECOND step bussiness 
export const SECOND_BUSSINESS_SUCCESS="SECOND_BUSSINESS_SUCCESS";
export const SECOND_BUSSINESS_FAIL="SECOND_BUSSINESS_FAIL";
// THIRD step bussiness 
export const THIRD_BUSSINESS_SUCCESS="THIRD_BUSSINESS_SUCCESS";
export const THIRD_BUSSINESS_FAIL="THIRD_BUSSINESS_FAIL";
// FOURTH step bussiness 
export const FOURTH_BUSSINESS_SUCCESS="FOURTH_BUSSINESS_SUCCESS";
export const FOURTH_BUSSINESS_FAIL="FOURTH_BUSSINESS_FAIL";
// first step personal 
export const FIRST_PRESONAL_SUCCESS="FIRST_PRESONAL_SUCCESS";
export const FIRST_PRESONAL_FAIL="FIRST_PRESONAL_FAIL";
// ProfilePic First step personal 
export const PICTURE_PRESONAL_SUCCESS="PICTURE_PRESONAL_SUCCESS";
export const PICTURE_PRESONAL_FAIL="PICTURE_PRESONAL_FAIL";
// SECOND step personal 
export const SECOND_PRESONAL_SUCCESS="SECOND_PRESONAL_SUCCESS";
export const SECOND_PRESONAL_FAIL="SECOND_PRESONAL_FAIL";
// THIRD step personal 
export const THIRD_PRESONAL_SUCCESS="THIRD_PRESONAL_SUCCESS";
export const THIRD_PRESONAL_FAIL="THIRD_PRESONAL_FAIL";
// Agreed Term & policy 
export const AGREED_TP_SUCCESS="AGREED_TP_SUCCESS";
export const AGREED_TP_FAIL="AGREED_TP_FAIL";
// GET USERS_DATA
export const USERS_DATA_SUCCESS="USERS_DATA_SUCCESS";
export const USERS_DATA_FAIL="USERS_DATA_FAIL";
// GET Bussines_DATA
export const BUSSINESS_DATA_SUCCESS="BUSSINESS_DATA_SUCCESS";
export const BUSSINESS_DATA_FAIL="BUSSINESS_DATA_FAIL";
// STATUS UPDATE PROFILE SCREEN
export const STATUS_UPDATE_SUCCESS="STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_FAIL="STATUS_UPDATE_FAIL";
// cover Photo
export const COVERPHOTO="COVERPHOTO";
// Profile Photo
export const PROFILEPHOTO="PROFILEPHOTO";

// update settings bussiness
export const SETTING_PRO_PERSONAL_SUCCESS="SETTING_PRO_PERSONAL_SUCCESS";
export const SETTING_PRO_PERSONAL_FAIL="SETTING_PRO_PERSONAL_FAIL";
// update settings bussiness
export const SETTING_PRO_BUSINESS_SUCCESS="SETTING_PRO_BUSINESS_SUCCESS";
export const SETTING_PRO_BUSINESS_FAIL="SETTING_PRO_BUSINESS_FAIL";
// update settings bussiness
export const SETTING_ACC_BUSINESS_SUCCESS="SETTING_ACC_BUSINESS_SUCCESS";
export const SETTING_ACC_BUSINESS_FAIL="SETTING_ACC_BUSINESS_FAIL";
// update settings bussiness
export const SETTING_ACC_PERSONAL_SUCCESS="SETTING_ACC_PERSONAL_SUCCESS";
export const SETTING_ACC_PERSONAL_FAIL="SETTING_ACC_PERSONAL_FAIL";
// update settings Location
export const SETTING_LOCATION_SUCCESS="SETTING_LOCATION_SUCCESS";
export const SETTING_LOCATION_FAIL="SETTING_LOCATION_FAIL";
// update settings Privacy
export const SETTING_PRIVACY_SUCCESS="SETTING_PRIVACY_SUCCESS";
export const SETTING_PRIVACY_FAIL="SETTING_PRIVACY_FAIL";
// update settings Notifications
export const SETTING_NOTIFICATIONS_SUCCESS="SETTING_NOTIFICATIONS_SUCCESS";
export const SETTING_NOTIFICATIONS_FAIL="SETTING_NOTIFICATIONS_FAIL";
// update All Cities
export const UPDATE_CITY_SUCCESS="UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAIL="UPDATE_CITY_FAIL";
// update user Profile
export const PROFILE_UPDATE_SUCCESS="PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL="PROFILE_UPDATE_FAIL";
// update user Profile
export const USER_DEACTIVATE_SUCCESS="USER_DEACTIVATE_SUCCESS";
export const USER_DEACTIVATE_FAIL="USER_DEACTIVATE_FAIL";

// Dashboard City selection
export const DASH_CITY_SUCCESS="DASH_CITY_SUCCESS";
export const DASH_CITY_FAIL="DASH_CITY_FAIL";
