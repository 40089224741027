import React, { useState, useEffect } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";
import { AiOutlineClose } from "react-icons/ai";
import "../../assets/styles/Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { logout, ThirdStepPresonal, DashCitySelect } from "../../redux/actions/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import TermsOfService from "../TermsOfService";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

function PresonalThirdStep() {
  // navigate
  let navigate = useNavigate();
  // useDispatch to send payload
  const dispatch = useDispatch();
  // useState to set value of cities in state
  const [cities1, setCities1] = useState(null);
  const [cities2, setCities2] = useState(null);
  const [cities3, setCities3] = useState(null);
  const [error, setError] = useState("");
  const [TAP, setTAP] = useState({model: "", state: false})

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: "country,region,place,postcode,locality,neighborhood",
    });

    geocoder.addTo("#FirstCity");
    // Add geocoder result to container.
    geocoder.on("result", (e) => {
      setCities1(e.result.place_name);
    });
    let id = document.getElementsByClassName("mapboxgl-ctrl-geocoder--button");
    id[0].style.backgroundColor = "#6148A1";
    id[0].style.position = "absolute";
    id[0].style.top = "2px";
    id[0].style.right = "0px";
    id[0].style.padding = "8px";
    id[0].style.borderRadius = "4px";
    id[0].style.display = "block";
    let icon = document.getElementsByClassName("mapboxgl-ctrl-geocoder--icon");
    icon[1].style.fill = "#fff";
  }, []);
  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: "country,region,place,postcode,locality,neighborhood",
    });

    geocoder.addTo("#SecondCity");
    // Add geocoder result to container.
    geocoder.on("result", (e) => {
      setCities2(e.result.place_name);
    });
    let id = document.getElementsByClassName("mapboxgl-ctrl-geocoder--button");
    id[1].style.backgroundColor = "#6148A1";
    id[1].style.position = "absolute";
    id[1].style.top = "2px";
    id[1].style.right = "0px";
    id[1].style.padding = "8px";
    id[1].style.borderRadius = "4px";
    id[1].style.display = "block";
    let icon = document.getElementsByClassName("mapboxgl-ctrl-geocoder--icon");
    icon[4].style.fill = "#fff";
  }, []);
  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: "country,region,place,postcode,locality,neighborhood",
    });
    geocoder.addTo("#ThirdCity");
    // Add geocoder result to container.
    geocoder.on("result", (e) => {
      setCities3(e.result.place_name);
    });
    let id = document.getElementsByClassName("mapboxgl-ctrl-geocoder--button");
    id[2].style.backgroundColor = "#6148A1";
    id[2].style.position = "absolute";
    id[2].style.top = "2px";
    id[2].style.right = "0px";
    id[2].style.padding = "8px";
    id[2].style.borderRadius = "4px";
    id[2].style.display = "block";
    let icon = document.getElementsByClassName("mapboxgl-ctrl-geocoder--icon");
    icon[7].style.fill = "#fff";
  }, []);
  const NormalUser = useSelector((state) => state?.auth?.user?.user);

  const handleSubmitThird = () => {
    if (cities1 === null && cities2 === null && cities3 === null) {
      setError("Pleaase enter at least one city first");
    } else if (cities1 === null) {
      setError("Please select first city");
    } else {
      const payloadThird = {
        activation_status: 3,
        email: "",
        cityname1: "",
        cityname2: "",
        cityname3: "",
      };
      payloadThird.activation_status = 3;
      payloadThird.email = NormalUser?.email;
      payloadThird.cityname1 = cities1;
      payloadThird.cityname2 = cities2;
      payloadThird.cityname3 = cities3;
      dispatch(ThirdStepPresonal(payloadThird)).then((res) => {
          const newCity = res?.user?.location?.extraCityToFollow0?.cityName.split(",");
          dispatch(DashCitySelect(newCity[0]))
          if (res?.user?.location?.extraCityToFollow0) {
           setTAP({model: "Terms", state: true})
          }
        });
      }
    };
    useEffect(() =>{
      if (NormalUser?.activation_status === 3 && (NormalUser?.agreedToTAC === undefined || NormalUser?.agreedToTAC === false)){
        console.log("workinhg")
        setTAP({model: "Terms", state: true})
      }
  },[])

  const handleClose = (e) => {
    dispatch(logout());
    navigate("/", { replace: true });
    localStorage.clear();
  };

  return (
    <>
      <div className="full-content">
        <div className="text-center mt-5 mb-4 pt-4">
          <img src="/img/Logo.png" height={60} alt="ibouge" />
        </div>
        <div className="bg-white card-customize ">
          { TAP.state === true ?
            <div className="position-absolute w-100" style={{top: "-23px", left: "0px",margin:0,padding:0, zIndex: "500"}}>
              <TermsOfService item={TAP.model} 
               flag={(e) => {
                setTAP(e)
              }}
              TAP = {TAP}
              />
            </div> 
          : "" }
          <div className="border_top"></div>
          <h6 className="headingSteps text-center">Setup 3/3</h6>
          <p className="setupHeading text-center px-2 px-md-4">Select 3 cities to follow for community news and updates</p>
          <div>
            <div className="mt-2 mb-2 text-center error-text">{error}</div>
            {/* input form */}
            <div className="">
              <div className="position-relative">
                <label className="thirdlabel">First City</label>
                <div className="d-flex justify-content-center w-100">
                  <div id="FirstCity" className="mx-2"></div>
                </div>
              </div>

              <div className="position-relative">
                <label className="thirdlabel">Second City</label>
                <div className="d-flex justify-content-center w-100">
                  <div id="SecondCity" className="mx-2"></div>
                </div>
              </div>

              <div className="position-relative">
                <label className="thirdlabel">Third City</label>
                <div className="d-flex justify-content-center w-100">
                  <div id="ThirdCity" className="mx-2"></div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="w-100 mt-3 mx-2 submit-butn"
                  style={{ opacity: cities1==null?0.4 : 1 }}
                  disabled={cities1==null?true: false}
                  onClick={handleSubmitThird}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 col-sm-6 col-md-2 col-lg-2">
        {TAP.state === false ?
          <AiOutlineClose size={30} className="aioutline" onClick={handleClose} />
        :""
        }
      </div>
    </>
  );
}

export default PresonalThirdStep;