import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { OAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const appleAuthProvider =    new OAuthProvider('apple.com');
const signInWithGoogle = () => auth.signInWithPopup(googleAuthProvider);
const signInWithfacebook = () => auth.signInWithPopup(facebookAuthProvider);
const signInWithapple = () => auth.signInWithPopup(appleAuthProvider);

export { auth, signInWithGoogle, signInWithfacebook, signInWithapple };
export default firebase;
