import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { AgreedTC } from '../redux/actions/auth/auth.actions';
import { useNavigate } from 'react-router-dom';
import Tos from '../Pages/auth/footerLogin/Tos';
import Pp from '../Pages/auth/footerLogin/Pp';

function TermsOfService({ item, flag, TAP }) {
  const NormalUser = useSelector((state) => state?.auth?.user?.user);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [checkedT, setCheckedT] = useState(false)
  const [checkedP, setCheckedP] = useState(false)
  useEffect(() => {
    if (TAP.model === "Terms") {
      setCheckedT(false);
      setCheckedP(false);
    } else {
      setCheckedT(false);
      setCheckedP(false);
    }
  }, [TAP.model]);

  const agreedApi = () => {
    const data = {
      id: NormalUser?._id
    }
    dispatch(AgreedTC(data)).then((res) => {
      if (res.user.type) {
        navigate('/fourthStep')
      } else {
        navigate("/home", {
          state: {
            item: [
              NormalUser.location.coordinates[0].coords.lng,
              NormalUser.location.coordinates[0].coords.lat,
            ],
          },
        });
      }

    }).catch((error) => {
      console.log(error)
    })
  }

  return (

    <div className="full-content" style={{ background: "#fff" }}>
      <div className="text-center mt-5 mb-4 pt-4">
        <img src="/img/Logo.png" height={60} alt="ibouge" />
      </div>{" "}
      <AiOutlineClose
        size={30}
        className=""
        style={{ marginTop: 15, position: 'absolute', right: '45px', cursor: 'pointer', zIndex: 100}}
        onClick={() => {
          flag({ model: "", state: false })
        }}
      />
      <div className="card-customize rounded-3 px-2 px-sm-5"
        style={{ minWidth: "50%", maxWidth: "900px", marginBottom: 0, paddingBottom: 0, position: "relative" }}>
        <div className='d-flex align-items-center flex-column' >
          {TAP.model === "Terms" ?
              <Tos/>
              :
              <Pp/>
          }  
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className="mt-3 mx-4 px-2 w-auto d-flex">
              {TAP.model === "Terms" ? (
                <input
                  type="checkbox"
                  className="rounded-4"
                  name="termsCheck"
                  id="termsCheck"
                  checked={checkedT}
                  onChange={(e) => setCheckedT(e.target.checked)}
                  style={{ marginTop: "0px", height: "20px" }}
                />
              ) : (
                <input
                  type="checkbox"
                  className="rounded-4"
                  name="termsCheck2"
                  id="termsCheck2"
                  checked={checkedP}
                  onChange={(e) => setCheckedP(e.target.checked)}
                  style={{ marginTop: "0px", height: "20px" }}
                />
              )}
              <label
                htmlFor="termsCheck"
                className=""
                style={{ fontSize: "14px" }}
              >
                &nbsp;<span>I have read, understood, and agree to the {TAP.model === "Terms" ? "Terms and Conditions" : "Privacy Policy"}.</span>
    
              </label>
            </div>
            <button className='w-100 my-2 mx-2 submit-butn'
              style={{ opacity: (TAP.model == "Terms" && !checkedT) || (TAP.model == "Privacy" && !checkedP) ? 0.6 : 1 }}
              disabled={(TAP.model == "Terms" && !checkedT) || (TAP.model == "Privacy" && !checkedP)}
              onClick={() => {
                flag({ model: TAP.model == "Privacy" ? "" : "Privacy", state: true });
                if (TAP.model == "Privacy") {
                  agreedApi()
                }
              }}>{NormalUser.type ? "Create my  business iBouge account" : "Create my free personal iBouge account"}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfService