import React, { useEffect, useState } from "react";
import {BsChatLeftTextFill} from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import axios from "axios";
import { Server2 } from "../../../redux/actions/auth/auth.actions";
import { useParams } from "react-router-dom";
import ModalImage from "react-modal-image";

function Aside({userData}) {
  let params = useParams();
  let newId = params?.id.split("=")
  const [getStatus, setGetStatus] = useState([]);

  useEffect(() => {
    Get();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const Get = () => {
    var config = {
      method: "get",
      url: Server2+`/status/getStatusById?id=${newId[1]}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setGetStatus(response.data[0].status);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="border rounded-3 shadow px-3 mt-5 pb-3 mb-3">
        <div className="mt-2">
          <p className="fw-bold">{`${userData?.fname}'s wall`}</p>
        </div>
        {/* All Posts Div*/}
        <div className="allpost_div mt-3" style={{height:"1050px"}}>
          {getStatus.length > 0 ? (
            getStatus.map((items,index) => {
              let date = new Date(items.time).toString().split(" ");
              return (
                <div
                  className="border rounded-2  jdp  me-3 py-3 mt-2"
                  key={index}
                >
                  <div className="d-flex  d-flex justify-content-between ">
                    <div className="user-bprofile d-flex justify-content-start align-items-center">
                      <img
                        className="img-profile"
                        src={
                          userData.profile_pic
                            ? userData.profile_pic
                            : "/img/upload-photo.png"
                        }
                        alt="Admin"
                      />
                      <div className="lh-1">
                        <span className="fw-bold lh-1 title">
                          {userData.fname} {userData.lname}
                        </span>{" "}
                        <br />
                        <span className="lh-1 date">{date[0]} {date[1]} {date[2]} {date[3]}</span>
                      </div>
                    </div>
                  </div>

                  <div className="ms-3 ps-5 me-3 ">
                    <p className="desc">{items.message}</p>
                    {items.filename ? (
                      items.status_type === "video" ? (
                        <video
                          width="100%"
                          height={"350px"}
                          style={{ borderRadius: "6px" }}
                          controls
                        >
                          <source src={items.filename} />
                        </video>
                      ) : (
                        <div className="">
                          <ModalImage
                          hideDownload	
                            className="pro-img border rounded-3 w-100 mt-0"
                            small={items.filename}
                            large={items.filename}
                            alt={"Status Updates"}
                          />
                        </div>
                      )
                    ) : null}
                    <div className="desc-icons p-1 d-flex justify-content-end align-items-center">
                      {items.replies.length}
                      <BsChatLeftTextFill className="icons-chat m-2" />
                      {items.likes.length}
                        <AiOutlineHeart
                              color="#D84076"
                              className="icons-heart m-2"
                              size={16}
                            />
                      
                    </div>
                    <div className="border"></div>
                    {items.replies.map((Com) => {
                      return (
                        <div key={Com.time}>
                          <div className="d-flex justify-content-between ">
                            <div className="user-bprofile d-flex justify-content-start align-items-center">
                              <img
                                className="img-profile"
                                src={
                                  Com.profile_pic
                                    ? Com.profile_pic
                                    : "/img/upload-photo.png"
                                }
                                alt="Admin"
                              />
                              <div className="lh-1">
                                <span className="fw-bold lh-1 title">
                                  {Com.fname} {Com.lname}
                                </span>
                              </div>
                            </div>
                          </div>
                            <p className="text-start ms-5 ps-3" style={{fontSize:"12px"}}>{Com.message}</p>
                          <div>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                </div>
              );
            })
          ) : (
            <div className=" bg-light text-center h-25 m-auto d-flex flex-column justify-content-center fw-bolder fs-4 text-dark">
              No Status Updates Here
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Aside;
