import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Col } from "reactstrap";
import { AiFillCamera } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import PhoneInput from "react-phone-input-2"; 
import "react-phone-input-2/lib/style.css";
import "../../assets/styles/style.css";
import "../../assets/styles/Profile.css"; 
import "../../assets/styles/Settings/Settings.css"
import {
  firstStepAction,
  logout,
} from "../../redux/actions/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import axios from "axios";
import PresonalSecondStep from "../Presonal Steps/PresonalSecondStep";
import InputTags from '@semcore/ui/input-tags';
import Tooltip from "@semcore/tooltip";
import ModalImage from "react-modal-image";
import { IoMdCamera } from "react-icons/io";
import ProfileImage from "../../Pages/My-Profile/ProfileImage";
function BussinessSteps() {
  const UserData = useSelector((state) => state?.auth?.user?.user);
  const [Offers, SetOffers] = useState([]);
  const [value3, updateValue3] = useState("");

  // navigate
  let navigate = useNavigate();
  // get phonecode
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  // steps changing
  const [First, setFirst] = useState("1");
  //  image state
  const [Image, setImage] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploadCover, setIsUploadCover] = useState(false);
  const [ImageCover, setImageCover] = useState("");
  const [ProfilePicPopup, setProfilePicPopup] = useState(false);
  const [CoverPicPopup, setCoverPicPopup] = useState(false);

  const inputFile = useRef(null);
  const inputFileCover = useRef(null);
  // dispatch
  const dispatch = useDispatch();
  // to open file explore
  const openInputFile = (e) => {
    inputFile.current.click();
  };
  const openInputFileCover = (e) => {
    inputFileCover.current.click();
  };
  useEffect(() => {
    if (!CoverPicPopup) {
      // Reset input value when CoverPicPopup is false
      inputFileCover.current.value = null;
    }
    if (!ProfilePicPopup) {
      // Reset input value when ProfilePicPopup is false
      inputFile.current.value = null;
    }
  }, [CoverPicPopup , ProfilePicPopup]);
  // to display image
  const handleImageChange = (e) => {
    inputFile.current.click();
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUpload(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
    setProfilePicPopup(true)
  };
  const handleImageChangeCover = (e) => {
    inputFileCover.current.click();
    // setFilesCover(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImageCover(e.target.result);
        setIsUploadCover(true);
      };
      reader.readAsDataURL(e.target.files[0]);
      setCoverPicPopup(true)
    }
  };
  const handleClose = (e) => {
    dispatch(logout());
    navigate("/", { replace: true });
    localStorage.clear();
  };
  // to select dialCode and input value
  const handleOnChange = (value, data) => {
    setPhone(value);
    setCountryCode(data.dialCode);
  };

  // initial values
  const inputValues = {
    status: 1,
    summary: "",
    url: "",
  };

  // Payload send to backend
  const payloadFirst = {
    address: "",
    country_code: "",
    phone_number: "",
    business_summary: "",
    about_company: "",
    activation_status: 1,
    email: "",
  };

  const payloadFirstFunc = (values) => {
    payloadFirst.address = values.url;
    payloadFirst.phone_number = phone;
    payloadFirst.business_summary = values.summary;
    payloadFirst.about_company = Offers;
    payloadFirst.activation_status = 1;
    payloadFirst.country_code = countryCode;
    payloadFirst.email = UserData.email;
  };
  const handleSubmit = (values) => {
    if(Offers.length>0){
      payloadFirstFunc(values);
      dispatch(firstStepAction(payloadFirst));
      setFirst();
    }
  };
  const validationSchema = Yup.object().shape({
    url: Yup.string("Please fill in the field Thank You").required(),
    summary: Yup.string("Please fill in the field Thank You").required(),
    // bussiness_offers: Yup.array(
    //   "Please fill in the field Thank You"
    // ).required(),
  });


   // Business Offers Func Input Tags

   const handleAppendOff = (newTags) => {
    SetOffers((Offers) => [...Offers, ...newTags]);
    updateValue3("");
  };
  const handleRemoveOff = () => {
    if (Offers.length === 0) return;
    SetOffers(Offers.slice(0, -1));
    updateValue3(Offers.slice(-1)[0] + ` ${value3}`);
  };
  const handleCloseOff = (e) => {
    e.preventDefault();
  };

  const handleEditOff = (e) => {
    const { dataset } = e.currentTarget;
    let allTags = [...Offers];
    if (value3) {
      allTags = [...allTags, value3];
    }
    SetOffers(allTags.filter((tag, ind) => ind !== Number(dataset.id)));
    if (!e.defaultPrevented) {
      updateValue3(Offers[dataset.id]);
    }
    return false;
  };

  const handleBlurInputOff = (e) => {
    const { value } = e.currentTarget;
    if (value) handleAppendOff([value]);
  }; 
  
  // focus input on click
  const handleClick = () => {
     handleBlurInputOff()
  };
  return (
    <div>
      {First ? (
        <Formik
          initialValues={inputValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            touched,
            isValid,
            dirty,
          }) => (
            <Col md="12" lg="12" sm="12" xm="12">
              {/* First Step */}

              <div className="full-content">
                {/* Logo image */}
                <div className="text-center mt-5 mb-4 pt-4">
                  <img src="/img/Logo.png" height={60} alt="ibouge" />
                  {/* text */}
                </div>
                <div className="bg-white card-customize">
                  <div className="border_top"></div>
                  <h6 className="headingSteps">Setup 1/4</h6>
                  <p className="setupHeading text-center">Setup your profile</p>
                  {/* Response Message */}
                  {(errors.url || errors.summary) &&
                  (touched.url ||
                    touched.summary) ? (
                    <div className="mt-2 mb-2 error-text">
                      Please fill in the field Thank You
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="position-relative text-center text-dark  ">
                    {/* user data */}
                    {/* <div className="prsonal">
                      {IsUpload ? (
                        <img
                          src={Image}
                          className="rounded-circle photouser"
                          height={110}
                          alt="uploaded"
                        />
                      ) : (
                        <div>
                          <img
                            src={
                              UserData.profile_pic
                                ? UserData.profile_pic
                                : "/img/upload-photo.png"
                            }
                            className="rounded-circle photouser"
                            height={110}
                            alt="upload"
                          />
                        </div>
                      )}
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        onClick={openInputFile}
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                      <AiFillCamera
                        size={45}
                        type="file"
                        htmlFor="file"
                        className="rounded-circle logo-cam  mt-2"
                        onClick={openInputFile}
                        onChange={handleImageChange}
                      />

                    </div> */}
                     <div className="overflow-hidden text-center mx-2" style={{borderRadius:"10px"}}>
                  <div
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "0px",
                      padding: "5px",
                      cursor: "pointer",
                      borderRadius:"8px",
                    }}
                    onClick={openInputFileCover}
                    onChange={handleImageChangeCover}
                  >
                    <AiFillCamera size={28} color={"White"} />
                    <span className="ms-1"></span>
                  </div>
                  
                    <div>
                      <img
                        className="Settingcover"
                        src={
                          UserData?.cover_pic
                            ? UserData?.cover_pic
                            : "/Images/dummy2.png"
                        }
                      
                        alt={`${UserData?.fname} ${UserData?.lname}`}
                      />
                    </div>
                  
                  <input
                    type="file"
                    id="file"
                    className="rounded-circle photouser"
                    ref={inputFileCover}
                    onClick={openInputFileCover}
                    onChange={handleImageChangeCover}
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                  />
                  <div style={{ position: "absolute", top: "30px" }} className="w-100 mx-auto">
                   
                      <img
                        className="ms-img img-fluid"
                        src={UserData?.profile_pic ? UserData?.profile_pic : "/img/upload-photo.png"}
                        alt="Icon"
                      />
                
                    <input
                      type="file"
                      id="file"
                      className="rounded-circle photouser"
                      ref={inputFile}
                      onClick={openInputFile}
                      accept="image/png, image/jpeg"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <IoMdCamera
                      className="s-camera p-2 rounded-5 "
                      onClick={openInputFile}
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                  </div>
                      <h3 className="userNameStep text-center mt-5 pt-2">{UserData?.fname}</h3>
                  {/* side section */}
                  <div className="">
                    <label className="LabelStep">Business Summary</label>
                    <div className="d-flex justify-content-around px-4">
                      <textarea
                        name="summary"
                        placeholder="Tell us a little bit about your business"
                        className="form-control step-input-btn"
                        rows="1"
                        value={values.summary}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  {/* contact form */}
                  <label className="LabelStep">Business Contact</label>
                  <div className="d-flex justify-content-center pe-4">
                    <PhoneInput
                      name="Phone"
                      specialLabel={""}
                      country={"us"}
                      inputClass={"step-input-btn"}
                      placeholder="201-555-0123"
                      // countrydata={dialCode}
                      value="phone"
                      onChange={handleOnChange}
                    />
                  </div>
                  <label className="LabelStep">Enter company website</label>
                  <div className="col d-flex justify-content-center px-4">
                    <input
                      name="url"
                      type="url"
                      className="form-control step-input-btn"
                      value={values.url}
                      onChange={handleChange}
                    />
                  </div>
                  {/* What does your Business offers? */}
                  <label className="LabelStep">
                    What does your business offer?
                  </label>
                  <div className="d-flex flex-column align-items-center px-4">
                    <InputTags size="l"
                      style={{ padding:"10px 10px" }}
                      className="step-input-btn form-control"
                      onAppend={handleAppendOff}
                      onRemove={handleRemoveOff}>
                    {Offers.length<0?null:
                      Offers.map((tag, idx) => (
                      <Tooltip key={idx}>
                        <Tooltip.Trigger
                          tag={InputTags.Tag}
                          theme="primary"
                          data-id={idx}
                          onClick={handleEditOff}
                        >
                          <InputTags.Tag.Text>{tag}</InputTags.Tag.Text>
                          <InputTags.Tag.Close onClick={handleCloseOff} />
                        </Tooltip.Trigger>
                      </Tooltip>
                    ))}
                    <InputTags.Value
                      value={value3}
                      onChange={updateValue3}
                      onBlur={handleBlurInputOff}
                    />
                  </InputTags>
                    {/* <textarea
                      name="bussiness_offers"
                      id="Businessoffers"
                      className="form-control  step-input-btn"
                      rows="1"
                      placeholder=""
                      ref={ref}
                      value={values.bussiness_offers}
                      onChange={handleChange}
                    /> */}
                    <div className="Addrefinput w-100">
                      <p className="Addbtnp"  onClick={handleClick}>
                        Add Product or Service
                        <BsPlusLg
                          className="BsPlusLg-icon"
                          color="white"
                          size={14}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center px-4 pt-3 pb-2">
                    <button
                      name="submit"
                      type="submit"
                      className="submit-butn w-100"
                      style={{opacity:Offers.length>0?1:0.4}}
                      disabled={!(dirty && isValid)}
                      onClick={handleSubmit}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              <AiOutlineClose
                size={30}
                className="aioutline"
                onClick={handleClose}
              />
            </Col>
          )}
        </Formik>
      ) : (
        <PresonalSecondStep />
      )}
          {ProfilePicPopup === true ? (
              <ProfileImage
                src={Image}
                Full={inputFile.current.files[0]}
                handleClose={() => setProfilePicPopup(false)}
                type={"profile"}
              />
            ) : null}
       {CoverPicPopup === true ? (
              <ProfileImage
                src={ImageCover}
                Full={inputFileCover.current.files[0]}
                handleClose={() => setCoverPicPopup(false)}
                type={"Cover"}
              />
            ) : null}
      {/* <FourthStep /> */}
    </div>
  );
}

export default BussinessSteps;