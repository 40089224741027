import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Col, Row } from "reactstrap";
import "../../../assets/styles/Footer/blog.css";
import "../../../assets/styles/Footer/footerAbout.css";
import FooterNav from "./FooterNav";
import { useNavigate } from "react-router-dom";
import FootNav from "../../User_Profile/Footer/Footer-nav/FootNav";

const Blog = () => {
  const navigate = useNavigate()
  return (
    <div style={{ background: "#fff" }}>
      <FooterNav from={"Blog"}/>
      <div className="position-relative m-4">
        <Row>
          <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
            <div >
              <img
                src="/img/Blog1.png"
                className="card-img-top image-size"
                alt="sea view"
              />
              <div className="card blogcardsize">
                <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                    <h4 className="card-title blogh4">
                      <strong>How To Make 2020 Your Best Year Yet</strong>
                    </h4>
                
                  
                    <h5 className="blue-text pb-2">
                      <strong>Lisa Rowlett Leslie</strong>
                    </h5>
                
                  <p className="card-text">
                    A blog post on how to thrive during the pandemic and make
                    the year a success.
                  </p>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                  >
                    <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/ibougeinc"
                  >
                    <FaInstagram className="me-4 instahover" size={22} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
            <div className="pt-lg-0 pt-md-0 pt-sm-5" >
              <img
                src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                className="card-img-top image-size"
                alt="sea view"
              />
              <div className="card blogcardsize">
                <div className="card-body text-center">
                <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                    <h4 className="card-title blogh4">
                      <strong>Lisa Rowlett Leslie</strong>
                    </h4>
                  
                    <h5 className="blue-text pb-2">
                      <strong>Information Architect</strong>
                    </h5>
                
                  <p className="card-text">
                    This article is about iBouge's exciting networking platform
                    for travelers and bloggers. Click and be inspired..
                  </p>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                  >
                    <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/ibougeinc"
                  >
                    <FaInstagram className="me-4 instahover" size={22} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
            <div className="pt-lg-0 pt-md-5 pt-sm-5" >
              <img
                src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                className="card-img-top image-size"
                alt="sea view"
              />
              <div className="card blogcardsize">
                <div className="card-body text-center">
                <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>

                    <h4 className="card-title blogh4">
                      <strong>Lisa Rowlett Leslie</strong>
                    </h4>
                  
                    <h5 className="blue-text pb-2">
                      <strong>Information Architect</strong>
                    </h5>
                
                  <p className="card-text">
                    This article is about iBouge's exciting networking platform
                    for travelers and bloggers. Click and be inspired.
                  </p>
                </div>  
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                  >
                    <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/ibougeinc"
                  >
                    <FaInstagram className="me-4 instahover" size={22} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
            <div className="pt-lg-0 pt-md-5 pt-sm-5" >
              <img
                src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                className="card-img-top image-size"
                alt="sea view"
              />
              <div className="card blogcardsize">
                <div className="card-body text-center">
                <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                    <h4 className="card-title blogh4">
                      <strong>Lisa Rowlett Leslie</strong>
                    </h4>
                  
                    <h5 className="blue-text pb-2">
                      <strong>Information Architect</strong>
                    </h5>
                
                  <p className="card-text">
                    This article is about iBouge's exciting networking
                    platform for travelers and bloggers. Click and be
                    inspired..
                  </p>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                  >
                    <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                  >
                    <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/ibougeinc"
                  >
                    <FaInstagram className="me-4 instahover" size={22} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="">
          <Row>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p></div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="">
        <Row>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p></div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3" xxl="3">
              <div className="pt-lg-5 pt-md-5 pt-sm-5" >
                <img
                  src="https://media1.popsugar-assets.com/files/thumbor/_Ffw5vxGx6wl0dz9zPHNaHHH5EE/fit-in/1024x1024/filters:format_auto-!!-:strip_icc-!!-/2013/09/04/073/n/1922441/fcae5df5ab359e8d_travel-photo-checklist/i/Travel-Photo-Ideas.jpg"
                  className="card-img-top image-size"
                  alt="sea view"
                />
                <div className="card blogcardsize">
                  <div className="card-body text-center">
                  <div onClick={() => navigate("/blogLanding")} style={{cursor:"pointer" , paddingBottom:"10px"}}>
                      <h4 className="card-title blogh4">
                        <strong>Lisa Rowlett Leslie</strong>
                      </h4>
                    
                      <h5 className="blue-text pb-2">
                        <strong>Information Architect</strong>
                      </h5>
                    
                    <p className="card-text">
                      This article is about iBouge's exciting networking platform
                      for travelers and bloggers. Click and be inspired.
                    </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaLinkedinIn className="me-4" size={22} color={"#0077B5"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                    >
                      <FaTwitter className="me-4" size={22} color={"#1DA1F2"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                    >
                      <FaFacebookF className="me-4" size={22} color={"#3b5998"} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/ibougeinc"
                    >
                      <FaInstagram className="me-4 instahover" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

      </div>
      <footer>
        <p>iBouge ® • Copyright © 2024 iBouge Inc. • All Rights Reserved.</p>
        <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
      </footer>
      <FootNav from={"Blog"}/>
    </div>
  );
};

export default Blog;
