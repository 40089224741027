import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { GrFormPrevious } from "react-icons/gr";
import { MdOutlineNavigateNext } from "react-icons/md";
import "../css/pagination.css";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 25;

const Events = () => {
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const [q, setQ] = useState("");
  const list = [
    "Restaurant",
    "Entertainment",
    "Health & Fitness",
    "Shopping",
    "Offer/Discount",
    "Hobby",
    "Other",
  ];
  function searchData() {
    // return activities;
    return Data.filter((user) => {
      if (q === "") {
        return user;
      } else if (user?.category.toLowerCase().includes(q.toLowerCase())) {
        return user;
      }
    });
  }
  const EventApi = () => {
    var config = {
      method: "get",
      url: Server2 + "/events/allEvents",
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        console.log(response.data);
        response.data.sort(
          (b, c) => new Date(c.createdDate) - new Date(b.createdDate)
        );
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const deleteEvent = (e) => {
    const data = {
      by:userData?._id,
      eventId: e,
      disable:true
    };
    let config = {
      method: "post",
      url: Server2 + "/events/disable",
      data: data,
    };
    axios(config)
      .then((response) => {
        EventApi();
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  // API'S Approved request Starts
  const handelDeleteRequest = (e) => {
    const data = {
      id: e,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  useEffect(() => {
    setLoading(true);
    EventApi();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //Remaning Days functions
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    Data.length === 0
      ? null
      : searchData()
          .slice(offset, offset + PER_PAGE)
          .map((user, index) => (
            <tbody key={index}>
              <tr>
                <td>
                  <div className="d-flex px-2 py-1">
                    <div>
                      <img
                        src={
                          user.eventImage
                            ? user.eventImage
                            : "/img/upload-photo.png"
                        }
                        className="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm text-capitalize ">
                        {user.name}
                      </h6>
                      <p className="text-xs text-secondary mb-0">
                        {user.email}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="align-middle ms-4 ">{user.category}</p>
                </td>
                <td>
                  <p className="align-middle ">
                    {moment(user.createdDate).format("MMMM Do, YYYY  h:mm A")}
                  </p>
                </td>
                <td className="allign-middle">
                  <div className="d-flex justify-content-center ">
                    <Button
                      style={{
                        background: "#6148a1",
                        border: "none",
                        marginRight: 10,
                      }}
                      onClick={() => {
                        navigate("/Event", {
                          state: {
                            item: user,
                          },
                        });
                      }}
                    >
                      View Event
                    </Button>
                    {/* <Button style={{ background: '#6148a1', border: 'none' }} onClick={() => handelAprovedRequest(user)}></Button> */}
                    <Dropdown className="border-0 rounded-2 add-btn fw-bold">
                      <Dropdown.Toggle
                        className="border-0"
                        id="dropdown-basic"
                        aria-expanded="false"
                        style={{ backgroundColor: "#6148a1" }}
                      >
                        <button
                          type="button"
                          className="bg-transparent border-0 text-white"
                          data-bs-toggle="button"
                        >
                          <spam className="d-inline postition-relative text-white">
                            Take Action
                          </spam>
                        </button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100 text-center">
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => deleteEvent(user?._id)}
                          style={{ fontSize: 13 }}
                        >
                          Delete Event
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="2"
                          onClick={() => handelDeleteRequest(user?.createdBy)}
                          style={{ fontSize: 13 }}
                        >
                          Disable User Account
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          ));

  const pageCount = Math.ceil(searchData().length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <h6>Events </h6>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase">Event Name</th>
                            <th className="">
                              <Dropdown className="">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase"
                                    style={{ color: "#67748e" }}
                                  >
                                    Category{" "}
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    setQ("");
                                  }}
                                >
                                  <span className="fw-bold">
                                    All Events
                                  </span>
                                </Dropdown.Item>
                                  {
                                    list.map((e) =>{
                                      return(
                                          <Dropdown.Item
                                          onClick={() => {
                                            setQ(e);
                                          }}
                                        >
                                          <span className="fw-bold">
                                            {e}
                                          </span>
                                        </Dropdown.Item>
                                      )
                                    })
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                            <th className=" text-uppercase ps-5">
                              Created Date
                            </th>
                            <th className="text-uppercase text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {currentPageData}
                      </table>
                    </div>

                    {searchData().length > 24 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          t
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Events;
