import React, { useRef, useEffect, useState, useMemo } from "react";
import { RiPencilFill } from "react-icons/ri";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { DashCitySelect } from "../../../../redux/actions/auth/auth.actions";
const MapboxCircle = require('mapbox-gl-circle');
var myCircle;
export default function Map({ setCoords,setTopCities, CityName, SetCitiesData, CityNameComp ,latestCity ,setLatestCity,GetPosts, UserEvent, UserMicro,GetStatusBookMarkApi,BookMarkEventApi }) {
  const dispatch = useDispatch()
  const memoizedSelectedData = useSelector((state) => state?.auth?.userCity);
  const currentCity = useMemo(() => memoizedSelectedData, [memoizedSelectedData]);
  console.log(currentCity,"i ma useSelector")
  const userData = useSelector((state) => state?.auth?.user?.user);
  const Loc = localStorage.getItem("location");
  const coord = Loc.split(",");
  const cityfirst = userData?.location?.extraCityToFollow0?.cityName;
  const city1 = cityfirst.split(",");
  const citysecond = userData?.location?.extraCityToFollow1?.cityName
    ? userData.location.extraCityToFollow1.cityName
    : " ,";
  const city2 = citysecond?citysecond.split(","):"";
  const citythird = userData?.location?.extraCityToFollow2?.cityName
    ? userData.location.extraCityToFollow2.cityName
    : "";
  const city3 = citythird!== null?citythird.split(","):" ";
  const [city, setCity] = useState(latestCity!== undefined?latestCity.city:currentCity?currentCity:"" );
  CityName(city)
  const Cities = [
    [0, city1[0]],
    [1, city2[0]],
    [2, city3[0]],
  ];
  useEffect(() =>{

    setCity(latestCity!== undefined?latestCity.city:currentCity?currentCity:"")
  },[latestCity])  // eslint-disable-line react-hooks/exhaustive-deps

  const [mapObject, setMap] = useState();
  const mapContainerRef = useRef(null);

  const NewCityCoord = latestCity!== undefined?[latestCity.latLng[0],latestCity.latLng[1]] : [coord[0], coord[1]]

  useEffect(() => {

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken:
        process.env.REACT_APP_MAPBOX_KEY,
        style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
      center: NewCityCoord,
      zoom: 7.75,
    });
    map.scrollZoom.disable()
    map.dragPan.disable()
    map.on("load", function () {
      if(latestCity!==undefined){
          const center = map.getCenter({ lat:latestCity.latLng[0] , lng: latestCity.latLng[1] })
          myCircle = new MapboxCircle(center, 25000, {
            minRadius: 1500,
            strokeColor: "#FAC82D",
            fillColor: "#FEA115",
            fillOpacity: 0.4,
          }).addTo(map);
      }else{
        const center = map.getCenter({ lat: coord[1], lng: coord[0] })
        myCircle = new MapboxCircle(center, 25000, {
          minRadius: 1500,
          strokeColor: "#FAC82D",
          fillColor: "#FEA115",
          fillOpacity: 0.4,
        }).addTo(map);
      }
    });
    setMap(map);
    SetCitiesData({ Selected:latestCity!== undefined?latestCity.city:city, AllCities: Cities });
  }, [latestCity]);  // eslint-disable-line react-hooks/exhaustive-deps
  const setMapCenter = (coords, e) => {
    if(myCircle!== undefined){
      myCircle.remove(mapObject)
    }
     myCircle = new MapboxCircle({ lat: coords[1], lng: coords[0] }, 25000, {
      strokeColor: "#FAC82D",
      fillColor: "#FEA115",
      fillOpacity: 0.4,
    }).addTo(mapObject);
  }

  const handleChange = (e) => {
    if(e.target.value === city1[0]){
      CityNameComp(cityfirst)
    }else if(e.target.value === city2[0]){
      CityNameComp(citysecond)
    } else{
      CityNameComp(citythird)
    }
    dispatch(DashCitySelect(e.target.value))
    setCity(e.target.value);
    CityName(e.target.value);
    getlan(e.target.value);
    GetPosts(e.target.value)
    UserEvent(e.target.value) 
    UserMicro(e.target.value)
    GetStatusBookMarkApi()
    BookMarkEventApi()
    SetCitiesData({ Selected: e.target.value, AllCities: Cities });
    setLatestCity()
  };
  const getlan = (e) => {
      var config = {
        method: "GET",
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${e}.json?access_token=` + process.env.REACT_APP_MAPBOX_KEY,
      };
      axios(config).then((response)=>{
        var location = [
          response?.data?.features[0]?.center[0],
          response?.data?.features[0]?.center[1],
        ]
        localStorage.setItem("location",location);
      mapObject.flyTo({
        center: [response?.data?.features[0]?.center[0],
        response?.data?.features[0]?.center[1]],
        essential: true,
        zoom: 7.65,
        duration: 3000,
      });
      setTimeout(() => {
        setMapCenter(location, e)
      }, 3000);
    });
  };

  return (
    <>
      <div className="side-bar border rounded-3 shadow">
        <div className="row">
          <div className=" d-flex justify-content-between py-3">
            <div className="d-flex w-100 mx-3">
              <select
                className="form-select form-select-sm map-form"
                name="Cities"
                onChange={(e) => {
                  if (e.target.value === "Add City") {
                    setTopCities()
                  } else {
                    handleChange(e)
                  }
                }
                }
                value={city}
              >
                {Cities.map((cities, index) => {
                  return cities[1] === "" || cities[1] === " "  ? (
                    <option
                      key={index}
                      className="p-2 text-primary"
                    >
                      Add City
                    </option>
                  ) : (
                    <option className="p-2 text-dark" key={index}>
                      {cities[1]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex">
              <button
                className="btn edit-btn border-0 d-flex align-items-center text-dark"
                type="button"
                onClick={() => {
                  setTopCities();
                }}
              >
                Edit
                <RiPencilFill className=" ms-2 pencil" />
              </button>
            </div>
          </div>
        </div>
        <div className="map-wrap-dash">
          <div className="map-dash ms-2" ref={mapContainerRef}></div>
        </div>
      </div>
    </>
  );
}
