import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Server2, SetPrivacy, UserAccountDeactivate, logout } from "../../../redux/actions/auth/auth.actions";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import DeactiveButtons from "../../../components/DeactiveButtons";

const Privacy = () => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [NewMessage, setNewMessage] = useState(userData?.privacy?.new_messages);
  const [ProfileInfo, setProfileInfo] = useState(
    userData?.privacy?.profile_info_private
  );
  const [FriendsList, setFriendsList] = useState(
    userData?.privacy?.friend_list_private
  );
  const [Invitations, setInvitations] = useState(
    userData?.privacy?.wall_private
  );
  // block Section States
  const [Text, setText] = useState("");
  const [SearchUsers, setSearchUsers] = useState([]);
  // console.log(SearchUsers, "SearchUsers----");
  const [SearchToggle, setSearchToggle] = useState(false);
  const [BlockItems, setBlockItems] = useState([]);
  const [popup, setpopup] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState({
    type: false,
    status: "Deactive"
  });
 
  // Update/Post Comments On Status
  const PayloadPrivacy = {
    email: "",
    new_messages: "",
    profile_info_private: "",
    friend_list_private: "",
    wall_private: "",
  };
  const PayloadPrivacyFunc = () => {
    PayloadPrivacy.email = userData.email;
    PayloadPrivacy.new_messages = NewMessage;
    PayloadPrivacy.profile_info_private = ProfileInfo;
    PayloadPrivacy.friend_list_private = FriendsList;
    PayloadPrivacy.wall_private = Invitations;
  };

  const ApiPrivacy = () => {
    PayloadPrivacyFunc();
    dispatch(SetPrivacy(PayloadPrivacy)).then((res) =>{
      console.log(res,"res,,")
      if(res.data){
      setpopup(true)
      setTimeout(() =>{
        setpopup(false)
      },3000)
      }
    });
  };
  useEffect(() => {
    GetUserBlock();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Block User Start
  useEffect(() => {
    if (Text.length > 0) {
      var config = {
        method: "get",
        url: Server2 + `/users/globalSearch?id=${userData?._id}&q=${Text}`,
      };
      axios(config)
        .then(function (response) {
          setSearchUsers([]);
          setSearchUsers(response.data);
          console.log(response.data, "response.data");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [Text]); // eslint-disable-line react-hooks/exhaustive-deps

  const GetUserBlock = () => {
    var config = {
      method: "GET",
      url: Server2 + `/users/getBlockList?id=${userData?._id}`,
    };
    axios(config)
      .then((response) => {
        console.log("respnose use block list", response.data);
        setBlockItems(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const blockUser = (e) => {
    console.log(e);
    let data = JSON.stringify({
      id: userData?._id,
      block_user: e,
    });
    var config = {
      method: "post",
      url: Server2 + "/users/block",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "BlockUser");
        GetUserBlock();
        setSearchToggle(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const unBlockUser = (e) => {
    let data = JSON.stringify({
      id: userData?._id,
      unblock_user: e,
    });
    var config = {
      method: "post",
      url: Server2 + "/users/unblock",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "unBlockUser");
        GetUserBlock();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
   // API'S Deactivate Starts
   const PayloadDeactive = {
    email: userData?.email,
    id: userData?._id,
    status: userData?.is_deactivated ? false : true

  };
  const DeActive = () => {
    dispatch(UserAccountDeactivate(PayloadDeactive)).then((res) => {
      console.log(res, "i am res")
      if (res?.data) {
        dispatch(logout());
        navigate("/", { replace: true });
        localStorage.clear();
      }
    });
  };
  const DeleteAccount = () =>{
    var data = {
      id: userData?._id,
      email: userData?.email,
      status: true
    };
    let config = {
      method: "post",
      url: Server2 + "/users/deletion-apply",
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.status){
          dispatch(logout());
          navigate("/", { replace: true });
          localStorage.clear();
        }
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  }
  return (
    <>
      <p className="ps-title text-center text-dark fs-5">Privacy</p>
      <div className=" m-3 rounded-3 shadow">
        <div className="m-3">
          <div className="d-flex pt-3 justify-content-between rounded-3">
            <label className="privacy-label">
              Make my wall private to friends only
            </label>
            <label className="switch">
              <input
                type="checkbox"
                checked={NewMessage}
                onChange={(e) => setNewMessage(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex pt-3 justify-content-between rounded-3">
            <label className="privacy-label">
              Make my profile private to my friends only
            </label>
            <label className="switch">
              <input
                type="checkbox"
                checked={ProfileInfo}
                onChange={(e) => setProfileInfo(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex pt-3 justify-content-between rounded-3">
            <label className="privacy-label">
              Show my friend list only to my friends
            </label>
            <label className="switch ">
              <input
                type="checkbox"
                checked={FriendsList}
                onChange={(e) => setFriendsList(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex pt-3 justify-content-between rounded-3">
            <label className="privacy-label">
              I accept new messages from outside my friend group
            </label>
            <label className="switch ">
              <input
                type="checkbox"
                checked={Invitations}
                onChange={(e) => setInvitations(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <p className="ps-title py-2 text-center text-dark">Block List</p>
          <div className="d-flex justify-content-between align-items-center pb-3 ">
            <div className="d-flex align-items-center">
              <span className="text-nowrap fw-semibold text-dark">
                Block Users
              </span>
            </div>
            <div className="">
              <div className="">
                <input
                  style={{ backgroundColor: "#ECECEC" }}
                  type="text"
                  placeholder="Type to Name"
                  className="form-control ps-3 py-1"
                  onChange={(e) => setText(e.target.value)}
                  onClick={() => {
                    setSearchToggle(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pb-3">
            {SearchToggle === true ? (
              <div className="">
                <div className="notibox-Header text-white rounded-1 mt-3">
                  <span className="py-2 fw-bolder fs-6 px-4">Searching...</span>
                  <FiX
                    className="fs-4 text-white me-3"
                    role="button"
                    onClick={() => setSearchToggle(false)}
                  />
                </div>
                <div className="notifications pt-2">
                  {SearchUsers.length < 0
                    ? ""
                    : SearchUsers.map((noti, index) => {
                        const intrest = noti.profile.interests[0]
                          ? noti.profile.interests[0].length > 20
                            ? noti.profile.interests[0].substring(0, 20) + "..."
                            : noti.profile.interests[0]
                          : "";
                        return (
                          <div key={index}>
                            {noti?._id !== userData?._id ? (
                              <div className="d-flex justify-content-between bg-light">
                                <div
                                  className="d-flex align-items-center mx-3 py-2 rounded-3 ps-3  mb-1"
                                  style={{ cursor: "pointer" }}
                                  // onClick={() => {handleClick(noti)}}
                                >
                                  <div className="pe-3">
                                    <img
                                      src={
                                        noti.profile_pic
                                          ? noti.profile_pic
                                          : "/img/upload-photo.png"
                                      }
                                      height={40}
                                      className="rounded-circle image-user"
                                      alt="ibouge"
                                    />
                                  </div>
                                  <div className="namees text-dark">
                                    <span className="fw-bold">
                                      {noti.fname} {noti.lname}
                                    </span>
                                    <h6 className="info">{intrest}</h6>
                                  </div>
                                </div>
                                <button
                                  className="btn my-4 mx-2 text-white"
                                  style={{
                                    backgroundColor: "#fea115",
                                    fontSize: "12px",
                                  }}
                                  onClick={() => blockUser(noti._id)}
                                >
                                  Block
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
            ) : (
              <div
                className=""
                style={{ maxHeight: "200px", overflow: "auto" }}
              >
                {BlockItems.length === 0 ? (
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Block list is empty
                  </div>
                ) : (
                  BlockItems.map((item, index) => {
                    const intrest = item.profile.interests[0]
                      ? item.profile.interests[0].length > 20
                        ? item.profile.interests[0].substring(0, 20) + "..."
                        : item.profile.interests[0]
                      : "";
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between bg-light mt-2">
                          <div
                            className="d-flex align-items-center mx-3 rounded-3 ps-3  mb-1"
                            style={{ cursor: "pointer" }}
                            // onClick={() => {handleClick(noti)}}
                          >
                            <div className="pe-3">
                              <img
                                src={
                                  item.profile_pic
                                    ? item.profile_pic
                                    : "/img/upload-photo.png"
                                }
                                height={40}
                                className="rounded-circle image-user"
                                alt="ibouge"
                              />
                            </div>
                            <div className="namees text-dark">
                              <span className="fw-bold">
                                {item.fname} {item.lname}
                              </span>
                              <h6 className="info">{intrest}</h6>
                            </div>
                          </div>
                          <button
                            className="btn my-4 mx-2 text-white"
                            style={{
                              backgroundColor: "#fea115",
                              fontSize: "12px",
                            }}
                            onClick={() => unBlockUser(item._id)}
                          >
                            Unblock
                          </button>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
            
          {
            confirmOpen.type === false ? null :
              <div className="text-center mt-2" style={{ backgroundColor: "#d2f8d2", borderRadius: "8px" }}>
                <p className="pt-1"> Are you sure you want to {confirmOpen.status==="Delete"?"Delete":"Deactivate"} Account? </p>
                <div className="d-flex justify-content-evenly pb-2 mb-1">
                  <button className="border-0 rounded-2 w-25 p-2 " onClick={() => setConfirmOpen({
                    type: false,
                    status: "Deactive"
                  })}>Cancel</button>
                  <button className="border-0 rounded-2 w-25 p-2 bg-primary text-white"
                    onClick={() => {
                      confirmOpen.status==="Delete"?
                      DeleteAccount():
                      DeActive()
                      }}
                  >Confirm</button>
                </div>
              </div>
          }
          <DeactiveButtons setConfirmOpen={(e) => setConfirmOpen(e)} />
          </div>
          {/* testing */}
        </div>
      </div>
      { popup === true ?
          <div style={{backgroundColor:"#d2f8d2" , margin:"10px 20px" , padding:"6px 15%" ,  borderRadius:"8px"}}>
          <BsCheckCircle style={{marginRight:"15px"}} color="#0b4a0b" size={18} />
          <span style={{fontSize:"13px"}}>Privacy has been updated</span>
          </div>:null
        }
      <div className="s-buttons d-flex pb-3 mx-3">
        <button
          className="btn fs-6 fw-bold shadow w-50 btn-lg me-2"
          type="button"
          data-bs-toggle="button"
          onClick={() => ApiPrivacy()}
        >
          Save
        </button>
        <button
          className="btn fs-6 fw-bold shadow w-50 btn-lg ms-2"
          type="button"
          data-bs-toggle="button"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default Privacy;
