import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import "../css/pagination.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { useNavigate } from "react-router-dom";

const PER_PAGE = 25;
const UsersReviewList = () => {
  const [business, setBusinesslUser] = useState([]);
  console.log(business);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user);

  // API'S MakeDeletionApproved Starts
  const getReviewables = () => {
    const data = {
      id: userData?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/get-reviewables",
      data: data,
    };

    axios(config)
      .then(function (response) {
        setBusinesslUser(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
        setBusinesslUser([])
      });
  };

  // API'S Approved request Starts
  const handelAprovedRequest = (e) => {
    const data = {
      email: e?.email,
      id: e?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/approve-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
        getReviewables();
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  // API'S Approved request Starts
  const handelRejectRequest = (e) => {
    const data = {
      email: e?.email,
      id: e?._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/reject-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  useEffect(() => {
    setLoading(true);
    getReviewables();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //pagination function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  //Remaning Days functions
  // function addDays(date, days) {
  //   var result = new Date(date);
  //   result.setDate(result.getDate() + days);
  //   return result;
  // }

  const calculate_Days = (created_date) => {
    const createdDate = new Date(created_date);
    const month = ["Jan","Feb","March","April","May","June","July","August","September","October","November","December"];
    const date = createdDate.getDate() + " " + month[createdDate.getMonth()] + ", " + createdDate.getHours() + ":" + createdDate.getMinutes();
    // let currentDate = addDays(createdDate, 15);
    // const mydate = new Date();
    // const difference = currentDate.getTime() - mydate.getTime();
    // var days_difference = Math.round(difference / (1000 * 60 * 60 * 24));
    return date;
  };
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    business.length === 0
      ? null
      : business.slice(offset, offset + PER_PAGE).map((user, index) => (
          <tbody key={index}>
            <tr>
              <td
                onClick={() =>
                  navigate(`/Profiless/${user?._id}`, {
                    state: {
                      item: user._id,
                    },
                  })
                }
                style={{cursor:"pointer"}}
              >
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={
                        user.profile_pic
                          ? user.profile_pic
                          : "/img/upload-photo.png"
                      }
                      className="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm text-capitalize ">
                      {user.fname} {user.lname}
                    </h6>
                    <p className="text-xs text-secondary mb-0">{user.email}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="align-middle mb-n1">
                  {user.business_category? user.business_category : "unknown"}
                </p>
                <span className="text-xs text-secondary my-0">{calculate_Days(user.created_at)}</span>
              </td>
              <td>
                <p className="align-middle ">
                 {user.deactivated_by_admin === true ? "True": "False"}
                </p>
              </td>
              <td className="">
                <Button
                  style={{ background: "#6148a1", border: "none", marginRight:"10px" }}
                  onClick={() => {handelAprovedRequest(user)}}
                >
                  Accept
                </Button>
                <Button
                  style={{ background: "#2dcecc", border: "none" }}
                  onClick={() => {handelRejectRequest(user)}}
                >
                  Reject
                </Button>
              </td>
            </tr>
          </tbody>
        ));

  const pageCount = Math.ceil(business.length / PER_PAGE);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem", color: "#6148A1" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <h6>Business Users </h6>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task"
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      <table className="table align-items-center mb-0">
                      {currentPageData !== null ?
                        <>
                          <thead>
                              <tr>
                                <th className="text-uppercase">User</th>
                                <th className="text-uppercase">Category</th>
                                <th className="text-uppercase">Rejected Status</th>
                                <th className="text-uppercase">Actions</th>
                              </tr>
                          </thead>
                          {currentPageData}
                        </> :
                          <div className="d-flex justify-content-center w-100">
                              <p>No Reviewables Accounts</p>
                          </div>
                        }
                      </table>
                    </div>

                    {business.length > 24 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          t
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UsersReviewList;