import React, { useEffect, useRef, useState } from "react";
import { BsFillCalendarFill } from "react-icons/bs";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";
import NavbarHome from "../NavbarNew/NavbarHome";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Server2, Socket } from "../../../redux/actions/auth/auth.actions";
import moment from "moment";
import { FaBookmark,  FaCaretDown,  FaCaretUp,  FaUserPlus } from "react-icons/fa";
import InviteEventPopup from "./InviteEventPopup";
import { MdBookmarkAdded } from "react-icons/md";
let screenSize = window.innerWidth;

const EventData = ({ item, userData, bookmarkGet, setbookmarkGet, setRendering }) => {
  console.log(item,"items")
  const [GoingDisable, setGoingDisable] = useState(false)
  const [AddUserOpen, setAddUserOpen] = useState(false);
  const [ClickUp, setClickUp] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    item.going.forEach((going) => {
      if (going.userId === userData._id) {
        setGoingDisable(true)
      }
    })
  }, [GoingDisable]) // eslint-disable-line react-hooks/exhaustive-deps
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(8);
  const [API_KEY] = useState(process.env.REACT_APP_MAPBOX_API_KEY);
  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/positron/style.json?key=${API_KEY}`,
      center: [
        item?.location?.coordinates[0]?.coords?.lng,
        item?.location?.coordinates[0]?.coords?.lat,
      ],
      zoom: zoom,
    });
    map.current.scrollZoom.disable()
    map.current.dragPan.disable()
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    new mapboxgl.Marker({ color: "#614A7E" })
      .setLngLat([
        item?.location?.coordinates[0]?.coords?.lng,
        item?.location?.coordinates[0]?.coords?.lat,
      ])
      .addTo(map.current);
  });

  const goingApi = () => {
    var data = JSON.stringify({
      eventId: item?._id,
      user: userData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/events/going",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setGoingDisable(true)
        setRendering()
        const data = {
          eventId: item?._id,
          userid: userData._id,
          to: response.data.createdBy
        }
        Socket.emit("going-to-event", data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  const notGoingApi = () => {
    var data = JSON.stringify({
      eventId: item?._id,
      user: userData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/events/notgoing",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        setGoingDisable(false)
        setRendering()
        const data = {
          eventId: item?._id,
          userid: userData._id,
          to: response.data.createdBy
        }
        Socket.emit("not-going-to-event", data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const boookmarkEvent = () => {
    var data = JSON.stringify({
      eventId: item?._id,
      id: userData._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/events/bookmark-event",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        if (response.data.message === "event bookmarked successfully.") {
          setbookmarkGet(true)
        } else {
          setbookmarkGet(false)
        }
        console.log(response.data, "boook")
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  const handleClick = (e) => {
    navigate(`/Profiless/${e?.userId}`, {
      state: {
        item: e.userId,
      },
    });

  }
  return (
    <div className="bg-white">
      <div className="main-event px-2 px-md-3">
        <div className="img-cover-event mb-5 px-1">
          <div
            className="cover-event"
            style={{
              backgroundImage:`url(${item.eventImage})`,
              height: "400px",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>

          <div className="profile d-lg-flex justify-content-between mx-lg-5">
            <div className="px-lg-2 px-md-5 px-sm-3 px-2">
              <p className="fw-bold fs-3 text-lg-start text-md-start text-start text-white event-name">
                {item?.name}
              </p>
              <div className="d-flex">
                <BsFillCalendarFill color="white" style={{ marginTop: "3px" }} />
                <p className="text-white ps-2 ps-sm-3 event-name">
                  {moment(item.dateOfEvent).format('MMMM Do, YYYY')} at&nbsp;
                  {moment(item.eventStartTime, "HH:mm:ss").format("hh:mm A")} - {moment(item.eventEndTime, "HH:mm:ss").format("hh:mm A")}
                </p>
              </div>
            </div>
            <div className="mx-lg-5 px-lg-1 px-md-5 px-2 pt-lg-5 pt-2">
              <button className="not-goingbtn" onClick={() => notGoingApi()}
                // disabled={GoingDisable === false ? true : false}
              >
                NOT GOING
              </button>
              <button
                className="goingbtn py-2 ms-2"
                onClick={() => goingApi()}
                // disabled={GoingDisable === true ? true : false}
              >
                GOING
              </button>
            </div>
          </div>
        </div>
        <div className="d-lg-flex d-block justify-content-around">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 px-5 ">
            <h4 className="event-des-head">Event Description</h4>
            <span className="event-des-text fw-bold">{item?.description}</span>
            <span className="event-des-text mt-4">
              {item?.going.length} of your friends are going.
            </span>

            <p className="mt-4 fs-6 text-dark fw-bold">
            {ClickUp === false?
            <button className="scroll-down-button" onClick={() => setClickUp(true)}>
              <FaCaretUp color="black" size={24} />
            </button>
            :
            <button className="scroll-down-button" onClick={() => setClickUp(false)}>
              <FaCaretDown color="black" size={24} />
            </button>
          }
            {item.going.length} Users are Interested</p>
            {ClickUp === true?
            <div className="friends rounded-3 shadow" style={{ height: "fit-content"}}>
            <div className="friends_row pt-2" style={{ height: "fit-content", marginBottom: "0px", paddingBottom: "0px" }}>
          {
            item.going.map((friends, index) => {
              return (
                <div className=" col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 px-1 mx-2 text-center"
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => handleClick(friends)}
                >
                  <img className="img rounded-5"
                    src={friends.profile_pic ? friends.profile_pic : "/img/upload-photo.png"}
                    style={{ width: "40px", height: "40px" }}
                    alt="Admin"
                  />
                  {/* {friends?.is_online == true ?
                    <img
                      src="/Images/contact-online.png"
                      className=""
                      style={{
                        position: "relative",
                        right: "11px"
                        , top: "17px"
                      }}
                      height={9}
                      alt="dot sign"
                    /> :
                    <img
                      src="/Images/contact-idle.png"
                      className=""
                      style={{
                        position: "relative",
                        right: "11px"
                        , top: "17px"
                      }}
                      height={9}
                      alt="dot sign"
                    />} */}

                  <p className="jacob"
                    style={{ wordBreak: friends.fname.length > 10 ? "break-word" : "normal" }}
                  >
                    {friends.fname} <br />
                    {friends.lname}
                  </p>
                </div>
              )
            })
          }
        </div>
        </div>:null}
          </div>
          
          {/* Invite & Bookmark */}
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="p-3 pt-md-0" style={{ marginTop: "-10px", marginleft: "auto", marginRight: screenSize < 564 ? "auto" : "15%" }}>
              <div className="d-flex justify-content-center justify-content-sm-end">
                <button className="not-goingbtn" style={{ height: "40px" }} onClick={() => boookmarkEvent()}>
                  {bookmarkGet === true ?
                    <MdBookmarkAdded size={18} />
                    :
                    <FaBookmark  />
                  }&nbsp;
                  {bookmarkGet === true ? "Bookmarked" : "Bookmark"}
                </button>
                <button className="goingbtn ms-2" onClick={() => setAddUserOpen(true)}>
                  <FaUserPlus />&nbsp; Invite
                </button>
              </div>
            </div>
            {AddUserOpen &&
              <InviteEventPopup handleClose={() => setAddUserOpen(!AddUserOpen)} AddUserOpen={AddUserOpen} item={item} />
            }
            <div className="map-wrap-event-screen rounded-3 shadow">
              <div ref={mapContainer} className="map-event-screen" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

const EventScreen = () => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const { state } = useLocation();
  const { item } = state;
  const [EventItem, setEventItem] = useState(null)
  const [Loading, setLoading] = useState(true)
  const [bookmarkGet, setbookmarkGet] = useState(false)
  const [Rendering, setRendering] = useState(false)

  useEffect(() => {
    var config = {
      method: "get",
      url: Server2 + `/events/event-clicked?eventId=${item._id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setEventItem(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    GetBookmarkerd()
  }, [Rendering]) // eslint-disable-line react-hooks/exhaustive-deps
  const GetBookmarkerd = () => {
    var config = {
      method: "get",
      url: Server2 + `/events/getBookmarkedEvents?user=${userData._id}&eventId=${item._id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "bookmaerfget")
        if (response.data.if_boomarked === true) {
          setbookmarkGet(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
    <NavbarHome />
      <div style={{ height: EventItem === null ? "100vh" : "" }}>

        {Loading === true ? <div style={{ display: "flex", justifyContent: "center", height: "100vh", margin: "5%" }}><div className="loading"></div></div> :
          EventItem != null ?
            <div>
              <EventData item={EventItem} userData={userData} bookmarkGet={bookmarkGet} setbookmarkGet={() => setbookmarkGet(!bookmarkGet)} setRendering = {() => setRendering(!Rendering)} />
            </div>
            : null
        }
      </div>
    </>
  );
};

export default EventScreen;