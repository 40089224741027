import React from 'react'
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  const handleAbout = () => {
    navigate("/", { replace: true })
  }
  const handleContact = () => {
    navigate("/contact", { replace: true })
  }
  const handleMission = () => {
    navigate("/mission.html", { replace: true })
  }
  const handleDemo = () => {
    navigate("/Demo", { replace: true })
  }
  const handlePress = () => {
    navigate("/press", { replace: true })
  }
  const handleBlog = () => {
    navigate("/blog.html", { replace: true })
  }
  return (
    <div>
      <div className="footerWrap">
        <button onClick={handleAbout}>
          About
        </button>
        <button onClick={handleContact}>
          Contact
        </button>
        <button onClick={handleMission}>
          Mission
        </button>
        <button onClick={handleDemo}>
          Demo
        </button>
        <button onClick={handlePress}>
          Press
        </button>
        <button onClick={handleBlog}>
          Blog                  </button>
        <button onClick={() => navigate('/privacy')}>
          Privacy
        </button>
        <button onClick={() => navigate('/terms')}>
          Terms
        </button>
      </div>
    </div>
  )
}

export default Footer
