import React from 'react'
import "../../../assets/styles/Footer/pressRelese.css"
import "../../../assets/styles/Footer/footerAbout.css"
import FooterNav from './FooterNav';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const PressRelese2 = () => {
    document.documentElement.scrollTop = 0;
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start': 
            new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    }
      )(window, document, 'script', 'dataLayer', 'GTM-NWKST7R');
    return (
        <>
           {/* <!-- Google Tag Manager (noscript) --> */}
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NWKST7R" title='Googlr Tag Manager' height="0" width="0"
        style={{display:"none",visibility:"hidden"}}></iframe></noscript>
     {/* End Google Tag Manager (noscript) */}
            <div style={{ backgroundColor: "#fff" }}>
                <FooterNav />
                <div className="container pb-5" >
                    <section className="mt-5 pb-3">

                        <div className="card" style={{ border: "none" }}>
                            <div className="text-center">
                                <img className="card-img-top rounded-5" style={{ maxWidth: "1400px" ,maxHeight:"80vh" , objectFit:"contain" }} src={"/img/LisaLeslie Proofs-042.jpg"} alt="Cardimage cap" />
                            </div>
                            <div className="text-center pb-4" style={{ margin: "-50px 25px 0px 25px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", background: "#fff", paddingTop: "20px", borderRadius: "4px" }}>
                                <h2 className='text-dark'><strong id="blogTitle">No Boundaries: How Lisa Leslie Is Making the World a Little
                                    Smaller</strong></h2>
                                <p>Published by University of Washington Coding Boot Camp, 7/05/2019</p>

                                <div className="social-counters ">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaLinkedin size={24} style={{ cursor: "pointer" }} color='#0082ca' className='mx-2' href="www.linkedin.com/in/tg308" />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                                            >
                                                <FaTwitter size={24} style={{ cursor: "pointer" }} color='#55acee' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaFacebook size={24} style={{ cursor: "pointer" }} color='#3b5998' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.instagram.com/ibougeinc"
                                            >
                                            <FaInstagram size={24} style={{ cursor: "pointer" }} color='#DD2A7B' className='mx-3' />
                                            </a>
                                        </div>

                            </div>
                        </div>

                    </section>
                    
                    <section>
                        <div className="mt-5" style={{marginTop: '200px'}}>
                            <p>Lisa Leslie got hooked on technology at an early age. She grew up playing games on her family’s computer and took every opportunity to tinker around on the Web.  </p>
                            <p>"I've always been extremely curious about how the internet works," Lisa Leslie said.  </p>

                            <p>When Lisa went to college at the University of Washington, she knew she wanted to do something significant with her career. She decided to study international studies to gain a more global perspective and thought she might go into diplomacy, government or language translation for French. Lisa explained, "After a year abroad at a political science institute in Paris, my world view had expanded. All of my understandings were now interpreted in a global framework; culturally, socially, politically and economically." She came back to the US with a strong desire to bring people together across country boundaries. </p>
                            <p>After graduation from UW in 2007, Lisa moved to Dubai. "At the time it seemed like the most global place to be." She landed her first job as a product manager for an internet company delivering data on emerging markets. The company was working with heat maps and she was put in charge of features for their website. "After that role I fell in love with the dynamic nature of the internet and the power it had to bring people together and share information." She later moved to New York City and continued obsessing about the internet but what stuck with her most was the use of maps as a framework to organize data. "I started researching geo-location tools and considering how the internet could benefit from a location-based platform. After reading about information architecture and prototyping on her own, she came up with some concepts for geo-networking on a map-based interface.</p>
                            <p>When Lisa came across the Coding Bootcamp offered in partnership through the University of Washington, she knew this was a chance to show her skill set and rise to the occasion. The experience turned out to be more rewarding than she ever imagined. "I feel like I've finally opened the door to realizing my full potential and have a set of skills I can carry with me throughout my career and continue to expand upon. It has allowed me to understand the complexity of my work from a technical standpoint and utilize the technologies needed to build my platform. It's not as impossible to build as I once thought."</p>
                            <p>During the bootcamp Lisa was able to showcase some of her geo-networking ideas, building a networking app for travelers living abroad and for those who love to travel. </p>
                            <p>"I realized how hard it was to make friends and create community while living in Paris and Dubai, even though I saw a lot of people around me who would be interesting to meet or be friends with. One of my best friends is French and when I moved to Paris I didn't have an apartment or know anyone, but she helped me find a nice place to live for a year and made me feel like Paris was my home too. We built a friendship that will last a lifetime and I would love to make more friends like her, around the world."</p>
                            <p>Users on Lisa’s app can type in their destination city of interest and it matches them with local people. The app also has messaging and shows things to do in that city along with minute-by-minute weather updates. It's designed to connect users to local communities, on-the-go, anywhere in the world. </p>
                            <p>Lisa has been accepted into the Founder Institute, a Silicon Valley based startup accelerator that operates across 180+ cities helping young companies and founders get started. She was accepted last fall but decided to wait and finish the UW Bootcamp first. "The bootcamp will make me more effective in my business and allow me to contribute directly to the ambitious goals I've set for my company and myself." </p>
                            <p>Lisa is planning to do the Seattle chapter of the Founder Institute for their upcoming cohort, expected to begin this fall. "I can't wait to see how things come together." </p>
                        </div>
                    </section>
                </div>

                
                
                <div className='container' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>

                    <div className="p-5 text-center text-md-left author-box" >

                        <h4 className="h3-responsive text-center font-weight-bold dark-grey-text">About Founder</h4>
                        
                        <hr />

                        <div className="row">
                            <div className="col-12 col-md-2 col-xl-3 my-auto">
                                <img id="authorPhoto" src="img/BlogAuthorProfilePhoto.png" alt='authorPhoto' style={{ borderRadius: "50%", height: "270px", width: "250px" }} className='mx-auto' />
                            </div>

                            <div className="col-12 col-md-10 col-xl-9 text-lg-start text-md-start text-sm-center">
                                <h5 className="font-weight-bold text-dark mb-3 h5big">
                                    <strong>Lisa Rowlett Leslie</strong>
                                </h5>
                                <div className="personal-sm pb-3">
                               
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaLinkedin size={24} style={{ cursor: "pointer" }} color='#0082ca' className='mx-2' href="www.linkedin.com/in/tg308" />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                                            >
                                                <FaTwitter size={24} style={{ cursor: "pointer" }} color='#55acee' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaFacebook size={24} style={{ cursor: "pointer" }} color='#3b5998' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.instagram.com/ibougeinc"
                                            >
                                            <FaInstagram size={24} style={{ cursor: "pointer" }} color='#DD2A7B' className='mx-3' />
                                            </a>
                                    
                                </div>
                                <p id="authorBio">Lisa is an information architect and entrepreneur who loves to develop websites and travel the world.</p>
                                <p id="authorBio">Lisa holds a double major and a graduate certificate from the University of Washington and has lived in Paris, Dubai, NYC, SF, Seattle and Portland, Oregon.
                                </p>
                                <p id="authorBio">She is the founder and CEO of iBouge.com, the world's first geo-networking platform as well as the main contributor for the iBouge blog.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <footer className='pt-3 bg-white'>
                <p>iBouge ® • Copyright © 2024 iBouge Inc. • All Rights Reserved.</p>
                <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
            </footer>
        </>
    )
}

export default PressRelese2
