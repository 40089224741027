import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Protected = ({ Component }) => {
  const prams2 = window.location.href;
  const userData = useSelector((state) => state?.auth?.user?.user);
  const prams = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    const checkAndNavigate = () => {
      if (!userData || prams2.includes("token=")) {
        console.log("Navigating to login");
        navigate('/login');
        return;
      }

      if ((userData?.type === false && userData?.activation_status === 3) ||
          (userData?.type === true && userData?.subscription !== undefined)) {
        switch (prams) {
          case '/dashboard':
            navigate('/dashboard');
            break;
          case '/mapOverview':
            navigate('/mapOverview');
            break;
          case `/${userData.type?"business":"personal"}/${userData?._id}`:
            navigate(`/${userData.type?"business":"personal"}/${userData?._id}`);
            break;
          case '/admin':
            navigate('/admin');
            break;
          case '/setting':
            navigate('/setting', { state: { item: "1" } });
            break;
          default:
            if (userData?.activation_status === 3 && 
                (userData?.agreedToTAC === undefined || userData?.agreedToTAC === false)) {
              navigate('/thirdStep');
            } else if (userData?.type === true && userData?.subscription === undefined) {
              navigate('/fourthStep');
            } else if (userData?.is_deactivated) {
              navigate('/setting', { state: { item: "1" } });
            } else if (!prams2.includes("token=")) { 
              console.log("Navigating to home");
              navigate('/home', { 
                state: { 
                  item: [
                    userData.location.coordinates[0].coords.lng,
                    userData.location.coordinates[0].coords.lat,
                  ],
                },
              });
            }
            break;
        }
      }
    };

    // Only wait 2 seconds and navigate to login if "token=" is in the URL
    if (prams2.includes("token=")) {
      setTimeout(checkAndNavigate, 2000); // Wait 2 seconds if "token=" is present
    } else {
      checkAndNavigate(); // Run immediately if "token=" is not in the URL
    }
  }, [userData, prams2, prams, navigate]);

  return <Component />;
};

export default Protected;