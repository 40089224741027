import React, { useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import * as turf from "@turf/turf";
import { useSelector, useDispatch } from "react-redux";
import { DashCitySelect, UpdateCities } from "../../../redux/actions/auth/auth.actions";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;


function TopCities({ handleClose, CitiesData, setLatestCity }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [isActive, setIsActive] = useState(CitiesData.Selected);
  const [index, setIndex] = useState();
  const [NewCity, setNewCity] = useState();
  const [coordsNew, setCoordsNew] = useState(null);
  const mapContainerRef = useRef(null);
  const Loc = localStorage.getItem("location");
  const coords = Loc.split(",");

  useEffect(() => {
    CitiesData?.AllCities?.forEach((e) => {
      if (e[1] === CitiesData?.Selected) {
        setIndex(e[0]);
      }
    });
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken:mapboxgl.accessToken,
      style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
      center: [coords[0], coords[1]],
      zoom: 7.75,
    });
    // const marker = new mapboxgl.Marker().setLngLat(markerLngLat).addTo(map);
    var ctrl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    if (mapContainerRef?.current?.children[0]) {
      mapContainerRef.current.removeChild(mapContainerRef.current.children[0]);
    }
    document
      .getElementById("createMicroblogGeocoder")
      .appendChild(ctrl.onAdd(map));

    ctrl.on("result", (e) => {
      const place = e.result.place_name;
      const NewCityCoords = e.result.geometry.coordinates
      setNewCity(place);
      setCoordsNew(NewCityCoords)
    });
    map.on("load", function () {
      var center = turf.point([coords[0], coords[1]]);
      var radius = 25;
      var options = {
        steps: 80,
        units: "kilometers",
      };
      var circle = turf.circle(center, radius, options);
      map.addSource("circleCData", {
        type: "geojson",
        data: circle,
      });
      map.addLayer({
        id: "circle-fill",
        type: "fill",
        source: "circleCData",
        paint: {
          "fill-color": "#FEA115",
          "fill-opacity": 0.4,
        },
      });
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const PayloadCity = {
    email: "",
    cityname: "",
    index: "",
    lastcity: "",
  };
  const PayloadCityFunc = () => {
    PayloadCity.email = userData.email;
    PayloadCity.cityname = NewCity;
    PayloadCity.index = index;
    PayloadCity.lastcity = isActive;
  };
  const CityUpdate = () => {
    const cityNameNew =  NewCity.split(",")
    setLatestCity({
      city:cityNameNew[0],
      latLng:coordsNew 
    })
    PayloadCityFunc();
    dispatch(UpdateCities(PayloadCity));
    const newCity2 = NewCity.split(",")
    dispatch(DashCitySelect(newCity2[0]))
    localStorage.setItem("location",coordsNew);
    handleClose();
  };
  return (
    <>
      <div className="topCities-Main">
        <div className="cities-main border rounded-3 shadow mb-4">
          <div className="cities-header py-1 text-white d-flex align-items-center justify-content-between">
            <p className="fw-bold mx-3 my-2">Top Cities</p>

            <FiX
              className="fs-4 text-white me-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          <div className="cities-body ">
            <p className=" mx-5 pt-3 text-center d-flex align-items-center">
              Choose up to three Locations to follow for Updates Select a City
              to Update to new city
            </p>
            <div className="main-city mx-2 d-flex align-items-center justify-content-center">
              {CitiesData.AllCities.map((city, index) => {
                
                return (
                  <div  key={index}>
                    <div
                      className="city rounded-3 pt-2 mx-2 d-flex justify-content-center align-items-center position-relative"
                     
                      style={{
                        backgroundColor: isActive === city[1] ? "#FFEBC2" : "",
                        border: isActive === city[1] ? "1px solid #FEA115" : "",
                      }}
                      onClick={() => {
                        setIsActive(city[1]);
                        setIndex(index);
                      }}
                    >
                      <div className="text-center px-3">
                        <span className="align-middle">
                          {city[0] === 0
                            ? "City 1"
                            : city[0] === 1
                            ? "City 2"
                            : city[0] === 2
                            ? "City 3"
                            : ""}
                        </span>
                        <br />
                        <span className="fw-bold">
                          {city[1] ? city[1] : "Not selected yet"}
                        </span>
                      </div>
                    <div
                      className=""
                      style={{
                        borderTop:
                          isActive === city[1] ? "5px solid #FEA115" : "",
                        borderRadius:
                          isActive === city[1] ? "5px" : "",
                        paddingRight: isActive === city[1] ? "60px" : "",
                        position:isActive === city[1] ?"absolute":"",
                        left:isActive === city[1] ?"34px":"",
                        top:isActive === city[1] ?"0px":"",
                      }}
                    ></div>
                    </div>
                  </div>
                );
              })}
            </div>
            <p className="mb-0 mx-3 mt-2">Search for a city</p>
            <div id="createMicroblogGeocoder"></div>
            <div className="map-wrap-dash">
              <div className="map-dash ms-2" ref={mapContainerRef}></div>
            </div>
          </div>
          <div className="p-3 text-center ">
            <button
              className="btn save-btn fw-bold border shadow w-100 p-2 "
              type="button"
              data-bs-toggle="button"
              onClick={() => CityUpdate()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopCities;
