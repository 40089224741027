import React, { useState, useEffect } from "react";
import "./assets/styles/login.css";
import CardComponetSignup from "./Pages/auth/SignUp/cardcompSignup";
import CardComponetLogin from "./Pages/auth/login/cardcompLogin";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {  useLocation } from "react-router-dom";
import { LinkUser } from "./redux/actions/auth/auth.actions";

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const loading = useSelector((state) => state?.auth?.loading);
  const loginFlag = false;
  const [forgotFlag, setForgotFalg] = useState(false);
  const active_token = { token: location.search.replace("?token=", "") };
  useEffect(() => {
    if (active_token.token !== "") {
      dispatch(LinkUser(active_token))
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps


  // if (loading) {
  //   return (
  //     <>
  //        <div className="loader">
  //         <div className="d-flex justify-content-center align-items-center">
  //           {/* <img src="/img/loadergif.gif" alt="loadergif" /> */}
  //         </div>
  //       </div> 
  //     </>
  //   );
  // }
  return (
    <>
              {loginFlag === false ? (
                <CardComponetLogin
                  loginFlag={loginFlag}
                  setForgotFalg={setForgotFalg}
                  forgotFlag={forgotFlag}
                />
              ) : (
                <CardComponetSignup/>
              )}
    </>
  );
};
export default Login;
