import React, { useState, useRef } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import { CanvasPreview } from '../../components/CanvasPreview'
import { useDebounceEffect } from '../../components/useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from "react-redux";
import { CoverPic, ProfilePic, Server2 } from "../../redux/actions/auth/auth.actions";
import axios from "axios";
import './crop.css'
import { FiX } from "react-icons/fi";
function centerAspectCrop(width, height, aspect , type ) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width:type=="profile"? 40:100,
        height:type=="profile"?40:100
      },
      type=="profile"?1:29 / 10,
      width,
      height,
    ),
    width,
    height,
  )
}
export default function ProfileImage({ src, Full, handleClose , type, setImage , upload,InputPhoto }) {
  const userData = useSelector((state) => state?.auth?.user.user);
  const dispatch = useDispatch();
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [aspect, setAspect] = useState(1)


  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect , type))
    }
  }

  async function ApiProfilePic() {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    console.log(previewCanvas,"prevcanvas")
    console.log(image,"image")
    if (!image || !previewCanvas || !completedCrop) {
      console.log('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })
    const blobToFile = new File([blob], Full.name, {
      lastModified: new Date().getTime(),
      type: 'image/png',
    })
    if(type==="profile"){
      ApiPic(blobToFile)
    }else if(type ==="event"){
      InputPhoto(blobToFile)
      let reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(blobToFile);
      upload()
      handleClose()
    }else{
      ApiCover(blobToFile)
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        CanvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
        )
      }
    },
    100,
    [completedCrop, scale],
  )
  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined)
  //   } else {
  //     setAspect(16 / 9)

  //     if (imgRef.current) {
  //       const { width, height } = imgRef.current
  //       const newCrop = centerAspectCrop(width, height, 16 / 9)
  //       setCrop(newCrop)
  //       // Updates the preview
  //       setCompletedCrop(convertToPixelCrop(newCrop, width, height))
  //     }
  //   }
  // }
  const ApiPic = (e) => {
    let data = new FormData();
    data.append("userId", userData?._id);
    data.append("file",  e);
    data.append("albumName", "all-profile-pictures");
    data.append("userEmail", userData?.email);
    let config = {
      method: "post",
      url: Server2 + "/users/profilepic",
      data: data,
    };
    console.log(data, "profile Pic");

    axios(config)
      .then(function (response) {
        const newSet = {
          message: "User Logged in Successfully.",
          status: 200,
          user: response.data,
        };
        handleClose()
        dispatch(ProfilePic(newSet));
      })
      .catch(function (error) {
        console.log(error, "i am error");
      });
  };
  const ApiCover = (e) => {
    let data = new FormData();
    data.append("userId", userData?._id);
    data.append("file", e);
    data.append("albumName", "all-cover-pictures");
    data.append("userEmail", userData?.email);
    let config = {
      method: "post",
      url: Server2 + "/users/coverpic",
      data: data,
    };
    console.log(data, "dataCover");

    axios(config)
      .then(function (response) {
        const newSet = {
          message: "User Logged in Successfully.",
          status: 200,
          user: response.data,
        };
        handleClose()
        dispatch(CoverPic(newSet));
      })
      .catch(function (error) {
        console.log(error, "i am error");
      });
  };
  return (
    <div className="popup-box" style={{ overflow: "auto" }}>
      <div
        className="box"
        style={{
          overflow: "hidden",
          backgroundColor: "white",
          marginTop: "5%",
          padding: "10px 20px 20px 20px",
        }}
      >
        <div className="d-flex w-100 d-flex justify-content-end">
          <FiX
            className="fw-bolder text-dark mb-2"
            onClick={() => handleClose()}
            style={{
              cursor: "pointer",
              fontSize: "24px",
            }}
          >
            Close
          </FiX>
        </div>
        <div style={{height:"70vh",overflow: "auto", overflowX: "hidden" }}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            //  aspect={aspect}
            circularCrop={type==="profile"?true:false}
            // minWidth={400}
            minHeight={100}
            // circularCrop
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={src}
              style={{ transform: `scale(${scale})`, }}
              onLoad={onImageLoad}
            />
            {/* <img
              src={src}
              ref={imgRef}
              style={{ minWidth: "320px", maxWidth: "620px" }}
              onLoad={onImageLoad}
              alt="CropImage"
            /> */}
          </ReactCrop>
          <input
            id="ranger"
            type="range"
            min="1"
            max="10"
            value={scale}
            onChange={e => {
              const { value } = e.target;
              setScale(value);
            }}
          />
          
          {!!completedCrop && (         
            <canvas
              ref={previewCanvasRef}
              style={{
                display:"none",
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}

          <div
            style={{
              width: "90%",
              margin: "20px 5% 5px 5%",
              display: "flex",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <button
              className="bg-primary text-white rounded-3 border-0"
              style={{ fontSize: "14px", width: "45%", padding: "10px" }}
              onClick={() => {
                
                ApiProfilePic();
                
              }}
              >
              Use Photo
            </button>          
          </div>
        </div>
        <div></div>
        {/* <img src={output} /> */}
      </div>
    </div>
  );
}