import React, { useEffect, useState } from "react";
import { IoMdInformationCircle, IoMdLock } from "react-icons/io";
import {
  BsCashCoin,
  BsCheckCircle,
  BsFillCalendarFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import "../../../assets/styles/Settings/Settings.css";
import LocationMap from "./LocationMap";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  Server2,
  SetAccBusiness,
  SetAccPersonal,
} from "../../../redux/actions/auth/auth.actions";
import moment from "moment/moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const GeneralAcc = () => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // change the page on button click
  const [Generel, setGeneral] = useState("1");
  // Normal User Data
  const [fname, setFname] = useState(
    userData?.type === false ? userData?.fname : ""
  );
  const [lname, setLname] = useState(
    userData?.type === false ? userData?.lname : ""
  );
  const [Gender, setGender] = useState(
    userData?.type === false ? userData?.gender : ""
  );
  let Userdate = userData?.type === false ? userData?.dob ? userData?.dob.split("-") : "" : "";
  let NewDay = userData?.type === false ? Userdate[2] ? Userdate[2].split("T") : "" : "";
  const [Day, setDay] = useState(userData?.type === false ? NewDay[0] : "");
  const [Month, setMonth] = useState(
    userData?.type === false ? Userdate[1] : ""
  );
  const [Years, setYears] = useState(
    userData?.type === false ? Userdate[0] : ""
  );
  const [Pass, setPass] = useState("");
  // Bussiness User Data
  const [Category, setCategory] = useState(
    userData?.type === true ? userData?.business_category : ""
  );
  const [bName, setBName] = useState(
    userData?.type === true ? userData?.fname : ""
  );
  const subAmount = userData?.type === true ? userData?.subscription?.amount_paid : ""
  const subEndDate = userData?.type === true ? userData?.subscription?.end_date : ""
  const subStartDate=userData?.type === true ? userData?.subscription?.start_date : ""
  const [popup, setpopup] = useState(false)
 

  //DOB Create
  const monthData = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const [year, setYear] = useState([]);
  const yearsMake = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    setYear(range(currentYear, currentYear - 122, -1));
  };
  useEffect(() => {
    yearsMake();
  }, []);
  const businessTypes = [
    { id: 0, name: "Real Estate / Construction" },
    { id: 1, name: "Food / Beverage" },
    { id: 2, name: "Clothing / Apparel" },
    { id: 3, name: "Coaching / Consultation" },
    { id: 4, name: "Antiques" },
    { id: 5, name: "Art" },
    { id: 6, name: "Books" },
    { id: 7, name: "Electronics" },
    { id: 8, name: "Music" },
    { id: 9, name: "Home / Garden" },
    { id: 10, name: "Beauty / Personal Care / Wellness" },
    { id: 11, name: "Sports" },
    { id: 12, name: "Entertainment" },
    { id: 13, name: "Culture" },
    { id: 14, name: "Vehicles" },
    { id: 15, name: "Furniture" },
    { id: 16, name: "Fabric / Textiles" },
    { id: 17, name: "Medical / Dental" },
    { id: 18, name: "Health / Fitness" },
    { id: 19, name: "Tools / Hardware" },
    { id: 20, name: "Toys / Hobbies / Crafts" },
    { id: 21, name: "Luggage / Bags / Cases" },
    { id: 22, name: "Business Services" },
    { id: 23, name: "Industrial" },
    { id: 24, name: "Outdoors / Recreation" },
    { id: 25, name: "Hotels / Motels / Resorts" },
  ];

  // Api Starts
  // User Api
  const PayloadPersonal = {
    email: "",
    password: "",
    fname: "",
    lname: "",
    gender: "",
    dob: "",
  };
  const PayloadPersonalFunc = () => {
    PayloadPersonal.email = userData?.email;
    PayloadPersonal.password = Pass;
    PayloadPersonal.fname = fname;
    PayloadPersonal.lname = lname;
    PayloadPersonal.gender = Gender;
    PayloadPersonal.dob = `${Month}-${Day}-${Years}`;
  };
  const AccUsers = () => {
    PayloadPersonalFunc();
    dispatch(SetAccPersonal(PayloadPersonal)).then((res) => {
      if (res.data) {
        setpopup(true)
        setTimeout(() => {
          setpopup(false)
        }, 3000)
      }
    });

  };
  // API'S Business Starts
  const PayloadBussinss = {
    email: "",
    password: "",
    business_category: "",
    business_name: "",
  };
  const PayloadBussinssFunc = () => {
    PayloadBussinss.email = userData?.email;
    PayloadBussinss.password = Pass;
    PayloadBussinss.business_category = Category;
    PayloadBussinss.business_name = bName;
  };
  const AccBusiness = () => {
    PayloadBussinssFunc();
    dispatch(SetAccBusiness(PayloadBussinss)).then((res) => {
      console.log(res, "res,,")
      if (res.data) {
        setpopup(true)
        setTimeout(() => {
          setpopup(false)
        }, 3000)
      }
    });
  };
  const Cancle = () => {
    cancelSubscription();
  };
  const cancelSubscription = () => {
    var data = JSON.stringify({
      id: userData?._id,
    });
    var config = {
      method: "POST",
      url: Server2 + "/stripe/unSubscribePlan",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        console.log(response.data, "i am UnSub");
        if (response?.data) {
          dispatch(logout());
          navigate("/", { replace: true });
          localStorage.clear();
        }
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
 
  return (

    <> <div>
      <p className="acc-title text-center text-dark">Account</p>
    </div>
      <div className="main_btns mx-3 d-flex">
        {userData?.type === false ? (
          <>
            <button
              className="top-Btns"
              onClick={() => setGeneral("1")}
              style={
                Generel === "1"
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#ececec" }
              }
            >
              General
            </button>
            <button
              className="top-Btns"
              onClick={() => setGeneral("2")}
              style={
                Generel === "2"
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#ececec" }
              }
            >
              Location
            </button>
          </>
        ) : (
          <>
            <button
              className="top-Btns-B me-1"
              onClick={() => setGeneral("1")}
              style={
                Generel === "1"
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#ececec" }
              }
            >
              General
            </button>
            <button
              className="top-Btns-B me-1"
              onClick={() => setGeneral("2")}
              style={
                Generel === "2"
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#ececec" }
              }
            >
              Location
            </button>
            <button
              className="top-Btns-B"
              onClick={() => setGeneral("3")}
              style={
                Generel === "3"
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#ececec" }
              }
            >
              Payments
            </button>
          </>
        )}
      </div>

      {Generel === "1" ? (
        <div id="General" className="tabcontent">
          <div className="mx-3 pb-3 rounded-3 shadow">
            <div className="general-body ">
              {userData?.type === false ? (
                <>
                  <div className=" mx-3 ">
                    <span className="">First Name</span>
                    <div className=" mt-1 d-flex ">
                      <IoMdInformationCircle
                        className="info-icon ms-2 mt-2 fs-4 "
                        color="black"
                      />
                      <input
                        type="search"
                        placeholder="John"
                        className="form-setting form-control ps-5 p-2 "
                        aria-label="Search"
                        style={{
                          color: "black",
                        }}
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mx-3 d-flex justify-content-between align-items-center">
                    <div className="">
                      <span className="">Last Name</span>
                      <div className="d-flex mt-1 ">
                        <IoMdInformationCircle
                          className="info-icon ms-2 mt-2 fs-4"
                          color="black"
                        />
                        <input
                          type="search"
                          placeholder="Doe"
                          className="form-setting form-control ps-5 p-2"
                          aria-label="Search"
                          style={{
                            color: "black",
                          }}
                          value={lname}
                          onChange={(e) => setLname(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className=" w-50 ms-2 mt-2 ">
                      <span className="">Gender</span>
                      <select
                        className="form-select form-control genderGA"
                        name="gender"
                        style={{
                          color: "black",
                        }}
                        value={Gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option
                          value="Gender"
                          className="p-2 text-dark"
                          disabled
                          hidden
                        >
                          Gender
                        </option>
                        <option value="male" className="p-2 text-dark">
                          Male
                        </option>
                        <option value="female" className="p-2 text-dark">
                          Female
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3 mx-3 ">
                    <select
                      className="form-select form-control genderGA me-2 pe-5"
                      name="month"
                      style={{
                        color: "black",
                      }}
                      placeholder=""
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value="MM" disabled hidden>
                        MM
                      </option>
                      {monthData.map((item, key) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{
                        color: "black",
                      }}
                      className="form-select form-control genderGA me-1"
                      name="date"
                      value={Day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value="DD" disabled hidden>
                        DD
                      </option>
                      {days.map((item, index) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{
                        color: "black",
                      }}
                      className="form-select form-control genderGA"
                      name="year"
                      value={Years}
                      onChange={(e) => setYears(e.target.value)}
                    >
                      <option value="YY" disabled hidden>
                        YY
                      </option>
                      {year.map((item, index) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className=" mx-3 ">
                    <span className="">Email Address</span>
                    <div className="mt-1 d-flex">
                      <MdEmail
                        className="info-icon ms-2 mt-2 fs-4 "
                        color="black"
                      />
                      <input
                        className="form-setting ps-5 p-2 form-control "
                        style={{
                          color: "black",
                        }}
                        value={userData?.email}
                        disabled
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Bussiness Account */}
                  <div className="mx-3 pt-4 position-relative">
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#ECECEC", color: "black" }}
                      name="bsCategory"
                      value={Category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option
                        value="BsCategory"
                        className="text-danger"
                        disabled
                        hidden
                      >
                        Business Category
                      </option>
                      {businessTypes.map((items) => {
                        return (
                          <option value={items.name} key={items.id}>
                            {items.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mx-3">
                    <span className="">Business Name</span>
                    <div className="d-flex">
                      <IoMdInformationCircle
                        className="info-icon ms-2 mt-2 fs-5"
                        color="black"
                      />
                      <input
                        type="text"
                        placeholder="Business Name"
                        className="form-setting form-control ps-5 p-2"
                        style={{
                          color: "black",
                        }}
                        value={bName}
                        onChange={(e) => setBName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mx-3">
                    <span className="">Email Address</span>
                    <div className="d-flex">
                      <MdEmail
                        className="info-icon ms-2 mt-2 fs-5"
                        color="black"
                      />
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-setting form-control ps-5 p-2"
                        style={{
                          color: "black",
                        }}
                        value={userData?.email}
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}
              <div className=" mx-3 ">
                <span className="">Password</span>
                <div className="d-flex">
                  <IoMdLock
                    className="info-icon ms-2 mt-2 fs-4 "
                    color="black"
                  />
                  <input
                    type="Password"
                    placeholder="• • • • • • • •"
                    className="form-setting ps-5 p-2 text-dark form-control "
                    onChange={(e) => setPass(e.target.value)}
                  />
                  <BsFillEyeSlashFill
                    className="eye-icon mt-2 fs-4"
                    color="black"
                  />
                </div>
              </div>
            </div>
          </div>


          {popup === true ?
            <div style={{ backgroundColor: "#d2f8d2", margin: "10px 20px", padding: "6px 15%", borderRadius: "8px" }}>
              <BsCheckCircle style={{ marginRight: "15px" }} color="#0b4a0b" size={18} />
              <span style={{ fontSize: "13px" }}>Account has been updated</span>
            </div> : null
          }
          <div className="s-buttons d-flex pb-3 mx-3 mt-2">
            <button
              className="btn fw-bold text-white shadow w-50 me-2"
              type="button"
              data-bs-toggle="button"
              onClick={
                userData?.type === false ? () => AccUsers() : () => AccBusiness()
              }
            >
              Save
            </button>
            <button
              className="btn fw-bold text-white shadow w-50 ms-2"
              type="button"
              data-bs-toggle="button"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : Generel === "2" ? (
        <LocationMap />
      ) : (
        <div id="MainDiv">
          <div className="mx-3 mt-2 pb-3 rounded-3 shadow">
            <div className="mx-3 pt-2">
              <div className="">
                <span className="">Subscription Amount</span>
                <div className="d-flex">
                  <BsCashCoin
                    className="info-icon ms-2"
                    style={{ marginTop: "13px" }}
                    size={22}
                    color="black"
                  />
                  <input
                    type="text"
                    placeholder="Business Name"
                    className="form-setting form-control ps-5 p-2"
                    style={{
                      color: "black",
                    }}
                    disabled
                    value={`${subAmount.slice(0, 2)} USD`}
                  />
                </div>
              </div>
              <div className="">
                <span className="">Subscription Start Date</span>
                <div className="d-flex">
                  <BsFillCalendarFill
                    className="info-icon ms-2"
                    style={{ marginTop: "12px" }}
                    color="black"
                  />
                  <input
                    type="text"
                    placeholder="Business Name"
                    className="form-setting form-control ps-5 p-2"
                    style={{
                      color: "black",
                    }}
                    disabled
                    value={moment(subStartDate).format("MM/DD/YY HH:MM A")}
                  />
                </div>
              </div>
              <div className="">
                <span className="">Subscription End Date</span>
                <div className="d-flex">
                  <BsFillCalendarFill
                    className="info-icon ms-2"
                    style={{ marginTop: "12px" }}
                    color="black"
                  />
                  <input
                    type="text"
                    placeholder="Business Name"
                    className="form-setting form-control ps-5 p-2"
                    style={{
                      color: "black",
                    }}
                    disabled
                    value={moment(subEndDate).format("MM/DD/YY HH:MM A")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-3 mx-3 mt-3">
            <button
              style={{ backgroundColor: "rgb(254, 161, 21)" }}
              className="btn text-white shadow p-2 ms-2"
              type="button"
              onClick={() => Cancle()}
            >
              Cancel Subscription
            </button>
          </div>
        </div>
      )} </>


  );
};

export default GeneralAcc;
