import React, { useState } from "react";
import "../../assets/styles/Profile.css";
import "../../assets/styles/login.css";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  logout,
  secondStepPresonal,
} from "../../redux/actions/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import PresonalThirdStep from "./PersonalThirdStep";
import MapLoc, { LocMarker } from "../MapLoc";
const mapContainerStyle = {
  // maxWidth: "348px",
  // with: "100%",
  height: "320px",
  borderRadius: "8px",
  position: "relative"
};
function PresonalSecondStep({ map, mapContainerRef, setcoords, coords }) {
  const UserData = useSelector((state) => state?.auth?.user?.user);
  let navigate = useNavigate();
  const [second, setSecond] = useState("2");
  const dispatch = useDispatch();

  // Payload send to backend
  const payloadSecond = {
    activation_status: 2,
    email: "",
    coordinates: {},
  };
  const payloadSecondFunc = () => {
    payloadSecond.activation_status = 2;
    payloadSecond.email = UserData?.email;
    payloadSecond.coordinates = { coords };
  };
  const handleSubmitsecond = async () => {
      payloadSecondFunc();
      dispatch(secondStepPresonal(payloadSecond));
      setSecond();
      navigate("/thirdStep")
  };
  const handleClose = (e) => {
    dispatch(logout());
    navigate("/", { replace: true });
    localStorage.clear();
  };

  return (
    <>
      {second ? (
        <div className="full-content">
          <div className="text-center mt-5 mb-4 pt-4">
            <img src="/img/Logo.png" height={60} alt="ibouge" />
          </div>
          <div className="bg-white card-customize text-center ps-3 pe-3 ">
            <div className="border_top"></div>
            <h6 className="headingSteps">Setup 2/3</h6>
            <p className="setupHeading text-center">Setup your location</p>
            <p className="clickOnMap ">Add your location: Click on map</p>
            <p className="lasttext">
              Please enable location for your browser to tag you in your zip code.
              <br/> Please tag yourself somewhere in your neighborhood so 
              you can get the best community information and results
              <br/>  We need access to your device's location to provide
              tailored recommendations, relevant content, and improved functionality.
              Your location is only accessed while the app is open and in use
            </p>
            {/* map start */}
            <div className="map-wrap">
              <center>
                <div ref={mapContainerRef} style={mapContainerStyle} className="map" />
                {map && coords && (
                  <LocMarker
                    map={map}
                    coords={coords}
                    setcoords={(e) => setcoords(e)}
                  />
                )}
              </center>
            </div>

            <div>
              <button className="w-100 secondbtn" style={{opacity:coords?1:0.4}} onClick={
                () => {
                  if(coords){
                    console.log(coords)
                    handleSubmitsecond()
                  }
                }
              }>
                Continue
              </button>
            </div>

          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <AiOutlineClose
              size={30}
              className="aioutline"
              onClick={handleClose}
            />
          </div>
        </div>
      ) : (
        <PresonalThirdStep />
      )}
    </>
  );
}

export default MapLoc(PresonalSecondStep);