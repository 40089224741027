// import React, { useState } from "react";
// import useNavigator from "react-browser-navigator";
import FooterNav from "./FooterNav";
import "../../../assets/styles/Footer/contactUs.css"
import Tos from "./Tos";

const Terms = () => {
  // let { userAgent } = useNavigator();
  // const [, forceRender] = useState(undefined);
  // setTimeout(() => {
  //   const iframe = document.getElementById("myiframe");
  //   if (
  //     iframe &&
  //     iframe.contentDocument &&
  //     iframe.contentDocument.body &&
  //     iframe.contentDocument.body.innerHTML.trim() === ""
  //   ) {
  //     iframe.src = iframe.src; // Reload the iframe if content is not loaded
  //     // forceRender((prev) => !prev);
  //   }
  //   else{
  //     console.log("rendered")
  //     // forceRender((prev) => !prev);
  //   }
  // }, 360);
  // // const navigate = useNavigate()
  // // useEffect(() => {
  // //   const script = document.createElement("script");
  // //   script.src = "https://app.termly.io/embed-policy.min.js";
  // //   script.async = true;
  // //   document.body.appendChild(script);
  // // }, []);
  return (
    <div style={{ background: "white" }}>
      <FooterNav />
      <div className="MainContact">
        <div className="Contactheading position-relative">
          <p className="">Terms of Service</p>
        </div>
        <center>
          <div
            style={{
              position: "relative",
              top: "34px",
              width: "260px",
              height: "5px",
              backgroundColor: "#8A438B",
              borderRadius: "0px 0px 8px 8px",
            }}
          ></div>
        </center>
        <div className="d-flex justify-content-center">
          <div
            // name="termly-embed"
            // data-id="19552eb5-399c-4d26-9346-54a9a574b7e9"
            // data-type="iframe"
              className="bg-white px-sm-5 px-2"
              style={{ marginTop: "30px", borderRadius: "8px", height: 'fit-content', width: 'auto' }}
            >
            <Tos/>
          </div>

        </div>
      </div>
      {/* <center>
        <button
          style={{
            width: "315px",
            height: "48px",
            backgroundColor: "#6148A1",
            borderRadius: "8px",
            border: "none",
            color: "white",
            position: "relative",
            bottom: "50px",
          }}
          onClick={() => navigate('/contact')}
        >
          Contact Us
        </button>
        </center> */}
    </div>
  );
};

export default Terms;
