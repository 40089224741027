import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { BiChevronLeft } from "react-icons/bi";
import axios from "axios";
import { useSelector } from "react-redux";
import { Server2 } from "../../../redux/actions/auth/auth.actions";

const AddEventLoaction = ({
  isOpen , 
  setIsOpen,
  press,
  EventName,
  EventCategory,
  startDate,
  EventSTime,
  EventETime,
  EventDes,
  InputPhoto,
  Close,
  handleApi
}) => {
  const User = useSelector((state) => state?.auth?.user?.user)
  const mapContainer = useRef(null);
  const [error, setError] = useState("");
  const [address, setAddress] = useState("");
  const [searchCoords, setSearchCoords] = useState();
  const [pinnedCoords, setPinnedCoords] = useState({lat: null, lng: null});
  const [searchCountry, setSearchCountry] = useState();
  const [searchState, setSearchState] = useState();
  const [searchCity, setSearchCity] = useState();
  const map = useRef(null);
  const [lng] = useState(74.329376);
  const [lat] = useState(31.582045);
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/positron/style.json?key=RGierAHokphISswP6JTB`,
      center: [lng, lat],
      // zoom: zoom,
      minZoom:10,
      maxZoom:13,
    });
    map.current.scrollZoom.disable()
    map.current.dragPan.disable()
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    var geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    map.current.addControl(geolocate);
    map.current.on("load", function () {
      geolocate.trigger();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            setPinnedCoords({ lat, lng });
            setSearchCoords([ lng , lat ])
            console.log(position)
          },
          function (error) {}
        );
      }
    });

    const marker = new mapboxgl.Marker()
    .setLngLat([
      pinnedCoords.lng,
      pinnedCoords.lat
    ])
    .addTo(map.current);
    setSearchCoords({
      lng:pinnedCoords.lng,
      lat:pinnedCoords.lat
    })
    console.log(searchCoords);
    function onDragEnd(event) {
      setSearchCoords(null)
      const lngLat = event.lngLat;
      const lat = lngLat.lat
      const lng = lngLat.lng
      marker.setLngLat([lng , lat])
      setSearchCoords([ lng , lat ])
    }
    map.current.on('click', onDragEnd);

    var ctrl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      reverseGeocode: true,
    });
    if (mapContainer?.current?.children[0]) {
      mapContainer.current.removeChild(mapContainer.current.children[0]);
    }
    document
      .getElementById("createEventGeocoder")
      .appendChild(ctrl.onAdd(map.current));

    ctrl.on("result", (e) => {
      console.log(e)
      const place_name = e?.result?.place_name
      const split  = place_name.split(",",place_name.length-1)
      
      const coords = e.result.geometry.coordinates;
      setSearchCoords(coords);

      const addres =  e.result.properties.address
      setAddress(addres)

      const country = split[split.length-1]
      setSearchCountry(country)

      const state = split[split.length-2] 
      setSearchState(state)

      const city = split[split.length-3]
      setSearchCity(city)

      if(coords){
      const marker = new mapboxgl.Marker()
        .setLngLat([
          coords[0],
          coords[1],
        ])
        .addTo(map.current);
        setSearchCoords({
          lng:coords[0],
          lat:coords[1]
        })
        console.log(searchCoords);
        function onDragEnd(event) {
          setSearchCoords(null)
          const lngLat = event.lngLat;
          const lat = lngLat.lat
          const lng = lngLat.lng
          marker.setLngLat([lng , lat])
          setSearchCoords([ lng , lat ])
        }
        map.current.on('click', onDragEnd);
      }
    });
  });
  // API Integration
  const EventsAPI = () => {
    console.log(searchCoords[0], searchCoords[1], searchCoords.lat, searchCoords.lng)
    let data = new FormData();

    if(searchCoords[0] !== undefined){
      console.log("array")
      data.append("coordinates0", searchCoords[0]);
      data.append("coordinates1", searchCoords[1]);
    } else {
      console.log("object")
      data.append("coordinates0", searchCoords.lng);
      data.append("coordinates1", searchCoords.lat);
    }

    data.append("userId", User._id);
    data.append("createdBy", User._id);
    data.append("name", EventName);
    data.append("category", EventCategory);
    data.append("date", startDate);
    data.append("confirmationDate", startDate);
    data.append("startTimeOfEvent", EventSTime);
    data.append("endTimeOfEvent", EventETime);
    data.append("eventDescription", EventDes);
    data.append("platform", "web");
    data.append("file", InputPhoto);
    data.append("albumName", "events-image");
    data.append("address1",address);
    data.append("address2", address);
    data.append("city", searchCity);
    data.append("state", searchState);
    data.append("country", searchCountry);
    data.append("zip", '');
    let config = {
      method: 'post',
      url: Server2 + '/events/create',
      data : data
    };
    axios(config)
    .then(function (response) {
      handleApi()
    })
    .catch(function (error) {
      console.log(error,"i am error");
    });
  };
  const HandleCreateEvent = () => {
    if(searchCoords===""){
      setError("Please Select the Address")
    }else{
      EventsAPI()
      setError("")
      Close()
    }
  }

  return (
    <>
      <div className=" text-center">
        <h5 className="fw-bold mt-2 text-dark event-step">Step 2/2</h5>
        <h4 className="fw-bold fs-5 mt-2 text-dark ">Enter Location Details</h4>
        <p className="lasttext mx-3">
          Add event details: Click on map <br/>
          We need access to your device's location to provide tailored recommendations, relevant content, and improved functionality. Your location is only accessed while the app is open and in use
             
        </p>
      </div>
      {/* map start */}
      <div className="map-wrap-event">
        <div ref={mapContainer} className="map-event" />
      </div>
      <div>
        <span className="eventlabel">Search for an adderss</span>
        <div>
          <div id="createEventGeocoder"></div>
          {error!== null ? <h6 className="text-center text-danger mb-2">{error}</h6>:""}
          <div className="d-flex justify-content-around me-3 mb-3">
            <div className="position-relative">
              <BiChevronLeft className="backEventIcon" size={27} />
              <button
                className="evenntBtnBack"
                style={{ cursor: "pointer" }}
                onClick={press}
              >
                Back
              </button>
            </div>
            <button type="submit" className="eventCreateBtn" onClick={HandleCreateEvent}>
              <b>Create Event</b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEventLoaction;