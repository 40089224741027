import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Server2 } from "../../../redux/actions/auth/auth.actions";

function Friends() {
  const userData = useSelector((state) => state?.auth?.user?.user)
  const [UserFriends, setUserFriends] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    var config = {
      method: "get",
      url: Server2 + `/users/Friends?id=${userData?._id}`,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        if (response.data) {
          setUserFriends(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  // const GetUserData = async (e) => {
  //   return await fetch(
  //     Server2 + `/users/userprofile?id=${userData._id}&user=${e}`,
  //     {
  //       method: 'GET',
  //       headers: { 'Content-Type': 'application/json' },
  //     },
  //   )
  //     .then(async (response) => {
  //       const data = await response.json()
  //       return data.user

  //     })
  //     .catch(function (error) {
  //       console.log("error Get USer profile ", error);
  //     });
  // };
  const handleClick = (e) => {
    navigate(`/Profiless/${e?._id}`, {
      state: {
        item: e._id,
      },
    });

  }
  return (
    <>
      <div className="friends border rounded-3 shadow" style={{ height: "fit-content", marginTop: userData.type === false ? "1rem" : "2rem" }}>
        <div className="border1 mt-n1"></div>
        <div className="d-flex justify-content-between">
          <p className="friends mt-2 ms-4 text-dark">{userData?.fname}'s Connections ({UserFriends.length})</p>
          {/* <p className="me-3 mt-2">View All</p> */}
        </div>
        <div className="friends_row ps-3 my-2" style={{ height: "fit-content", marginBottom: "0px", paddingBottom: "0px" }}>
            {UserFriends.length === 0?
                          <p className=" fs-6 text-capitalize rounded text-center pt-3" style={{ backgroundColor: "#ececec", width: "25%", height: "70px", marginBottom: "20px" }}>No Connections Found</p>
            :UserFriends.map((friends, index) => {
              return (
                <div className=" col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 px-1 mx-2 text-center"
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => handleClick(friends)}
                >
                  <img className="img rounded-5"
                    src={friends.profile_pic ? friends.profile_pic : "/img/upload-photo.png"}
                    style={{ width: "40px", height: "40px" }}
                    alt="Admin"
                  />
                  {friends?.is_online === true ?
                    <img
                      src="/Images/contact-online.png"
                      className=""
                      style={{
                        position: "relative",
                        right: "11px"
                        , top: "17px"
                      }}
                      height={9}
                      alt="dot sign"
                    /> :
                    <img
                      src="/Images/contact-idle.png"
                      className=""
                      style={{
                        position: "relative",
                        right: "11px"
                        , top: "17px"
                      }}
                      height={9}
                      alt="dot sign"
                    />}

                  <p className="jacob"
                    style={{ wordBreak: friends.fname.length > 10 ? "break-word" : "normal" }}
                  >
                    {friends.fname} <br />
                    {friends.lname}
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
}

export default Friends;
