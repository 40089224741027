import SettingRoutes from "./routes";
import './App.css'

function App() {
  return (
    <>
      <SettingRoutes />
    </>
  );
}

export default App;
