import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { newpassword } from "../redux/actions/auth/auth.actions";
import { Button, Col, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { MdEmail } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const NewPasswordComp = () => {
  const resData = useSelector(
    (state) => state?.auth?.newPassState?.data);
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [errorMsg, setError] = useState("");
  console.log(errorMsg, "errorMsg")
  const [passwordShown, setPasswordShown] = useState(false);
  const active_token = { token: location.search.replace("?token=", "") };
  const validationSchema = Yup.object().shape({
    password: Yup.string("Please fill in the field Thank You").required(),
    email: Yup.string("Please fill in the field Thank You").email().required(),
  });
  const handleSubmit = (values) => {
    const resetData = {
      email: values.email,
      password: values.password,
      token: active_token.token,
    };
    if (location?.search !== null) {
      dispatch(newpassword(resetData)).then((res) => {
        console.log(res)
        if (res.state === "success") {
          navigate("/login")
          setError("")
        } else {
          setError("Enter valid Email")
        }
      });
    }
  };
  function clearError() {
    return (
      errorMsg?
    <div className="mt-4 error-text">{errorMsg}</div>:null
  )}
  function signUpPageChange() {
    navigate("/")
    setError("")
  }
  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Col lg="12" md="12" sm="12" xs="12">
              {errorMsg !== null ? clearError() : ""}
              <div
                className=""
                onClick={() => {
                  setError("");
                }}
              >
                <div className="position-relative mb-3 mt-5 px-4">
                  <label className="Label">Email Address</label>
                  <MdEmail className="MdEmail" color="black" size={20} />
                  <input
                    className="form-control input-btn email"
                    name="email"
                    value={values.email}
                    type="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="mt-4 position-relative px-4">
                <label className="Label">Password</label>
                <IoMdLock className="IoMdLock" color="black" size={20} />
                <input
                  className="form-control input-btn pass"
                  name="password"
                  value={values.password}
                  type={passwordShown ? "text" : "password"}
                  placeholder="• • • • • • • •"
                  onChange={handleChange}
                />
                {passwordShown === true ?
                  <AiFillEye onClick={() => setPasswordShown(false)} className="eyeInvisible" color="black" size={20} /> :
                  <AiFillEyeInvisible onClick={() => setPasswordShown(true)} className="eyeInvisible" color="black" size={20} />
                }
              </div>
              {(errors.email || errors.password) &&
                (touched.email || touched.password) ? (
                <div className="mt-4 text-align-center error-text">
                  Please fill in the field Thank You
                </div>
              ) : null}

            </Col>
            <div className="d-flex justify-content-end">
              <Label check className="RememberMe" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Input type="checkbox" id="checkbox2" className="checkbox" />
                Remember me
              </Label>
            </div>
            <Col lg="12" md="12" sm="12" xs="12" className="">
              <Button
                type="submit"
                className="submit-btn ib-btn w-100 mx-4"
                disabled={!(dirty && isValid)}
              >
                Create New Password
              </Button>
              <div className="d-flex justify-content-between align-items-center">
                <p className="dontHave">Already have an account?</p>
                <Button className="signup_button" onClick={signUpPageChange} ><b>Sign In</b></Button>
              </div>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewPasswordComp;