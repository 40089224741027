import React, { useState, useRef, useEffect, Fragment } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { CoverPic, ProfilePic, Server2 } from "../../redux/actions/auth/auth.actions";
import axios from "axios";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { BsCheckCircle, BsThreeDotsVertical } from "react-icons/bs";
import { FiX } from "react-icons/fi";
// import { saveAs } from 'file-saver'
import ActionSheet from "actionsheet-react";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TumblrIcon,
  TumblrShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { MdOutlineContentCopy } from "react-icons/md";
// import image2uri from "image2uri";
// import { imageDataURI } from 'image-data-uri'

export default function ProfileImage({ src, handleClose, type, user }) {

  const userData = useSelector((state) => state?.auth?.user.user);
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  // const [src, setSrc] = useState(source);
  // console.log(src)
  // const [srsChanged, setSrcChanged] = useState(false);
  
  // const [fetching, setFetching] = useState(false);
  const [popupCopied, setPopupCopied] = useState(false);
  const [popupShare, setPopupShare] = useState(false);

  const refActionSheet = useRef();

  useEffect(() => {
    const handleEsc = (event) => {
       if (event.key === 'Escape') {
        handleClose()
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const deletePicture = () => {
    var config;
    if(type === "profile")
      config = {
        method: "GET",
        url: Server2 + `users/delete-profile-pic?id=${userData._id}`,
      };
    else
      config = {
        method: "GET",
        url: Server2 + `users/delete-cover-pic?id=${userData._id}`,
      };
      
    axios(config)
      .then((response) => {
        if (response.data) {
          // setSrc('/Images/dummy2.png')
          // setSrcChanged(true)
          console.log("Done Deleted")
        }
      })
      .catch(function (error) {
        console.log("error deleting Image ", error);
      });
  };

  const handleSetImage = () => {
    let data = {
      id: userData._id,
      type: type === "profile" ? "Cover":"profile",
      url: src
    };
    let config = {
      method: "post",
      url: Server2 + "/users/update-picture-url",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config)
    .then((response) => {
      const newSet = {
        message: "User Logged in Successfully.",
        status: 200,
        user: response.data,
      };
      if(type == "profile")
        dispatch(ProfilePic(newSet));
      if(type == "Cover")
        dispatch(CoverPic(newSet));
      // setSrcChanged(true)
      // set(response.data);
    })
      .catch((err) => {
        console.log(err, "error");
      });
  }

  const downloadFile = async () => {
    
    // setFetching(true);
    // fetch(userData.profile_pic)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     setFetching(false);
    //     const blobURL = URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = blobURL;
    //     a.style = "display: none";
    //     a.download = "image.jpeg";
    //     document.body.appendChild(a);
    //     a.click();
    //   })

    // function toDataURL(url) {
    //   return fetch(url)
    //     .then((response) => {
    //       return response.blob();
    //     })
    //     .then((blob) => {
    //       return URL.createObjectURL(blob);
    //     });
    // }
    // const a = document.createElement("a");
    // a.style.display = "none";
    // // a.href = await toDataURL(userData.profile_pic);
    // a.href = userData.profile_pic;
    // a.download = "image.jpeg"
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // const uri = await image2uri(userData.profile_pic, { ext: '.jpeg' });
    // const link = document.createElement("a");
    // link.href = uri;
    // link.download = `IMAGE.jpeg`;
    // document.body.appendChild(link);
    // link.click();
  }

  const downloadImage = () => {
    // saveAs(userData.profile_pic, 'image.jpeg') // Put your image URL here.
    let config = {
      method: 'get',
      url: Server2 + `/users/proxy-image?url=${src}`,
      responseType: 'blob'  // Ensure the response is treated as a blob (binary data)
    };
    
    axios(config).then(response => {

      const blob = response.data
      // Create a local URL for the image blob
      const imageUrl = URL.createObjectURL(blob);
      console.log(imageUrl);
      const a = document.createElement("a");
      a.href = imageUrl;
      a.style = "display: none";
      a.download = "image.jpeg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    })
    .catch(error => {
      console.error('Error fetching the image:', error);
    });
  }

  return (    
    <div className="popup-box" style={{ overflow: "auto" }}>
      <center>
        {popupCopied === true ?
          <div className="position-absolute d-flex justify-content-center w-100">
            <div style={{ backgroundColor: "#d2f8d2", margin: "35px 20px", padding: "6px 15%", borderRadius: "8px" }}>
              <BsCheckCircle style={{ marginRight: "15px" }} color="#0b4a0b" size={18} />
              <span style={{ fontSize: "13px" }}>URL added to your clipboard</span>
            </div>
          </div>
        : null
        }
        <div
          style={{
            maxWidth: "100%",
            overflow: "auto",
            backgroundColor: "black",
            height: "100vh",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
          }}
        >
          <div className="d-flex justify-content-center flex-column popup-imageviewer w-100 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <FiX
                className="text-white"
                onClick={() => {
                  handleClose(false)
                }}
                style={{  
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              />
              <Dropdown className="" style={{
                // position: 'absolute',
                // right: '100px'
              }}>
                <Dropdown.Toggle
                  className="rounded-3 border-0"
                  id="dropdown-basic"
                  aria-expanded="false"
                  style={{
                    backgroundColor: 'transparent',
                    // border: "0.5px solid grey",
                    fontSize: "24px",
                    // width: "100%",
                    // padding: "10px",
                  }}
                >
                  <BsThreeDotsVertical/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100 text-center">
                  
                {user === userData._id?
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      deletePicture();
                    }}
                    style={{ fontSize: 13 }}
                  >
                    Delete Picture
                  </Dropdown.Item>
                : null }

                  <Dropdown.Item
                    eventKey="2"
                    onClick={() => {
                      downloadImage()
                    }}
                    style={{ fontSize: 13 }}
                  >
                    Save Picture
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="3"
                    onClick={() => {
                      // setPopupShare(true)
                      refActionSheet.current.open();
                    }}
                    style={{ fontSize: 13 }}
                  >
                    Share Picture
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="4"
                    onClick={() => {
                      handleSetImage(user?._id)
                      // refActionSheet.current.close();
                    }}
                    style={{ fontSize: 13 }}
                  >
                    Make {type === "profile"? "Cover" : "Profile"} Picture
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>

            </div>
            {/* <b
              onClick={()=> {downloadFile()}}
              className="text-white">Download
            </b> */}
            <div className="w-100 d-flex align-items-center justify-content-center" style={{height: "90vh", objectFit: "contain" }}>
              <img
                src={src}
                ref={imgRef}
                className="img-fluid mt-n5"
                style = { type === 'profile' ?
                  { minWidth: "220px", borderRadius: "20px", objectFit: "contain" }
                  :
                  { borderRadius: "20px", objectFit: "contain"}
                }
                alt="User`s Cover Pic"
              />
            </div>
          </div>
        </div>
      </center>
      {popupShare === true ? 
      <Modal
          show={popupShare}
          onHide={() => setPopupShare(false)}
          backdrop="static"
          keyboard={false}
          centered
          style={{width:"100%", height:"100vh"}}
        >
          <Modal.Header>
            <Modal.Title style={{color:"black", fontSize:"20px"}}>Share to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3" style={{maxWidth: "460px", overflowY: "scroll"}}>
              <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                <FacebookShareButton
                  url={src}
                  quote={'From iBouge'}
                  hashtag={'#iBouge...'}
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                Facebook
              </div>
              <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                <LineShareButton
                  url={src}
                  title={'From iBouge'}
                >
                  <LineIcon size={40} round={true} />
                </LineShareButton>
                Line
              </div>
              <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                <LinkedinShareButton
                  url={src}
                  title={'From iBouge'}
                  hashtag={'#iBouge...'}
                >
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                LinkedIn
              </div>
              <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                <TumblrShareButton
                  url={src}
                  title={'From iBouge'}
                  hashtag={'#iBouge...'}
                >
                  <TumblrIcon size={40} round={true} />
                </TumblrShareButton>
                Tumblr
              </div>
              <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                <WhatsappShareButton
                  url={src}
                  quote={'From iBouge'}
                  hashtag={'#iBouge...'}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
                Whatsapp
              </div>
              <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px", cursor: "pointer"}}>
                <div className="bg-secondary rounded-5">
                  <MdOutlineContentCopy className="text-white p-2" size={40}
                    onClick={()=>{
                      navigator.clipboard.writeText(src)
                      setPopupCopied(true)
                      setPopupShare(false)
                      setTimeout(() => {
                        setPopupCopied(false)
                      }, 3000);
                    }}
                  />
                </div>
                Copy URL
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="secondary" onClick={() => setPopupShare(false)}>
              Close
            </Button>
          </Modal.Footer>
      </Modal> 
      : null }
      
      <Fragment>
        <ActionSheet ref={refActionSheet} className="d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center w-100">
              <div className="d-flex gap-3 my-5 px-3 shareButtons-div">
                <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                  <FacebookShareButton
                    url={src}
                    quote={'From iBouge'}
                    hashtag={'#iBouge...'}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  Facebook
                </div>
                <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                  <LineShareButton
                    url={src}
                    title={'From iBouge'}
                  >
                    <LineIcon size={40} round={true} />
                  </LineShareButton>
                  Line
                </div>
                <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                  <LinkedinShareButton
                    url={src}
                    title={'From iBouge'}
                    hashtag={'#iBouge...'}
                  >
                    <LinkedinIcon size={40} round={true} />
                  </LinkedinShareButton>
                  LinkedIn
                </div>
                <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                  <TumblrShareButton
                    url={src}
                    title={'From iBouge'}
                    hashtag={'#iBouge...'}
                  >
                    <TumblrIcon size={40} round={true} />
                  </TumblrShareButton>
                  Tumblr
                </div>
                <div className="d-flex flex-column align-items-center gap-1" style={{minWidth: "55px"}}>
                  <WhatsappShareButton
                    url={src}
                    quote={'From iBouge'}
                    hashtag={'#iBouge...'}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  Whatsapp
                </div>
                <div className="d-flex flex-column text-center align-items-center gap-1" style={{minWidth: "55px", cursor: "pointer"}}>
                  <div className="bg-secondary rounded-5">
                    <MdOutlineContentCopy className="text-white p-2" size={40}
                      onClick={()=>{
                        navigator.clipboard.writeText(src)
                        setPopupCopied(true)
                        setPopupShare(false)
                        setTimeout(() => {
                          setPopupCopied(false)
                        }, 3000);
                      }}
                    />
                  </div>
                  Copy URL
                </div>
              </div>
              <Button variant="secondary" className="w-100 rounded-0" onClick={() => refActionSheet.current.close()}>
                Close
              </Button>
            </div>
        </ActionSheet>
      </Fragment>
    </div>
  );
}