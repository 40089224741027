import React, { useEffect, useState } from "react";
import "../../assets/styles/Profile.css";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FourthStepAction,  logout,  Server2,  DashCitySelect } from "../../redux/actions/auth/auth.actions";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe,  useElements,  PaymentElement,  Elements } from "@stripe/react-stripe-js";

function CheckoutForm({ clientSecret  , id , email , setMessage , setError}) {
  const created = new Date()
  const Expiry = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://www.ibouge.com",
      },
    });
    if (error) {
      setMessage(error.message)
      console.log("[error]", error);
    } else {
      if (!stripe) {
        return;
      }
      if (!clientSecret) {
        return;
      }
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        document.documentElement.scrollTop = 0;
        switch (paymentIntent.status) {
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "succeeded":
            setMessage("Payment succeeded!");
            dispatch(FourthStepAction({
              id:id,
              email:email,
              IntentId:paymentIntent.id,
              amount:paymentIntent.amount,
              createdDate:created,
              expiryDate:Expiry
            }))
              .then((res) => {
                const newCity = res?.data?.user?.location?.extraCityToFollow0?.cityName.split(",");
                dispatch(DashCitySelect(newCity[0]))
                if (res?.data?.user?.subscription) {
                  navigate("/thankyoumodel");
                } else {
                  setError(res?.data.message);
                }
              })
              .catch((error) => {
                console.log(error, "error");
              });
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }
    // console.log(setAgreedTAC);
  };
  return (
    <>
    
    <form onSubmit={handleSubmit}>
      <div style={{ margin: "5px 20px" }}>
        <PaymentElement />
      </div>
      <div className="d-flex justify-content-center w-100">
        <button type="submit" className= "submit-btn2 w-100 mx-2">
        Activate my iBouge Business Page
        </button>
      </div>
    </form>
    </>
  );
}
function FourthStep() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);
  const userData = useSelector((state) => state?.auth?.user?.user);
  // set all data of Get api in state
  const [planName, setPlanName] = useState();
  const [planType, setPlanType] = useState();
  const [planPrice, setPlanPrice] = useState();
  // const [planId, setPlanID] = useState();
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState(null);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    const data = {
      email:userData?.email,
      fname:userData?.fname
    }
    var config = {
      method: "POST",
      url: Server2 + "/stripe/getSubscriptionPlan",
      headers: { "Content-Type": "application/json" },
      data:data
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data, "response?.data?.data");
        setPlanName(response?.data?.data?.plan_name);
        setPlanType(response?.data?.data?.plan_interval);
        setPlanPrice(response?.data?.data?.plan_price);
        // setPlanID(response?.data?.data?.plan_id);
        setClientSecret(response?.data?.data?.clientSecret);
      })
      .catch(function (error) {
        setError(error)
        console.log(error, "i am error Fourth");
      });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const handleClose = (e) => {
    dispatch(logout());
    localStorage.clear();
    navigate("/", { replace: true });
  };
  const appearance = {
    theme: "flat",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <div className="full-content">
        <div className="text-center mt-5 mb-4 pt-4">
          <img src="/img/Logo.png" height={60} alt="ibouge" />
        </div>{" "}
        <div className="bg-white card-customize ">
          <div className="border_top"></div>
          <h6 className="headingSteps text-center">Step 4/4</h6>
          <p className="setupHeading text-center">Activate Membership</p>
          { message ? (
            <div
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                margin: "2px 28px 10px 28px",
                textAlign: "center",
                color: message=== "Payment succeeded!"?"#198754":"red"
              }}
            >
              {message}
            </div>
          ) : ( null )}
          {error ? (
            <div
              className="text-danger"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                margin: "2px 28px 10px 28px",
                textAlign: "center",
              }}
            >
              {error}
            </div>
          ) : (
            ""
          )}
          <div className="position-relative">
            <label className="thirdlabel">Plan Name</label>
            {/* <BiChevronDown size={36} className="aicloseinput" /> */}
            <div className="d-flex justify-content-center">
              <select
                className="form-select form-select-sm AccountType mx-2"
                name="Plan Name"
                defaultValue={"PlanName"}
              >
                <option
                  value="PlanName"
                  className="p-2 text-dark"
                  disabled
                  hidden
                >
                  {planName}
                </option>
                <option className="p-2 text-dark">{planName}</option>
              </select>

            </div>
          </div>
          <div className="position-relative">
            <label className="thirdlabel">Plan Type</label>
            {/* <BiChevronDown size={36} className="aicloseinput" /> */}
            <div className="d-flex justify-content-center w-100">
              <select
                className="form-select form-select-sm AccountType mx-2"
                name="Plan Name"
                defaultValue={"PlanType"}
              >
                <option
                  value="PlanType"
                  className="p-2 text-dark"
                  disabled
                  hidden
                >
                  {planType === "year" ? " Annual" : ""}
                </option>
                <option className="p-2 text-dark">
                  {planType === "year" ? " Annual" : ""}
                </option>
              </select>
            </div>
          </div>
          <div className="position-relative">
            <label className="thirdlabel">Plan Price</label>
            {/* <BiChevronDown size={36} className="aicloseinput" /> */}
            <div className="d-flex justify-content-center w-100">
              <select
                className="form-select form-select-sm AccountType mx-2"
                name="Membership"
                defaultValue={"Membership"}
              >
                <option
                  value="Membership"
                  className="p-2 text-dark"
                  disabled
                  hidden
                >
                  Business Membership ${planPrice} USD / Annually
                </option>
                <option className="p-2 text-dark">
                  Business Membership ${planPrice} USD / Annually
                </option>
              </select>
            </div>
          </div>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm 
                clientSecret={clientSecret}
                id={userData?._id}
                email={userData?.email}
                setError={(e) => setError(e)}
                setMessage = {(e) => setMessage(e)}
              />
            </Elements>
          )}
        </div>
        <div className="bg-danger">
          <AiOutlineClose
            size={30}
            className="aioutline"
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default FourthStep;