import React from "react"
import Map from "./Map"

const Layout = ({setNavMaps , NavMaps , UpRef}) => {
  return (
    <>
      <Map setNavMaps={(e) =>setNavMaps(e)} NavMaps = {NavMaps} UpRef={UpRef} />
    </>
  )
}

export default Layout