import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Server2 } from '../../redux/actions/auth/auth.actions';
import ShareProfile from './ShareProfile'

const Main = () => {
    let params = useParams();
    let newId = params?.id.split("=")
    const [usersData , setUserData] = useState() 
    useEffect(() => {
        data(newId[1])
        },[]) // eslint-disable-line react-hooks/exhaustive-deps
        const data = (e) => {
            var config = {
                method: "get",
                url: Server2 + `/users/shareprofile?user=${e}`,
              };
              axios(config)
              .then(function (response) {
                setUserData(response.data)  
                })
                .catch(function (error) {
                  console.log(error);
                });
        }
  return (
    <div>
    {usersData?<ShareProfile userData={usersData} />:""
    }
    </div>
  )
}

export default Main
