import React from "react";
import { Card} from "reactstrap";
import NewPasswordComp from "../../../components/newpasswordComp";
const NewPassword = () => {
  return (
    <>
     
      <div className="text-center img-logo">
        <img src="/img/Logo.png" alt="" className="FormLogo-Signup" />
        <span style={{color: "#9f47a0", position: "relative", top: "15px", fontSize: "1.2rem"}}>&#174;</span>
      </div>
      <Card className="card-customize">
        <div className="border_top"></div>
        <div className="mt-4 text-center">
          <span className="LoginHeading">Please enter your new password</span>
        </div>
        <NewPasswordComp/>
      </Card>
    </>
  );
};

export default NewPassword;
