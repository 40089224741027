import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function Events({ isopen, cityname , Eventlist,EventLoading }) {
  let navigate = useNavigate();
  const handleEvent = (items) => {
    navigate("/Event", {
      state: {
        item: items,
      },
    });
  };
  return (
    <>
      <div className="events shadow mt-2 ">
        <div className="events-header p-2 fw-bold d-flex justify-content-between align-items-center">
          <div>
            <img className="ms-2" src={"/Images/Vector.png"} alt="Icon" />
            <span className="event-name ms-3">Local Events</span>
          </div>
          <div
            className=" add-eb d-flex"
            onClick={() => {
              isopen();
            }}
          >
            <h6 className="add">Add New</h6>
            <BsPlusLg className=" ms-2 plus-btn" />
          </div>
        </div>
        <div className=" events-body pe-3 py-3">
          <div className="events-scrol px-3 mb-2">
            {EventLoading === true? <div style={{display:"flex", justifyContent:"center",margin:"5%"}}><div className="loading2"></div></div> :
              Eventlist.map((items , index) => {
              let date = new Date(items.dateOfEvent).toString().split(" ");
              return (
                <div
                  className="border eventsHover rounded-3 border-dark mt-2 d-flex justify-content-between"
                  key={index}
                  onClick={() => handleEvent(items)}
                  style={{ cursor: "pointer" }}
                >
                  <>
                    <div className=" p-2 pb-0 d-flex">
                      <img
                        className="img-fluid box-img"
                        src={
                          items.eventImage === null
                            ? "/Images/Event3.png"
                            : items.eventImage
                        }
                        alt="Icon"
                      />
                      <div className="ps-3 py-0 ">
                        <span className="box-span1 fw-bold">{items.name}</span>
                        <br />
                        <span className="box-span2 text-nowrap ">
                          {`${date[0]}` +
                            " " +
                            `${date[1]}` +
                            " " +
                            `${date[2]}`+
                            " " +
                            `${date[3]}`}
                        </span>
                      </div>
                    </div>
                    <button className=" btn send-eb px-2  " type="button">
                      <BiChevronRight className="arrow-send " />
                    </button>
                  </>
                </div>
              );
            })}
            {/* ^ Events Box6 */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
