import React, { useEffect, useState } from "react";
import { BiCalendar } from "react-icons/bi";
import {IoMdShare} from 'react-icons/io'
import { Card } from "reactstrap";
import "../../../assets/styles/Footer/pressRelese.css";
import "../../../assets/styles/Footer/contactUs.css";
import "../../../assets/styles/Footer/footerAbout.css";
import FooterNav from "./FooterNav";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoMdLink } from "react-icons/io";
import { Helmet } from "react-helmet";
import FootNav from "../../User_Profile/Footer/Footer-nav/FootNav";

const Press = () => {
  const navigate = useNavigate() 
   const monthData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [year, setYear] = useState([]);
  const yearsMake = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    setYear(range(currentYear, currentYear - 122, -1));
  };
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    yearsMake();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [Url, setURL] = useState("");
  
  return (
    <>
    <div style={{background:"white"}}>
    <Helmet>
        <meta property="og:url" content="press" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="iBouge Press" />
        <meta property="og:description" content="iBouge, a geo-network. We use maps to connect the global community" />
      </Helmet>
      <FooterNav from={"Press"} />
      <div className="MainContact" >
        <div className="Contactheading">
          <p>Press</p>
        </div>

        <div className="press-card-main mx-1 mt-2">
          <Card className="press-card-customize">
            <div className="press-border_top"></div>
            <div className="mt-3 text-center">
              <span className="press-LoginHeading">Press Statement</span>
            </div>
            <div className="d-flex justify-content-center mt-3 position-relative">
              <center>
                <div className="row">
                  <div className="col-12 col-md-4 position-relative">
                    <BiCalendar size={22} className="press-Bicalender" />
                    {/* <BiChevronDown size={34} className="press-BiChevronDownM" /> */}
                    <select
                      className="form-select form-select-sm text-dark press-dateSelect"
                      name="month"
                    >
                      <option disabled selected hidden>
                        MM
                      </option>
                      {monthData.map((item) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 position-relative">
                    <BiCalendar size={22} className="press-Bicalender" />
                    <select
                      className="form-select form-select-sm ms-2 text-dark press-dateSelect"
                      name="year"
                    >
                      <option disabled selected hidden>
                        YYYY
                      </option>
                      {year.map((item, index) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-md-4" >
                    <button className="press-Search-btn"> Search Article</button>
                  </div>

                </div>
              </center>
            </div>

            {/* presss card start */}

            <div className="p-3 mt-5">
              <div className="row d-flex justify-content-around">
                <div className="col-lg-6 col-12 press-card-size d-flex flex-column justify-content-center mx-2 px-0 py-3">
                  <img
                    className="img-fluid"
                    style={{width:"50%",margin:"auto", borderRadius:"4px"}}
                    src="/img/LisaLeslie Proofs-042.jpg"
                    alt="Lisa Leslie"
                  />
                  <div className="d-flex mt-2">
                  <p className="press-card-title">
                    No Boundaries: How Lisa Leslie Is Making the World a Little
                    Smaller
                  </p>
                  <div className="d-flex mt-2">
                    <IoMdShare className="press-icon-share me-2" size={26} color="white" onClick={() => {
                      setIsOpen(true)
                      setURL("https://www.ibouge.com/pressBlog2")
                    }} />
                    {/* <BiLike className="press-icon-Like" size={26} color="white" /> */}
                  </div>
                  </div>
                  <p className="lastupdate">Last updated 7/05/2019</p>
                  <p className="press-card-text">
                    Lisa Leslie got hooked on technology at an early age. She grew
                    up playing games on her family’s computer and took every
                    opportunity to tinker around on the Web.
                  </p>
                  <button className="press-read-btn text-white" onClick={() => navigate('/blogLandingPage.html')}>
                    
                      Read Article
                  
                  </button>
                </div>

                {/* 2nd Card Start */}
                <div className="col-lg-6 col-12 press-card-size d-flex flex-column justify-content-center mx-2 px-0 p-3">
                  <img
                    className="img-fluid"
                    style={{width:"100%" , height:"250px", objectFit:"contain" , borderRadius:"4px"}}
                    src="/img/pressArticle2.png"
                    alt="Card cap"
                  />
                  <div className="d-flex mt-2">
                  <p className="press-card-title">
                    iBouge Launches Global Geo-Networking Platform for Businesses
                  </p>
                  <div className="d-flex mt-2">
                    <IoMdShare className="press-icon-share me-2" size={26} color="white" onClick={() => {
                      setIsOpen(true)
                      setURL("https://www.ibouge.com/pressBlog")
                    }}/>
                    {/* <BiLike className="press-icon-Like" size={26} color="white" /> */}
                  </div>
                  </div>
                  <p className="lastupdate">Last updated 7/05/2019</p>
                  <p className="press-card-text">
                    iBouge now offers business pages to companies everywhere.
                    Businesses can register on the mapping platform and instantly
                    reach a global audience.
                  </p>
                  <button className="press-read-btn text-white" onClick={() => navigate('/pressBlog')}>
                    Read Article
                  </button>
                </div>
              </div>
            </div>
            {isOpen && Url ?
                <SharePress  handleClose={() => setIsOpen(!isOpen)} Url={Url} seturl={() => setURL("")} />
                :null
            }
          </Card>
        </div>
      </div>
          <footer className="px-3">
          <p>iBouge ® • Copyright © 2024 iBouge Inc. • All Rights Reserved.</p>
          <p>Patent numbers: US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
        </footer>
        <FootNav from={"Press"} />
    </div>
    </>
  );
};

export default Press;

export const SharePress = ({handleClose,Url , seturl}) =>{
  const [copyText, setCopyText] = useState(false);
  return(
    <>
      <div className="popup-box">
      <div
        className="box"
        style={{
          marginTop: "calc(100vh - 67vh - 20px)",
          padding: "40px 20px",
        }}>
              <h5 className="ms-4 ps-1 my-3">Share Press</h5>
              <div>
                <input
                  name="text"
                  value={Url}
                  disabled
                  className="form-control"
                  style={{ width: "90%", height: "45px", margin: "10px 5%" }}
                />
              </div>
              {/* <div>{Url.length> 10? `${Url.substring(0, 35)}...` :Url}</div> */}
              <div
                className=""
                style={{
                  width: "90%",
                  margin: "25px 5%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CopyToClipboard text={Url}>
                  <button
                    onClick={() => setCopyText(true)}
                    className={
                      copyText === true
                        ? "bs-gray-400 text-primary rounded-3"
                        : "bg-white text-primary rounded-3"
                    }
                    style={{
                      border: "0.5px solid grey",
                      fontSize: "14px",
                      width: "23%",
                      padding: "10px",
                    }}
                  >
                    <IoMdLink size={20} />{" "}
                    {copyText === true ? "Copied" : "Copy Link"}
                  </button>
                </CopyToClipboard>
                <button
                  className="bg-primary text-white rounded-3 border-0"
                  style={{ fontSize: "14px", width: "23%", padding: "10px" }}
                  onClick={() => {
                    handleClose()
                    seturl()
                  }}
                >
                  Done
                </button>
              </div>
            
        </div>
        </div>
    </>
  )
}