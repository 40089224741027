import { useState, useEffect } from 'react';
import { Server2 } from '../redux/actions/auth/auth.actions';
import "../assets/styles/style.css"

function LinkPreview({url , color}) {
    console.log(url)
  const [previewData, setPreviewData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Server2+`/users/Link?url=${url}`);
        const data = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        const title = doc.querySelector('title')?.textContent || '';
        const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || '';
        const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

        setPreviewData({ title, description, image });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return  <div className="c-item mt-3">
    <div className="c-item__center">
      <h3>
        <div className="c-skeleton-line" />
        <div className="c-skeleton-line" />
      </h3>
    <div className="c-item__top">
      <div className="c-skeleton-square" />
    </div>
    </div>
  </div>;
  }

  if (!previewData) {
    return <p>Failed to fetch link preview.</p>;
  }

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer',width:"100%",marginTop:10 }}>
      <h5 style={{color:color?"white":"black" , fontWeight:"bold"}}>{previewData.title}</h5>
      <p style={{color:color?"white":"black" , fontSize:12, width:"90%" , wordWrap:"break-word"}}>{previewData.description}</p>
      {previewData.image && <img src={previewData.image} alt="Link Preview" style={{maxWidth:"100%" , borderRadius:"8px"}}/>}
    </div>
  );
}

export default LinkPreview;