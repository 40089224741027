import React from "react";
import { Col, Row } from "reactstrap";
import Layout from "./Layout";
const CompHome = ({setNavMaps , NavMaps ,UpRef}) => {
    
  return (
    <>
      <Row>
        <Col md="12" lg="12" sm="12" xm="12">
          <Layout setNavMaps={(e) =>setNavMaps(e)} NavMaps = {NavMaps} UpRef={UpRef} />
      </Col>  
      
      </Row>
    </>
  );
};

export default CompHome;
