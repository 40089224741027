import React from "react";
import "../assets/styles/home.css";

export const Loader = () => {
  return (
    <div>
    <p className="text-center text-dark">Uploading</p>
      <div className="balls">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
