import React from 'react'
import "../../../assets/styles/Footer/pressRelese.css"
import FooterNav from './FooterNav';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const PressRelease = () => {
    document.documentElement.scrollTop = 0;
    return (
        <>
               <div style={{ backgroundColor: "#fff" }}>
                <FooterNav />
                <div className="container pb-5" >
                    <section className="mt-5 pb-3">  
                        <div className="card" style={{ border: "none" }}>
                            <div className="text-center">
                                <img className="card-img-top rounded-3" style={{ maxWidth: "1400px" }} src={"img/Ibouge_Facebook_1200x630_B.png"} alt="Cardimage cap" />
                            </div>
                            <div className="text-center pb-4" style={{ margin: "-50px 25px 0px 25px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", background: "#fff", paddingTop: "20px", borderRadius: "4px" }}>
                                        <h2 className='text-dark'><strong id="blogTitle">iBouge Launches Global Geo-Networking Platform for Businesses</strong></h2>
                                        <p>Published by iBouge PR Team, 7/05/2019</p>

                                        <div className="social-counters ">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaLinkedin size={24} style={{ cursor: "pointer" }} color='#0082ca' className='mx-2' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                                            >
                                                <FaTwitter size={24} style={{ cursor: "pointer" }} color='#55acee' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaFacebook size={24} style={{ cursor: "pointer" }} color='#3b5998' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.instagram.com/ibougeinc"
                                            >
                                            <FaInstagram size={24} style={{ cursor: "pointer" }} color='#DD2A7B' className='mx-3' />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </section>
                                <section>
                                <div className="mt-5" style={{marginTop: '200px'}}>
                                    <p>iBouge is a global GIS platform that redesigns industries on the internet using location to geotag people, products, and information on its global mapping platform.  </p>

                                    <p>The company now offers business pages to small and large companies everywhere. Business owners can register their company and geotag its location on iBouge's global map interface. The new business offering is described as a hybrid between Google Maps and Facebook Business Pages making it easy to find shops and companies by their location and to learn more about them. It offers direct messaging with the business and the ability to follow the company for updates and to see their posts. The business page also redirects to the company's own website and lists their contact information. </p>
                                    <p>iBouge's chief executive Lisa Rowlett Leslie describes this launch as "the next step in building a global community on iBouge. Our location services provide a strong advantage for businesses in any country to be found and to promote themselves using location as a consistent placeholder, making our platform an advanced global business directory -- which currently doesn't exist on Amazon, Etsy, or Facebook." </p>
                                    <p>She continued, "Our future vision is for any merchant in the world to be able to sell to a global online audience using iBouge's e-commerce dashboard to manage global transactions." The company will charge a competitive transaction fee compared to eBay, Etsy, and Facebook.
                                        <p>iBouge was founded in 2017 and currently offers geo-networking, location-based chat, and events on its global map interface. The business page launch is the beginning of iBouge's expansion into the global e-commerce market using its patented platform to compete in organizing and finding businesses on the internet. </p>
                                    </p>

                                </div>
                                </section>
                </div>

                    <div className='container' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>

                    <div className="p-5 text-center text-md-left author-box" >

                        <h4 className="h3-responsive text-center font-weight-bold dark-grey-text">About Founder</h4>
                        
                        <hr />

                        <div className="row">
                            <div className="col-12 col-md-2 col-xl-3 my-auto">
                                <img id="authorPhoto" src="img/BlogAuthorProfilePhoto.png" alt='authorPhoto' style={{ borderRadius: "50%", height: "270px", width: "250px" }} className='mx-auto' />
                            </div>

                            <div className="col-12 col-md-10 col-xl-9 text-lg-start text-md-start text-sm-center">
                                <h5 className="font-weight-bold text-dark mb-3 h5big">
                                    <strong>Lisa Rowlett Leslie</strong>
                                </h5>
                                <div className="personal-sm pb-3">
                                
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaLinkedin size={24} style={{ cursor: "pointer" }} color='#0082ca' className='mx-2' href="www.linkedin.com/in/tg308" />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                                            >
                                                <FaTwitter size={24} style={{ cursor: "pointer" }} color='#55acee' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaFacebook size={24} style={{ cursor: "pointer" }} color='#3b5998' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.instagram.com/ibougeinc"
                                            >
                                            <FaInstagram size={24} style={{ cursor: "pointer" }} color='#DD2A7B' className='mx-3' />
                                            </a>
                                        
                                </div>
                                <p id="authorBio">Lisa is an information architect and entrepreneur who loves to develop websites and travel the world.</p>
                                <p id="authorBio">Lisa holds a double major and a graduate certificate from the University of Washington and has lived in Paris, Dubai, NYC, SF, Seattle and Portland, Oregon.
                                </p>
                                <p id="authorBio">She is the founder and CEO of iBouge.com, the world's first geo-networking platform as well as the main contributor for the iBouge blog.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className='pt-3'>
                <p>iBouge ® • Copyright © 2024 iBouge Inc. • All Rights Reserved.</p>
                <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
            </footer>
        </>
    )
}

export default PressRelease;
