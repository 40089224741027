import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import moment from "moment";
import { useSelector } from "react-redux";
import { Server2, Socket } from "../../../redux/actions/auth/auth.actions";
import axios from "axios";

const MsgInbox = ({
  setToggleMsg,
  setChatData,
  setRoomId,
  setChatBoxShow,
  Room
}) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [InboxList, setInboxList] = useState([]);
  const [typinglist, setTypingList] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() =>{
    Socket.on("newmessage", (data) => {
      GetInbox()
    })
  },[Socket])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    GetInbox()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const GetInbox = () =>{
    var config = {
      method: "GET",
      url: Server2 + `/users/getInbox?id=${userData?._id}`,
    };
    axios(config)
      .then((response) => {
        setInboxList(response.data);
        setLoading(false)
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }
  const GetUserData = async (e) => {
    return await fetch(
      Server2 + `/users/userprofile?id=${userData?._id}&user=${e}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        return data.user;
      })
      .catch(function (error) {
        console.log("error Get USer profile ", error);
      });
  };
  useEffect(() => {
    Socket.on("inboxtyping", (data) => {
      if (data.statustyping === true) {
        setTypingList((prev) => [...prev, data])
      } else {
        var lists = typinglist.filter(x => {
          return x.from !== data.from && x.room !== data.room;
        })
        setTypingList(lists)
      }
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const gettype = (id, room) => {
    let type = false
    typinglist.forEach((item) => {
      if (item.from === id && item.room === room) {
        type = true;
      }
    })
    return type;
  }
  useEffect(()=>{
    let list = []
    for (let index = 0; index < InboxList.length; index++) { 
      const element = InboxList[index];
      const id =  element?.users[0]?.user_id !== userData?._id? element?.users[0]?.user_id: element?.users[1]?.user_id;
      gettype(id,element.room)?element.typing=true:element.typing=false
      list.push(element)
    }
    setInboxList(list)
  },[typinglist]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="msg-box-Main p-0 ms-5">
      <div className="nb-pointer"></div>
      <div className="text-start rounded-3 overflow-hidden notibox bg-white">
        <div className="notibox-Header text-white">
          <span className="py-3 fw-bolder fs-6 px-4">Inbox</span>
          <FiX
            className="fs-4 text-white me-3"
            role="button"
            onClick={() => {
      
              setToggleMsg(false);
            }}
          />
        </div>

        {/* Messages Div */}
        <div className="notifications pt-2">
          {Loading === true ? <div style={{ display: "flex", justifyContent: "center", margin: "5%" }}><div className="loading2"></div></div> :
            InboxList?.length > 0 ? (
              <>
                {InboxList.map((noti, index) => {
            
                  const id =
                    noti?.users[0]?.user_id !== userData?._id
                      ? noti?.users[0]?.user_id
                      : noti?.users[1]?.user_id;
                  let message =
                    noti.messages
                      ? noti.messages[0].message.length > 20
                        ? noti.messages[0].message.substring(0, 20) + "..."
                        : noti.messages[0].message
                      : "File";
                  return (
                    <div
                      className="d-flex align-items-center py-2 rounded-3 ps-3 bg-light mb-1 position-relative"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setChatData([]);
                        if (Room) {
                          const data = {
                            room: Room,
                            id: userData?._id
                          };
                          Socket.emit("leave-room", data);
                        }
                        if (noti.users.length === 2) {
                          setRoomId(noti.room);
                          setChatData([]);
                          GetUserData(id).then((e) => {
                            const a = {
                              _id: e._id,
                              item: {
                                _id: e._id,
                                fname: e.fname,
                                is_online: e.is_online,
                                lname: e.lname,
                                location: e.location,
                                profile: e.profile,
                                profile_pic: e.profile_pic,
                              },
                            };

                            setChatData((curr) => [...curr, a]);
                          });
                        } else {
                          setRoomId(noti.room);
                          noti.users.forEach((e) => {
                            GetUserData(e.user_id).then((e) => {
                              const a = {
                                _id: e._id,
                                item: {
                                  _id: e._id,
                                  fname: e.fname,
                                  is_online: e.is_online,
                                  lname: e.lname,
                                  location: e.location,
                                  profile: e.profile,
                                  profile_pic: e.profile_pic,
                                },
                              };

                              setChatData((cur) => [...cur, a]);
                            });
                          });
                        }
                        setToggleMsg(false);
                        setTimeout(() => {
                          setChatBoxShow(true);
                        }, 2500);
                      }}
                      key={index}
                    >
                      <div className="pe-3">
                        <img
                          src={
                            !noti.image
                              ? "/img/upload-photo.png"
                              : noti.image
                          }
                          height={40}
                          className="rounded-circle image-user"
                          alt="ibouge"
                          style={{ border: "1px solid #6148A1" }}
                        />
                        {noti.is_online === true ? (
                          <img
                            src="/Images/contact-online.png"
                            className=""
                            style={{
                              position: "absolute",
                              bottom: "25%",
                              left: "13%",
                            }}
                            height={9}
                            alt="dot sign"
                          />
                        ) : (
                          <img
                            src="/Images/contact-idle.png"
                            className=""
                            style={{
                              position: "absolute",
                              bottom: "25%",
                              left: "13%",
                            }}
                            height={9}
                            alt="dot sign"
                          />
                        )}
                      </div>
                      <div className=" text-dark ">
                        <span className="fw-bold">{noti.name}</span>
                        <br />
                        <div className="d-flex flex-column">
                          <span className="" style={{ fontSize: "12px" }}>
                            {noti.typing?
                                      <div className="typing my-2 fw-lighter">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                      </div>
                                  :
                                  message
                            }

                          </span>
                          <span
                            className=""
                            style={{fontSize:"12px" , marginTop:"4px" }}
                          >
                            {moment(
                              noti.messages
                                ? noti.messages[0].time
                                : noti.created_date
                            ).format("MMMM Do, YYYY  h:mm A")}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) :
              <div className="fs-5 fw-bold text-center pt-5 text-dark">No Chats available</div>
          }
          {/* ^ Notifications Div*/}
        </div>
        {/* ^ Main Div */}
      </div>
    </div>
  );
};

export default MsgInbox;
