import React from 'react'
import SideBar from './SideBar'
import NavbarHome from '../Pages/home/NavbarNew/NavbarHome'
const Admin = () => {
    return (
        <div className='' style={{height:"100vh", background:"white", overflow: 'hidden'}}>
            <NavbarHome/>
                
            <div className="">
                <SideBar />
            </div>
       
        </div>
    )
}

export default Admin
