import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { useRef } from "react";
import AddEventLoaction from "./AddEventLoaction";
import TimePicker from "@ashwinthomas/react-time-picker-dropdown";
import ProfileImage from "../../My-Profile/ProfileImage";

function AddEvent(props,{isOpen , setIsOpen}) {
  const prams = window.location.pathname;
  // for save Value of Input Field
  const [error, setError] = useState("");
  const [EventName, setEventName] = useState("");
  const [EventCategory, setEventCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [EventSTime, setEventSTime] = useState("");
  const [EventETime, setEventETime] = useState("");
  const [EventDes, setEventDes] = useState("");
  const [InputPhoto, setInputPhoto] = useState("");
  const [ProfilePicPopup, setProfilePicPopup] = useState(false);

  // Manage two components with state
  const [EventFirst, setEventFirst] = useState(true);
  // Image Selection Start
  const [Image, setImage] = useState("");
  console.log(Image,"i am Image")
  const [IsUpload, setIsUpload] = useState(false);
  const inputFile = useRef(null);
  // to open file explore
  const openInputFile = (e) => {
    inputFile.current.click();
  };
  // to display image
  const handleImageChange = (e) => {
    setInputPhoto(e.target.files[0]);
    inputFile.current.click();
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setProfilePicPopup(true);
    }
  };
  const handleClick = () => {
    if (!EventName.trim()) {
      setError("eventname");
    } else if (!EventCategory.trim()) {
      setError("category");
    } else if (startDate === "") {
      setError("date");
    } else if (EventSTime === "") {
      setError("stime");
    } else if (EventETime === "") {
      setError("etime");
    } else if (!EventDes.trim()) {
      setError("des");
    } else if (Image === "") {
      setError("inputfile");
    } else {
      setEventFirst(false);
    }
  };
  return (
    <>
<div className="event-container w-100 px-3" style={{overflow:prams=="/home"?"scroll":"",scrollbarWidth:"none",}}>
        <div className="event-main border rounded-3 shadow mb-4 w-100">
          <div className="event-header py-1 text-white d-flex align-items-center justify-content-between">
            <p className="fw-bold mx-3 my-2 ">Add Event</p>
            <FiX
              className="fs-4 text-white me-3"
              role="button"
              onClick={props.handleClose}
            />
          </div>
          {EventFirst === true ? (
            <div>
              <div className=" text-center">
                <span className="fw-bold event-step">Step 1/2</span>
                <br />
                <span className="fw-bold ">Event Details</span>
              </div>
              <div className="event-body ">
                <div className="form-name">
                  <p className="mb-0 mx-3 mt-1">Event Name</p>
                  <div className=" mt-1 mx-3 d-flex">
                    <input
                      name="eventName"
                      id="eventName"
                      type="text"
                      style={{
                        borderColor:
                          EventName.length > 0
                            ? "#ECECEC"
                            : error === "eventname"
                            ? "red"
                            : "#ECECEC",
                      }}
                      placeholder="Biggest Pizza Challenge 2022"
                      className="search-f1 ps-2 p-2 form-control "
                      aria-label="Event-Name"
                      value={EventName}
                      onChange={(e) => setEventName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-category">
                  <p className="mb-0 mx-3 mt-2">Event Category</p>
                  <div className="mt-1 position-relative">
                    <select
                      className="form-select form-select-sm EventCat"
                      name="gender"
                      style={{
                        borderColor:
                          EventCategory.length > 0
                            ? "#ECECEC"
                            : error === "category"
                            ? "red"
                            : "#ECECEC",
                      }}
                      onChange={(e) => setEventCategory(e.target.value)}
                      value={EventCategory}
                    >
                      <option
                        value="Gender"
                        className="p-2 text-dark"
                      >
                        Event Category
                      </option>
                      <option value="Restaurant" className="p-2 text-dark">
                        Restaurant
                      </option>
                      <option value="Entertainment" className="p-2 text-dark">
                        Entertainment
                      </option>
                      <option value="Health" className="p-2 text-dark">
                        Health & Fitness
                      </option>
                      <option value="Shopping" className="p-2 text-dark">
                        Shopping
                      </option>
                      <option value="Offer/Discount" className="p-2 text-dark">
                        Offer/Discount
                      </option>
                      <option value="Hobby" className="p-2 text-dark">
                        Hobby
                      </option>
                      <option value="Other" className="p-2 text-dark">
                        Other
                      </option>
                    </select>
                  </div>
                </div>

                {/* Date Div */}
                <div className="form-date position-relative">
                  <p className="mb-0 mx-3 mt-2">Start Date</p>
                  <div className="d-flex">
                  <input  type="date"
                  name="date"
                  id="date"
                    className="Eventdate ps-2 form-control"
                      placeholder="November 12, 2024"
                      onChange={(date) => setStartDate(date.target.value)}
                      min={new Date().toISOString().split("T")[0]}
                      value={startDate}
                      data-date-format="MM DD YYYY"
                  />
                  </div>
                </div>

                <div className="d-flex p-1">
                  <div className="">
                    <p className="mb-0 mx-3 mt-2">Start Time</p>
                    <div className="d-flex mt-1 mx-2">
                      <input
                        name="STime"
                        id="STime"
                        type="time"
                        style={{
                          borderColor:
                            EventSTime.length > 0
                              ? "#ECECEC"
                              : error === "stime"
                              ? "red"
                              : "#ECECEC",
                        }}
                        placeholder="HH : MM"
                        className="timeinpt form-control"
                        // aria-label="text"
                        defaultValue={"10:00"}
                        value={EventSTime}
                        onFocus={(e) => {
                          e.target.showPicker();
                        }}
                        onChange={(e) => setEventSTime(e.target.value)}
                      />
                      {/* <TimePicker
                      className="timeinpt form-control"
                        defaultValue="10:00"
                        placeholder="hh:mm"
                        useTwelveHourFormat={false}
                        showClockIcon={false}
                        showCloseIcon={false}
                        allowBackdrop={true}
                        onTimeChange={(e) => setEventSTime(e)}
                      /> */}
                    </div>
                  </div>
                  <div>
                    <p className="mb-0 mx-4 mt-2">End Time</p>
                    <div className="d-flex mt-1 mx-3 P-1">
                    <input
                        name="ETime"
                        id="ETime"
                        type="time"
                        style={{
                          borderColor:
                            EventETime.length > 0
                              ? "#ECECEC"
                              : error === "etime"
                              ? "red"
                              : "#ECECEC",
                        }}
                        placeholder="HH : MM"
                        className="timeinpt form-control"
                        defaultValue={"10:00"}
                        value={EventETime}
                        onFocus={(e) => {
                          e.target.showPicker();
                        }}
                        onChange={(e) => setEventETime(e.target.value)}
                      />
                    {/* <TimePicker
                      className="timeinpt form-control"
                        defaultValue="10:00"
                        placeholder="hh:mm"
                        useTwelveHourFormat={false}
                        showClockIcon={false}
                        showCloseIcon={false}
                        allowBackdrop={true}
                        onTimeChange={(e) => setEventETime(e)}
                      /> */}
                    </div>
                  </div>
                </div>
                <p className="mb-0 mx-3 mt-2">Event Description</p>

                <textarea
                  name="description"
                  id="description"
                  style={{
                    borderColor:
                      EventDes.length > 0
                        ? "#ECECEC"
                        : error === "des"
                        ? "red"
                        : "#ECECEC",
                  }}
                  className="event-des form-control rounded-3"
                  rows={3}
                  value={EventDes}
                  onChange={(e) => setEventDes(e.target.value)}
                ></textarea>

                <p className="mb-0 mx-3 mt-2">Cover Photo</p>
                {IsUpload ? (
                  <img
                    src={Image}
                    className="event-photo"
                    style={{
                      borderColor:
                        Image.length > 0
                          ? "#6148a1"
                          : error === "inputfile"
                          ? "red"
                          : "#6148a1",
                      objectFit: "cover"
                    }}
                    height={110}
                    alt="uploaded"
                    onChange={handleImageChange}
                    onClick={openInputFile}
                  />
                ) : (
                  <div>
                    <img
                      src="/img/uploaddemo.png"
                      style={{
                        borderColor:
                          Image.length > 0
                            ? "#6148a1"
                            : error === "inputfile"
                            ? "red"
                            : "#6148a1",
                            cursor:"pointer"
                      }}
                      alt="uploaddemo"
                      className="event-photo"
                      onClick={openInputFile}
                      onChange={handleImageChange}
                    />
                  </div>
                )}
                <input
                  type="file"
                  id="file"
                  className="event-photo"
                  ref={inputFile}
                  accept="image/png, image/jpeg"
                  onClick={openInputFile}
                  onChange={handleImageChange}
                  style={{
                    display: "none",
                    borderColor:
                      Image.length > 0
                        ? "#6148a1"
                        : error === "inputfile"
                        ? "red"
                        : "#6148a1",
                  }}
                />
                <div className="p-3 text-center ">
                  <button
                    className="event-location-btn "
                    type="button"
                    data-bs-toggle="button"
                    onClick={handleClick}
                  >
                    Next to Location
                  </button>
                </div>
              </div>
              {ProfilePicPopup === true ? (
              <ProfileImage
                src={Image}
                Full={inputFile.current.files[0]}
                handleClose={() => setProfilePicPopup(false)}
                type={"event"}
                setImage={(e) => setImage(e)}
                upload={() => setIsUpload(true)}
                InputPhoto={(e)=> setInputPhoto(e)}
              />
            ) : null}
            </div>
          ) : (
            <AddEventLoaction
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              Close={props.handleClose}
              handleApi={()=>props.handleApi()}
              EventName={EventName}
              EventCategory={EventCategory}
              startDate={startDate}
              EventSTime={EventSTime}
              EventETime={EventETime}
              EventDes={EventDes}
              InputPhoto={InputPhoto}
              press={() => setEventFirst((prev) => !prev)}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AddEvent;