import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/Profile.css";

function ThankYouModel() {
  const NormalUser = useSelector((state) => state?.auth?.user?.user);
  const [counter, setCounter] = useState(6);
  let navigate = useNavigate();
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      if (NormalUser?.location?.extraCityToFollow0) {
        navigate("/home", {
          state: {
            item: [
              NormalUser.location.coordinates[0].coords.lng,
              NormalUser.location.coordinates[0].coords.lat,
            ],
          },
        });
      }
    }
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <div className="" style={{
        height:"100vh",
        backgroundColor:"white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        {/* <center> */}
          <div
            className="text-center bg-white"
            style={{padding:"30px",boxShadow: "0px 10px 64px rgba(21, 48, 89, 0.3)" }}
          >
            <p className="font">Thank You!</p>
            <FaCheck size={60} className="check mb-4" />

            <h3 className="text-dark pb-2 fs-5">
              Logging you into iBouge in{" "}
              <span className="text-primary fs-5 ">{counter}</span> {counter === 1 ? "second" : "seconds"}
            </h3>
            <h4 className="text-dark pb-3 fs-5">
              Thank you! Your business page has now been activated.
            </h4>
            <h6 className="pt-3 text-dark">
              &copy; {new Date().getFullYear()}
            </h6>
          </div>
        {/* </center> */}
      </div>
    </div>
  );
}

export default ThankYouModel;
