import * as authType from "./auth.types";
import axios from "axios";
import SocketIOClient from "socket.io-client";
//  import {auth, facebookAuthProvider } from "../../../services/firebase";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
export const Server2 = "https://www.ibouge.com/ibouge_backend"
export const Socket =
    SocketIOClient("https://www.ibouge.com", {
      jsonp: false,
    });


export const thumbnailGen = (files) => {
  return generateVideoThumbnails(files, 1).then((thumbs) => {
    const base64ToFile = (base64, filename) => {
      const arr = base64.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };
  
      const base64String = thumbs[0];
      const filename = "image.jpg";
      const fileThumb = base64ToFile(base64String, filename);
      return (fileThumb);
  });
}

//Login User
export const loadUser = (data) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: authType.LOADING });
      const res = await axios.post(Server2 + "/auth/login", {
        email: data.email,
        password: data.password,
      });
      dispatch({
        type: authType.USER_SUCCESS,
        payload: res?.data?.user,
      });
      return res?.data?.user;
    } catch (error) {
      dispatch({
        type: authType.USER_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
//Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: authType.LOGOUT });
};
// Sign Up
export const newUserAction = (data) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: authType.LOADING });
      const res = await axios.post(Server2 + "/auth/signup", {
        type: data.type,
        email: data.email,
        business_category: data.business_category,
        business_name: data.business_name,
        business_owner: data.user_name,
        gender: data.gender,
        fname: data.fname,
        lname: data.lname,
        dob: data.dob,
        password: data.password,
      });
      dispatch({
        type: authType.NEW_USER_SUCCESS,
        payload: res?.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: authType.NEW_USER_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
//Active User
export const LinkUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(Server2 + "/auth/activate-account", {
      token: data.token,
    });
    dispatch({
      type: authType.LINK_USER_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: authType.LINK_USER_FAIL,
      payload: error,
    });
  }
};
//ResetPassword
export const resetPassword = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/auth/restore-password", {
        email: data.email,
      });
      dispatch({
        type: authType.FORGOT_PASSWORD_SUCCESSS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: authType.FORGOT_PASSWORD_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
//Resend Email
export const newpassword = (data) => {
  return async (dispatch) => {
    try {
    const res = await axios.post(Server2 + "/auth/new-password", {
      email: data.email,
      password: data.password,
      token: data.token,
    });
    dispatch({
      type: authType.NEW_PASSWORD_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: authType.NEW_PASSWORD_FAIL,
      payload: error,
    });
    return error
  }
}
};

//GOOGLE api for signup
export const googleSignUpAuth = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/social/googlesignup", {
        email: data.email,
        fname: data.fname,
        lname: data.lname,
      });
      dispatch({
        type: authType.GOOGLE_SIGNUP_SUCCESS,
        payload: res?.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: authType.GOOGLE_SIGNUP_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
//Facebook api for signup
export const facebookSignUpAuth = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/social/facebooksignup", {
        email: data.email,
        fname: data.fname,
        lname: data.lname,
      });
      dispatch({
        type: authType.FACEBOOK_SIGNUP_SUCCESS,
        payload: res.data,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: authType.FACEBOOK_SIGNUP_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

// GOOGLE api for login
export const googleLoginAuth = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/social/googlesignin", {
        email: data.email,
      });
      dispatch({
        type: authType.GOOGLE_AUTH_SUCCESS,
        payload: res.data.user,
      });
      return res?.data;
    } catch (error) {
      dispatch({
        type: authType.GOOGLE_AUTH_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
////////////////////////////////////////
// Facebook api for login
////////////////////////////////////////
export const facebookLoginAuth = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/social/facebooksignin", {
        email: data.email,
      });
      dispatch({
        type: authType.FACEBOOK_AUTH_SUCCESS,
        payload: res.data.user,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: authType.FACEBOOK_AUTH_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

// Bussiness first Step Action

export const firstStepAction = (data) => async (dispatch) => {
  try {
    const res = await axios.put(Server2 + "/steps/business", {
      address: data.address,
      country_code: data.country_code,
      phone_number: data.phone_number,
      business_summary: data.business_summary,
      about_company: data.about_company,
      activation_status: data.activation_status,
      email: data.email,
    });
    const newSet = {
      message: "User Logged in Successfully.",
      status: 200,
      user: res?.data,
    };
    dispatch({
      type: authType.FIRST_BUSSINESS_SUCCESS,
      payload: newSet,
    });
  } catch (error) {
    dispatch({
      type: authType.FIRST_BUSSINESS_FAIL,
      payload: error,
    });
  }
};
// Bussiness Forth Step Action


export const FourthStepAction = (data) => {
  console.log(data,"i am data of fouth Step dispatch")
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/stripe/subscribePlan", {
        // card_number: data.card_number,
        // cvc: data.cvc,
        // card_expiry_year: data.card_expiry_year,
        // card_expiry_month: data.card_expiry_month,
        // plan_id: data.plan_id,
        amount:data.amount,
        id: data.id,
        email: data.email,
        intentId:data.IntentId,
        createdDate:data.createdDate,
        expiryDate:data.expiryDate
      });
      if (res.data.User) {
        const newSet = {
          message: "User Logged in Successfully.",
          status: 200,
          user: res?.data?.User,
        };
        dispatch({
          type: authType.FOURTH_BUSSINESS_SUCCESS,
          payload: newSet,
        });
        return newSet;
      } else {
        return res;
      }
    } catch (error) {
      dispatch({
        type: authType.FOURTH_BUSSINESS_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
// Presonal First Step Action
export const firstStepPresonal = (data) => async (dispatch) => {
  try {
    const res = await axios.put(Server2 + "/steps/personal", {
      languages: data.languages,
      interests: data.interests,
      about_me: data.about_me,
      activation_status: data.activation_status,
      email: data.email,
    });
    const newSet = {
      message: "User Logged in Successfully.",
      status: 200,
      user: res?.data,
    };
    dispatch({
      type: authType.FIRST_PRESONAL_SUCCESS,
      payload: newSet,
    });
  } catch (error) {
    dispatch({
      type: authType.FIRST_PRESONAL_FAIL,
      payload: error,
    });
  }
};
// Presonal Second Step Action

export const secondStepPresonal = (data) => async (dispatch) => {
  try {
    const res = await axios.put(Server2 + "/steps/location", {
      coordinates: data.coordinates,
      activation_status: data.activation_status,
      email: data.email,
    });
    const newSet = {
      message: "User Logged in Successfully.",
      status: 200,
      user: res?.data,
    };
    dispatch({
      type: authType.SECOND_PRESONAL_SUCCESS,
      payload: newSet,
    });
  } catch (error) {
    dispatch({
      type: authType.SECOND_PRESONAL_FAIL,
      payload: error,
    });
  }
};
// Presonal third Step Action

export const ThirdStepPresonal = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(Server2 + "/steps/cities", {
        activation_status: data.activation_status,
        email: data.email,
        cityname1: data.cityname1,
        cityname2: data.cityname2,
        cityname3: data.cityname3,
      });
      const newSet = {
        message: "User Logged in Successfully.",
        status: 200,
        user: res?.data,
      };
      dispatch({
        type: authType.THIRD_PRESONAL_SUCCESS,
        payload: newSet,
      });
      return newSet;
    } catch (error) {
      dispatch({
        type: authType.THIRD_PRESONAL_FAIL,
        payload: error,
      });
      return error;
    }
  };
};
// Agreed Terms & Policy

export const AgreedTC = (data) => {
  console.log(data.id,"AgreedAdta")
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/agreed", {
        id:data.id
      });
      const newSet = {
        message: "User Logged in Successfully.",
        status: 200,
        user: res?.data?.user,
      };
      dispatch({
        type: authType.AGREED_TP_SUCCESS,
        payload: newSet,
      });
      return newSet;
    } catch (error) {
      dispatch({
        type: authType.AGREED_TP_FAIL,
        payload: error,
      });
      return error;
    }
  };
};

// cover Pic
export const CoverPic = (data) => async (dispatch) => {
  dispatch({
    type: authType.COVERPHOTO,
    payload: data,
  });
};
// Profile Pic
export const ProfilePic = (data) => async (dispatch) => {
  dispatch({
    type: authType.PROFILEPHOTO,
    payload: data,
  });
};
// API'S Settings Starts

// Profile Settings Start

export const SetProfilePersonal = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/user-profile", {
        email: data.email,
        about_me: data.about_me,
        interests: data.interests,
        languages: data.languages,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data,
      };
      dispatch({
        type: authType.SETTING_PRO_PERSONAL_SUCCESS,
        payload: newSet,
      });
      return res
    } catch (error) {
      dispatch({
        type: authType.SETTING_PRO_PERSONAL_FAIL,
        payload: error,
      });
      return error;
    }
  }
};
// Profile Settings Start Bussiness
export const SetProfileBusiness = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/business-profile", {
        email: data.email,
        business_summary: data.business_summary,
        phone_number: data.phone_number,
        country_code: data.country_code,
        address: data.address,
        about_company: data.about_company,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data,
      };
      dispatch({
        type: authType.SETTING_PRO_BUSINESS_SUCCESS,
        payload: newSet,
      });
      return res;
    } catch (error) {
      dispatch({
        type: authType.SETTING_PRO_BUSINESS_FAIL,
        payload: error,
      });
      return error
    }
  }
};
//  update ACCOUNT settings bussiness

export const SetAccBusiness = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/business-account", {
        email: data.email,
        business_name: data.business_name,
        password: data.password,
        business_category: data.business_category,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data?.user,
      };
      dispatch({
        type: authType.SETTING_ACC_BUSINESS_SUCCESS,
        payload: newSet,
      });
      return res
    } catch (error) {
      dispatch({
        type: authType.SETTING_ACC_BUSINESS_FAIL,
        payload: error,
      });
      return error
    }
  }
};
// update settings bussiness
export const SetAccPersonal = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/user-account", {
        email: data.email,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        gender: data.gender,
        dob: data.dob,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data?.user,
      };
      dispatch({
        type: authType.SETTING_ACC_PERSONAL_SUCCESS,
        payload: newSet,
      });
      return res
    } catch (error) {
      dispatch({
        type: authType.SETTING_ACC_PERSONAL_FAIL,
        payload: error,
      });
      return error
    }
  }
};
// update Location ACCOUNT settings bussiness

export const SetUpdateLoc = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/setting-location", {
        email: data.email,
        coordinates: data.coordinates,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data,
      };
      dispatch({
        type: authType.SETTING_LOCATION_SUCCESS,
        payload: newSet,
      });
      return res
    } catch (error) {
      dispatch({
        type: authType.SETTING_LOCATION_FAIL,
        payload: error,
      });
      return error
    }
  }
};

export const SetPrivacy = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/privacy", {
        email: data.email,
        new_messages: data.new_messages,
        profile_info_private: data.profile_info_private,
        friend_list_private: data.friend_list_private,
        wall_private: data.wall_private,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data?.user,
      };
      dispatch({
        type: authType.SETTING_PRIVACY_SUCCESS,
        payload: newSet,
      });
      return res
    } catch (error) {
      dispatch({
        type: authType.SETTING_PRIVACY_FAIL,
        payload: error,
      });
      return error
    }
  }
};
export const SetNotifications = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(Server2 + "/users/notification", {
        email: data.email,
        new_messages: data.new_messages,
        new_events: data.new_events,
        friend_requests: data.friend_requests,
        invitations_to_conversation: data.invitations_to_conversation,
      });
      const newSet = {
        status: 200,
        message: "User Logged in Successfully.",
        user: res?.data?.user,
      };
      dispatch({
        type: authType.SETTING_NOTIFICATIONS_SUCCESS,
        payload: newSet,
      });
      return res
    } catch (error) {
      dispatch({
        type: authType.SETTING_NOTIFICATIONS_FAIL,
        payload: error,
      });
      return error
    }
  }
};
//  update Cities
export const UpdateCities = (data) => async (dispatch) => {
  try {
    const res = await axios.post(Server2 + "/users/Updatecity", {
      email: data.email,
      cityname: data.cityname,
      index: data.index,
      lastcity: data.lastcity,
    });
    const newSet = {
      status: 200,
      message: "User Logged in Successfully.",
      user: res?.data,
    };
    dispatch({
      type: authType.UPDATE_CITY_SUCCESS,
      payload: newSet,
    });
  } catch (error) {
    dispatch({
      type: authType.UPDATE_CITY_FAIL,
      payload: error,
    });
  }
};
//  update Cities
export const UserProfileUpdate = (data) => async (dispatch) => {
  try {
    const res = await axios.get(
      Server2 + `/users/userprofile?id=${data}&user=${data}`
    );
    const newSet = {
      status: 200,
      message: "User Logged in Successfully.",
      user: res?.data?.user,
    };
    dispatch({
      type: authType.PROFILE_UPDATE_SUCCESS,
      payload: newSet,
    });
  } catch (error) {
    dispatch({
      type: authType.PROFILE_UPDATE_FAIL,
      payload: error,
    });
  }
};
//Account Deactivate
export const UserAccountDeactivate = (data) => {
  return async (dispatch) => {
    try {
    const res = await axios.post(Server2 + "/users/deactivate", {
      email: data.email,
      id:data.id,
      status:data.status
    });
    const newSet = {
      status: 200,
      message: "User Deactivate Successfully.",
      user: res?.data?.user,
    };
    dispatch({
      type: authType.USER_DEACTIVATE_SUCCESS,
      payload: newSet,
    });
    return res
  } catch (error) {
    dispatch({
      type: authType.USER_DEACTIVATE_FAIL,
      payload: error,
    });
  }
}
};
//Update City change
export const DashCitySelect = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
      type: authType.DASH_CITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: authType.DASH_CITY_FAIL,
      payload: error,
    });
  }
}
};