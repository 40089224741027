import React, { useEffect, useRef } from "react";
import { FaAngleRight, FaCaretDown, FaCaretUp } from "react-icons/fa";
import "../../../assets/styles/home.css";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment/moment";
import { FiX } from 'react-icons/fi';


const UserPanel = ({ list, type, length, isopen, isMicro, isActiv, MicroData, UpRef}) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [checkScroll , setCheckScroll] = useState(false)
  const [panelScroll , setPanelScroll] = useState(false)
  const refPanel = useRef(null)
  const navigate = useNavigate();

  const handleUsers = (items) => {
    if (items._id === userData?._id) {
      navigate(`/${userData.type?"business":"personal"}/${userData?._id}`)
    } else {
      navigate(`/Profiless/${items._id}`, {
        state: {
          item: items._id,
        },
      });
    }
  };
  const handleEvent = (items) => {
    navigate("/Event", {
      state: {
        item: items,
      },
    });
  };
  const handleMicro = (items) => {
    MicroData([items])
    isActiv()
  };
  const bottomEl = useRef(null);
  // const bottomUl = useRef(null);
  // const handleScrollDown = () =>{
  //   setCheckScroll(true)
  //   bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
  // }
  const handleScrollUp = () =>{
    window.scrollTo(0, 0)
    // setCheckScroll(false)
    // UpRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <>
      <aside className="main sticky-xl-top ms-1 pe-2 shadow-lg"
        onClick={() => {setPanelScroll(!panelScroll);}}
        style={{ paddingLeft: window.innerWidth < 768 ? "15px" : "" }}>
        <div
          className="main-head d-flex"
          style={{
            backgroundColor:
              type === "user"
                ? "#0079CB"
                : type === "event"
                  ? "#984399"
                  : type === "micro"
                    ? "#E40090"
                    : type === "business"
                      ? "#40B307"
                      : "",
          }}
        >
        <div className="d-flex">
          {type === "user" ? (
            <img src="img/TwoUsers.png" className="TwoUsers" alt="Users" />
          ) : type === "event" ? (
            <img src="img/Events.png" className="Twoevents" alt="Events" />
          ) : type === "micro" ? (
            <img src="img/MicroBlog.png" className="Twoblogs" alt="MicroBlog" />
          ) : (
            <img
              src="img/cartBusiness.png"
              className="cartBusiness"
              alt="Business"
            />
          )}
          <h4 className="heading ">
            {length}
            {type === "user"
              ? " Users"
              : type === "event"
                ? " Events"
                : type === "micro"
                  ? " Microblogs"
                  : " Business"}
          </h4>
        </div>
          <div className="d-flex ms-auto align-items-center"> 
            <div className="d-md-none d-block">
              { type === "event" || type === "micro" ? (
                <BsPlusLg className="BsPlusLg-event" color="white" size={14} onClick={() => {
                  if(type === "event"){isopen()} 
                  else if(type === "micro"){isMicro()} 
                }}/>
              ) : null }
            </div>
            
            <div className="d-block d-md-none">
            {panelScroll?
              <button className="scroll-down-button" 
                onClick={handleScrollUp}
              >
                <FaCaretUp size={24} />
              </button>
              :
              <button className="scroll-down-button"
                // onClick={handleScrollDown}
              >
                <FaCaretDown size={24} />
              </button>
            }
            </div>
          </div>
        </div>
        <div className="Listmain d-flex justify-content-between flex-column">
          <div className={ panelScroll === true ? "ListAll ListAll-sm" : "ListAll"} 
            ref = {refPanel}
          >
            {panelScroll === true ?
            <div className="d-flex d-sm-none justify-items-end mx-3 mt-2">
              <FiX size={30} className="ms-auto" onClick={() => {setPanelScroll(false)}}/>
            </div> : null}
            <div className="list-child">
            {list.map((items, index) => {
              let intrest = items.type === false ? items?.profile?.interests[0] : ""
              return (
                <div
                ref={bottomEl}
                  className={
                    type === "user"
                      ? "userspanl d-flex mb-2"
                      : type === "event"
                        ? "userspanlEvents d-flex mb-2"
                        : type === "micro"
                          ? "userspanlBlogs d-flex mb-2"
                          : type === "business"
                            ? "userspanlBussiness d-flex mb-2"
                            : ""
                  }
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={
                    type === "user"
                      ? () => handleUsers(items)
                      : type === "event" ?
                        () => handleEvent(items)
                        : type === "micro" ?
                          () => handleMicro(items)
                          : type === "business"
                            ? () => handleUsers(items)
                            : null
                  }
                >
                <div className="pe-0 d-flex flex-wrap flex-md-nowrap align-items-center">
                  <img
                    src={
                      type === "user"
                        ? items?.profile_pic
                          ? items?.profile_pic
                          : "/img/upload-photo.png"
                        : type === "event"
                          ? items?.eventImage?items?.eventImage.includes("https://storage.googleapis.com/ibouge")=== true ?
                              items?.eventImage : "/img/upload-photo.png": "/img/upload-photo.png"
                          : type === "micro"
                            ? items?.microblog_img ? items?.microblog_img.includes("https://storage.googleapis.com/ibouge")=== true ?
                              items?.microblog_img : "/img/upload-photo.png": "/img/upload-photo.png"
                              : type === "business"
                                ? items?.profile_pic
                                  ? items?.profile_pic
                                  : "img/upload-photo.png"
                                : ""
                    }
                    className="rounded-circle image"
                    alt="user"
                  />
                  {type === "user" ?
                    items?.is_online === true ?
                      <img
                        src="/Images/contact-online.png"
                        className="contact-online"
                        height={9}
                        alt="dot sign"
                      /> :
                      <img
                        src="/Images/contact-idle.png"
                        className="contact-idle"
                        height={9}
                        alt="dot sign"
                      /> : type === "business" ?
                      items?.is_online === true ?
                        <img
                          src="/Images/contact-online.png"
                          className="contact-online"
                          height={9}
                          alt="dot sign"
                        /> :
                        <img
                          src="/Images/contact-idle.png"
                          className="contact-idle"
                          height={9}
                          alt="dot sign"
                        /> : ""
                  }
                  <div className="namees ps-3 ps-lg-3 pe-5">
                  <div className="user-name">
                    <span style={{fontSize:"12px" , fontWeight:"bold"}}>
                      {type === "user"
                        ? items?.fname
                        : type === "event"
                        ? items?.name 
                        : type === "micro"
                        ? items?.name
                        : items?.fname
                      }<> </>{type === "user"
                        ? items.lname  : ""
                      }
                     </span>
                    </div>

                    <h6 className="info">

                      {type === "user"
                        ? intrest
                        : type === "event"
                    ? moment(items.dateOfEvent).format('ddd MMM Do, YYYY')
                          : type === "micro"
                    ? moment(items?.created_date).format('ddd MMM Do, YYYY')
                            : items?.business_category}
                    </h6>
                          {/* ? `${date[0]}` + " " + `${date[1]}` + " " + `${date[2]}` + " " + `${date[3]}` */}
                            {/* ? `${Micro[0]}` + " " + `${Micro[1]}` + " " + `${Micro[2]}` + " " + `${Micro[3]}` */}
                    
                  </div>
                  </div>
                    <div
                      className="right d-flex align-items-center"
                      style={{
                        backgroundColor:
                          type === "user"
                            ? "#0079CB"
                            : type === "event"
                              ? "#984399"
                              : type === "micro"
                                ? "#E40090"
                                : type === "business"
                                  ? "#40B307"
                                  : "",
                      }}
                    >
                      <FaAngleRight size={14} className="faAngleRightArrow my-auto" />
                    </div>
                </div>
              );
            })}
            </div>
          </div>
          <div className="d-md-block d-none">
            {type === "event" ? (
              <button className="addEventBtn" onClick={() => isopen()}>
                <BsPlusLg className="BsPlusLg-event" color="white" size={14} />{" "}
                Add Event
              </button>
            ) : type === "micro" ? (
              <button className="addBlogBtn" onClick={() => isMicro()}>
                <BsPlusLg className="BsPlusLg-event" color="white" size={14} />{" "}
                Add Microblog
              </button>
            ) : null}
          </div>
        </div>
      </aside>
    </>
  );
};

export default UserPanel;