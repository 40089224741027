import React from 'react'
import { useSelector } from 'react-redux'

const DeactiveButtons = ({ setConfirmOpen }) => {
  const userData = useSelector((state) => state?.auth?.user?.user)

  return (
    <>
      <div className="s-buttons d-flex pb-3 mx-3 mt-3">
        <button
          className="btn fw-bold text-white shadow w-50  me-2 "
          type="button"
          data-bs-toggle="button"

          onClick={() => setConfirmOpen({
            type: true,
            status: "Deactive"
          })}
        >
          {userData?.is_deactivated ? "Activate Account" : "Deactivate Account"}
        </button>
        <button
          className="btn fw-bold text-white shadow w-50 ms-2"
          type="button"
          data-bs-toggle="button"
          onClick={() => setConfirmOpen({
            type: true,
            status: "Delete"
          })}
        >
          Delete Account
        </button>
      </div>
    </>
  )
}

export default DeactiveButtons
