import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/home.css";
import { Container, Dropdown, Nav, Navbar, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoMdMailOpen } from "react-icons/io";
import { MdNotifications } from "react-icons/md";
import { BsCircleFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  Server2,
  Socket,
} from "../../../redux/actions/auth/auth.actions";
import axios from "axios";
import SearchBox from "./SearchBox";
import NotificatioBox from "./NotificatioBox";
import MsgInbox from "./MsgInbox";
import { ChatBox } from "./ChatBox";
import RingTone from "../../../assets/ring.mp3"
import { StatusPopup } from "./StatusPopup";
import MicroblogActivities from "../AddMicroblogPopup/MicroblogActivities";

var data;
document.addEventListener("visibilitychange", (event) => {
  if (document.visibilityState === "visible") {
    const userId = {
      id: data?._id,
    };
    console.log(document.visibilityState,"document.visibilityState")
    Socket.emit("addUserID", userId);
  } else {
    const userId = {
      id: data?._id,
    };
    Socket.emit("Socket-disconnect", userId)
  }
});
const NavbarHome = ({ NavMaps }) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  data = userData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Text, setText] = useState("");
  const [SearchUsers, setSearchUsers] = useState([]);
  const [SearchToggle, setSearchToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleMsg, setToggleMsg] = useState(false);
  const [notifymessageicon, setNotifyMessageIcon] = useState(false);
  const [notifyicon, setNotifyIcon] = useState(false);
  const [notifyRing, setNotifyRing] = useState(false);
  const [MessageRing, setMessageRing] = useState(false);
  const [ChatData, setChatData] = useState([]);
  const [ChatBoxShow, setChatBoxShow] = useState(false);
  const [RoomId, setRoomId] = useState("");
  const [PopupStatus, setPopupStatus] = useState(false)
  const [MicroStatus, setMicroStatus] = useState(false)
  const [PopupData, setPopupData] = useState([])
  const [MicroData, setMicroData] = useState([])
  const prams = window.location.pathname;
  const ref = useRef(null);
  const notiRef = useRef(null);
  const wrapperRef = useRef(null);
  useEffect(() => {
    if(prams=="/home"){
      const callback = () => {
        NavMaps.flyTo({
          center: [userData.location.coordinates[0].coords.lng,
          userData.location.coordinates[0].coords.lat],
          essential: true,
          zoom: 10
        });
      }
      const Ref = wrapperRef.current
      Ref?.addEventListener('click', callback);
      return () => Ref?.removeEventListener('click', callback)
    }
  }, [NavMaps]) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (notiRef.current && !notiRef.current.contains(event.target)) {
        setToggle(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notiRef]);

  const handleClose = (e) => {
    logout2();
    dispatch(logout());
    const userId = {
      id: data?._id,
    };
    Socket.emit("Socket-disconnect", userId)
    navigate("/", { replace: true });
    localStorage.clear();
  };
  useEffect(() => {
    if (PopupStatus === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [PopupStatus])
  const logout2 = () => {
    var data = {
      id: userData._id,
    };
    var config = {
      method: "post",
      url: Server2 + `/users/logout`,
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config)
      .then((response) => {
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  const NormalUser = useSelector((state) => state?.auth?.user?.user);
  const GoogleUser = useSelector(
    (state) => state?.auth?.googleLogin?.user?.user
  );
  
  const onClick = () => {
    if (RoomId) {
      const data = {
        room: RoomId,
        id: userData?._id
      };
      Socket.emit("leave-room", data);
    }
    navigate("/home", {
      state: {
        item: [
          NormalUser.location.coordinates[0].coords.lng,
          NormalUser.location.coordinates[0].coords.lat,
        ],
      },
    });
  };
  useEffect(() => {
    Socket.on("connect", () => {
      console.log("connected");
    });
    const userId = {
      id: NormalUser?._id,
    };
    Socket.emit("addUserID", userId);
    // const intervel =  setInterval(() =>{
    // },[30000])
    // return () => clearInterval(intervel)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // notification Data Get Api
  useEffect(() => {
    var config = {
      method: "GET",
      url: Server2 + `/users/getNotifications?id=${userData?._id}`,
      headers: { "Content-Type": "application/json" },
    };
    axios(config).then((response) => {
      if (response.data.length > 0) {
        response.data.map((notify) => {
          if (notify.status === true) {
            setNotifyIcon(true)
            return("")
          } else {
            setNotifyIcon(false)
            return("")
          }
        })
      }
    })
      .catch((err) => {
        console.log(err, "err")
      });
  }, [toggle, PopupStatus]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    Socket.on("newmessage", (data) => {
      if(NormalUser?.notifications?.invitations_to_conversation){
      setNotifyMessageIcon(true);
      setMessageRing(true)
      }
    });
    Socket.on("newNotification", (data) => {
      if(!data.silenced){
        setNotifyRing(true)
      }
      setNotifyIcon(true);
    });
  }, [Socket]); // eslint-disable-line react-hooks/exhaustive-deps
  setTimeout(() => {
    setNotifyRing(false)
    setMessageRing(false);
  }, 3000);
  useEffect(() => {
    setSearchUsers([]);
    if (Text.length > 0) {
      var config = {
        method: "get",
        url: Server2 + `/users/globalSearch?id=${NormalUser?._id}&q=${Text}`,
      };
      axios(config)
        .then(function (response) {
          setSearchUsers(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [Text]); // eslint-disable-line react-hooks/exhaustive-deps

  const myProfile = () => {

    navigate("/setting", {
      state: {
        item: "1",
      },
    });
  };
  const Account = () => {
    navigate("/setting", {
      state: {
        item: "2",
      },
    });
  };
  const Privacy = () => {
    navigate("/setting", {
      state: {
        item: "3",
      },
    });
  };
  const Notifications = () => {
    navigate("/setting", {
      state: {
        item: "4",
      },
    });
  };

  return (
    <>
      <div className="m-3 mb-3">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg=""
          variant="dark"
          className="rounded-3 position-relative"
        >
          <Container fluid className="">
            <Navbar.Brand
              className="ps-1 ps-xxl-2 ps-xl-2 ps-lg-2 me-0 pt-2 d-flex align-items-start"
              style={{ cursor: "pointer" }}
              ref={wrapperRef}
            >
              <img
                src="/img/LogoNav.png"
                height={30}
                alt="ibouge"
                onClick={() => onClick()}
              />
              <span className="text-white">&#174;</span>
            </Navbar.Brand>

            <span className="border-start border-2 opacity-75 py-3 rounded-2 ms-4 d-none d-xxl-block d-xl-block d-lg-block"></span>



            {/*Navbar Icons*/}
            <Nav className="px-0 d-flex flex-row flex-fill order-2 order-xxl-4 order-xl-4 order-lg-4 order-md-3 flex-auto justify-content-end ">
              <div className="d-flex p-0 align-items-center gap-3">
                <div className="Fa-icon search-box" style={{ position: "relative" }}>
                  <button
                    className="btn-search"
                    onMouseEnter={() => {
                      setToggle(false);
                      setSearchToggle(false);
                      setToggleMsg(false);
                      ref.current.focus();
                    }}
                  >
                    <FaSearch size={19} color="white" className="srch" />
                  </button>
                  <input
                    type="text"
                    className="input-search"
                    ref={ref}
                    placeholder="Type to Search"
                    onChange={(e) => {
                      setText(e.target.value);
                      setSearchToggle(true);
                    }}
                    onClick={() => setSearchToggle(true)}
                  />
                  {SearchToggle && (
                    <SearchBox
                      SearchUsers={SearchUsers}
                      setSearchToggle={setSearchToggle}
                    />
                  )}
                </div>
                <div className="navicons-div">
                  <IoMdMailOpen
                    size={22}
                    color="white"
                    className="Fa-icon message"
                    style={{ cursor: "pointer" }}
                    onMouseEnter={() => {
                      
                      setToggleMsg(true);
                      setNotifyMessageIcon(false);
                      setToggle(false);
                      setSearchToggle(false);
                      // setChatBoxShow(false);
                    }}
                  />
                  {notifymessageicon && (
                    <>
                      <BsCircleFill
                        color="#FD708E"
                        className="notification-sign"
                        size={9}
                      />

                    </>
                  )}
                  {MessageRing && (
                    <iframe title="Message Ring" src={RingTone} allow="autoPlay" id="audio" style={{ display: "none" }}></iframe>
                  )}
                  {toggleMsg && (
                    <MsgInbox
                      setToggleMsg={setToggleMsg}
                      setChatBoxShow={setChatBoxShow}
                      Room={RoomId}
                      setChatData={(item) => setChatData(item)}
                      setRoomId={(item) => setRoomId(item)}
                    />
                  )}
                  {ChatBoxShow && (
                    <div
                      style={{
                        position: "fixed",
                        bottom: "3rem",
                        zIndex: "2000",
                      }}
                    >
                      <ChatBox
                        Data={ChatData}
                        Room={RoomId}
                        setChatBox={() => setChatBoxShow(false)}
                      />
                    </div>
                  )}
                </div>

                <div className="navicons-div" ref={notiRef}>
                  <MdNotifications
                    size={22}
                    color="white"
                    className="Fa-icon notify"
                    onMouseEnter={() => {
                      setToggle(true);
                      setToggleMsg(false);
                      setSearchToggle(false);
                      // setNotifyIcon(false);
                    }}
                  />

                  {toggle && <NotificatioBox setToggle={setToggle} setPopupStatus={setPopupStatus} setMicroStatus={setMicroStatus} setPopupData={(e) => setPopupData(e)} setMicroData={(e) => setMicroData(e)} setNotifyIcon={(e) => setNotifyIcon(e)} />}

                  {notifyicon && (
                    <>
                      <BsCircleFill
                        color="#FD708E"
                        className="notification-sign"
                        size={9}
                      />
                    </>
                  )}
                  {notifyRing && (
                    <iframe title="NotiRing" src={RingTone} allow="autoPlay" id="audio" style={{ display: "none" }}></iframe>
                  )}
                  {/* <img
                    src="/Images/contact-online.png"
                    className="notification-sign"
                    height={7}
                    alt="dot sign"
                  /> */}
                </div>

                <span className="border-start border-2 opacity-75 py-3 rounded-2 mx-3 d-none d-xxl-block d-xl-block d-lg-block"></span>
              </div>

              <Dropdown className="NavNameImg d-none d-sm-block">
                <Dropdown.Toggle
                  className="border-0 bg-transparent togglebtn d-flex align-items-center p-0 px-1 px-sm-2 px-md-3 "
                  variant=""
                  id="dropdown-basic"
                  aria-expanded="false"
                >
                  <div className="userName me-2 Fa-icon d-none d-sm-block">
                    {NormalUser?.fname} {NormalUser?.lname}
                    {GoogleUser?.fname} {GoogleUser?.lname}
                  </div>
                  <img
                    src={
                         NormalUser?.profile_pic
                         ? NormalUser?.profile_pic
                         : "/img/upload-photo.png"
                    }
                    height={35}
                    className="rounded-circle image-user p-0"
                    alt="ibouge"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu">
                  <Dropdown.Item
                    className="dropdown-menu-item"
                    eventKey="9"
                    onClick={() => myProfile()}
                  >
                    My Profile
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="dropdown-menu-item"
                    eventKey="9"
                    onClick={() => navigate("/mapHigh") }
                  >
                    High Map
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    className="dropdown-menu-item"
                    eventKey="9"
                    onClick={() => Account()}
                  >
                    Account
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-menu-item"
                    eventKey="9"
                    onClick={() => Privacy()}
                  >
                    Privacy
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-menu-item"
                    eventKey="9"
                    onClick={() => Notifications()}
                  >
                    Notifications
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-menu-item "
                    style={{ borderTop: "1px solid grey" }}
                    onClick={handleClose}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>

            {/* Navbar Collapse Button */}
            <Navbar.Toggle
              id="basic-navbar-nav"
              aria-controls="navbarScroll"
              data-bs-target="#navbarScroll"
              className="mx-2 order-3 order-xxl-4 order-xl-4 order-lg-4"
            />

            {/*navbar links*/}
            <Navbar.Collapse className="order-4 order-xxl-2 order-xl-2 order-lg-2">
              <Nav className="nav-navlinks p-1">
                <div className="NavNameImg d-flex justify-content-center d-block d-sm-none">
                  <div className="border-0 text-center togglebtn py-3 p-0 w-100">
                    <img
                      src={
                        NormalUser
                          ? NormalUser?.profile_pic
                          ? NormalUser?.profile_pic
                          : "/img/upload-photo.png"
                          : null
                      }
                      height={35}
                      className="rounded-circle image-user-drawer p-0"
                      alt="ibouge"
                    />

                    <div className="userName w-100 Fa-icon pt-3">
                      <p className="text-white text-center fs-4 mb-0">
                        {NormalUser?.fname} {NormalUser?.lname}
                        {GoogleUser?.fname} {GoogleUser?.lname}
                      </p>
                    </div>
                  </div>
                </div>

                <NavLink
                  onClick={() => {
                    if (RoomId) {
                      const data = {
                        room: RoomId,
                        id: userData?._id
                      };
                      Socket.emit("leave-room", data);
                    }
                    navigate("/dashboard")
                  }}
                  className="ms-3"
                  eventKey="1"
                  // as={Link}
                  // to="/dashboard"
                  style={{
                    borderBottom:
                      prams === "/dashboard" ? "3px solid #FEA115" : "",
                    fontWeight: prams === "/dashboard" ? "500" : "",
                  }}
                >
                  My Dashboard
                </NavLink>

                <NavLink
                  onClick={() => {
                    if (RoomId) {
                      const data = {
                        room: RoomId,
                        id: userData?._id
                      };
                      Socket.emit("leave-room", data);
                    }
                    navigate("/mapOverview")
                  }}
                  className="ms-3"
                  eventKey="2"
                  // as={Link}
                  // to="/mapOverview"
                  style={{
                    borderBottom:
                      prams === "/mapOverview" ? "3px solid #FEA115" : "",
                  }}
                >
                  Map Overview
                </NavLink>

                <NavLink
                  onClick={() => {
                    if (RoomId) {
                      const data = {
                        room: RoomId,
                        id: userData?._id
                      };
                      Socket.emit("leave-room", data);
                    }
                    navigate(`/${userData.type?"business":"personal"}/${userData?._id}`)
                    console.log('check it ')
                  }}
                  className="ms-3"
                  eventKey="3"
                  style={{
                    borderBottom:
                      prams === `/${userData.type?"business":"personal"}/${userData?._id}` ? "3px solid #FEA115" : "",
                  }}
                // as={Link}
                // to=`/${userData.type?"business":"personal"}/${userData?._id}`
                >
                  {NormalUser
                    ? NormalUser.type === false
                      ? "My Profile"
                      : "Business Profile"
                    : null}
                </NavLink>

               {(userData?.role === "admin"|| userData?.role === "super-admin") ? <NavLink
                  onClick={() => {
                    // if (RoomId) {
                    //   const data = {
                    //     room: RoomId,
                    //     id: userData?._id
                    //   };
                    //   Socket.emit("leave-room", data);
                    // }
                    navigate("/admin")
                  }}
                  className="ms-3"
                  eventKey="4"
                  // as={Link}
                  // to="/mapOverview"
                  style={{
                    borderBottom:
                      prams === "/admin" ? "3px solid #FEA115" : "",
                  }}
                >
                  Admin
                </NavLink>: null} 
               
                <NavLink
                  className="ms-3 d-block d-sm-none"
                  eventKey="9"
                  onClick={() => myProfile()}
                >
                  Settings
                </NavLink>

                <NavLink
                  className="ms-3 d-block d-sm-none"
                  eventKey="9"
                  onClick={handleClose}
                >
                  Logout
                </NavLink>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {PopupStatus && <StatusPopup
        setPopupStatus={setPopupStatus}
        Items={PopupData}
      />}
      {
        MicroStatus &&
        <div className="main-event-bg-dash">
          <MicroblogActivities MicroData={MicroData} ActivitiesClose={(e) => setMicroStatus(e)} />
        </div>
      }

    </>
  );
};

export default NavbarHome;
