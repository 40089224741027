import React from "react";
import { Container, Nav, Navbar, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/styles/home.css";
import "../../../assets/styles/Footer/contactUs.css";
import { useSelector } from "react-redux";

const FooterNav = ({statusL, from}) => {
  // console.log(statusL, ": Loggedin Status")
  // console.log(from, "top navbar accessed from")
  const userData = useSelector((state) => state?.auth?.user?.user);
  let navigate = useNavigate();
  return (
    <div className="m-3 mb-3">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg=""
        variant="dark"
        className="rounded-3 position-relative pb-2"
      >
        <Container fluid>
          <Navbar.Brand className="pe-4 me-3" style={{ cursor: "pointer" }} onClick={() => {
            if(statusL === "LoggedIn"){
              navigate('/home', { 
                state: { 
                  item: [
                    userData.location.coordinates[0].coords.lng,
                    userData.location.coordinates[0].coords.lat,
                  ],
                },
              });
            }
            else{
              navigate("/login");
            }
              }}>
            <img src="/img/LogoNav.png" height={30} alt="ibouge" />
            <span style={{color: "white", position: "relative", top: "-8px", fontWeight:"300", fontSize: "0.8rem"}}>&#174;</span>
          </Navbar.Brand>
          <Navbar.Toggle
            id="basic-navbar-nav"
            aria-controls="navbarScroll"
            data-bs-target="#navbarScroll"
            className="me-3"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="Navfooter gap-2">
              {from === "About" ? "" :
                <NavLink className="" eventKey="1" as={Link} to="/">
                  About
                </NavLink>
              }
              <NavLink className="" eventKey="2" as={Link} to="/contact">
                Contact
              </NavLink>
              <NavLink className="" eventKey="3" as={Link} to="/mission.html">
                Mission
              </NavLink>
              <NavLink className="" eventKey="4"  as={Link} to="/Demo">
                Demo
              </NavLink>
              {from === "About" || from === "Contact" || from === "Blog" || from === "Press" || from === "Mission" ? "" :
              <>
              <NavLink className="" eventKey="5" as={Link} to="/press">
                Press
              </NavLink>
              <NavLink className="" eventKey="6" as={Link} to="/blog.html">
                Blog
              </NavLink>
              <NavLink className="" eventKey="7" as={Link} to="/privacy">
                Privacy
              </NavLink>
              <NavLink className="" eventKey="8" as={Link} to="/terms">
                Terms of Service
              </NavLink>
              </>
              }
            </Nav>
            <button
              className="signup_button_about text-white ms-auto border-0 shadow-none"
              onClick={() => {
                navigate("/signup");
              }}
            >
              Sign Up
            </button>
            <button
              className="loginbtn text-white ms-2 border-0"
              style={{backgroundColor: "#0180BF"}}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default FooterNav;