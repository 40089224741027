import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Server2, Socket } from '../../../redux/actions/auth/auth.actions';
import { FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const InviteEventPopup = ({ item, handleClose }) => {
  const userData = useSelector((state) => state?.auth?.user?.user)
  const [Text, setText] = useState("");
  const [SearchUsers, setSearchUsers] = useState([]);
  // const [SearchToggle, setSearchToggle] = useState(false);
  const [Error, setError] = useState("");
  useEffect(() => {
    if (Text.length > 0) {
      var config = {
        method: "get",
        url: Server2 + `/users/globalSearch?id=${userData?._id}&q=${Text}`,
      };
      axios(config)
        .then(function (response) {
          setSearchUsers([]);
          setSearchUsers(response.data);
          console.log(response.data, "response.data");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [Text]); // eslint-disable-line react-hooks/exhaustive-deps

  const invite = (user) => {

    var notify = user?.notifications?.new_events

    var data = JSON.stringify({
      id: item?._id,
      users: user?._id,
      myId: userData._id,
      notify: notify
    });
    var config = {
      method: "POST",
      url: Server2 + "/events/invite-friend",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then((response) => {
        console.log(response.data)

        if(notify){
          const data = {
            to: user?._id
          }
          Socket.emit("event-invite", data)
        }

        setError(`${user.fname} ${user.lname} is successfully invited`)
        setText("")
        setSearchUsers([])
        setTimeout(() => {
          // setSearchToggle(false);
          handleClose()
          setError("")
        }, 3000)

      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  return (
    <div className="popup-box">
      <div
        className="box"
        style={{
          marginTop: "calc(100vh - 67vh - 20px)",
          padding:  "0px 0px 20px 0px",
        }}
      >
        <div className="notibox-Header text-white rounded-1">
          <span className="py-2 fw-bolder fs-6 px-4">Invite Users</span>
          <FiX
            className="fs-4 text-white me-3"
            role="button"
            onClick={() => {
              handleClose()
              setSearchUsers([])
              // setSearchToggle(false);
            }}
          />
        </div>
        {Error.length > 0 ? <h5 className="text-success text-center mt-2" style={{ fontSize: "14px" }}>{Error}</h5> : null}
        <div className="d-flex flex-column-reverse">
          <div style={{ width: "95%", marginLeft: "2%" }}>
            <div className="">

              <input
                style={{ backgroundColor: "#FFF", margin: "3px 0px", borderBottom: "1px solid #ECECEC" }}
                type="text"
                placeholder="Type Here To Invite User"
                className="form-control ps-3 py-1"
                onChange={(e) => setText(e.target.value)}
                onClick={() => {
                  // setSearchToggle(true);
                }}
              />
            </div>
              <div className="" style={{ boxShadow: "0px 10px 64px rgba(21, 48, 89, 0.3)" }}>
                <div className="notifications pt-2">
                  {SearchUsers.length < 0
                    ? ""
                    : SearchUsers.map((noti, index) => {
                      const intrest = noti.profile.interests[0]
                        ? noti.profile.interests[0].length > 20
                          ? noti.profile.interests[0].substring(0, 20) + "..."
                          : noti.profile.interests[0]
                        : "";
                      return (
                        <div key={index}>
                          {noti?._id !== userData?._id ? (
                            <div className="d-flex justify-content-between bg-light">
                              <div
                                className="d-flex align-items-center mx-3 py-2 rounded-3 ps-3  mb-1"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="pe-3">
                                  <img
                                    src={
                                      noti.profile_pic
                                        ? noti.profile_pic
                                        : "/img/upload-photo.png"
                                    }
                                    height={40}
                                    className="rounded-circle image-user"
                                    alt="ibouge"
                                  />
                                </div>
                                <div className="namees text-dark">
                                  <span className="fw-bold">
                                    {noti.fname} {noti.lname}
                                  </span>
                                  <h6 className="info">{intrest}</h6>
                                </div>
                              </div>
                              <button
                                className="btn my-4 mx-2 text-white"
                                style={{
                                  backgroundColor: "#fea115",
                                  fontSize: "12px",
                                }}
                                onClick={() => invite(noti)}
                              >

                                Invite
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
               

          </div>
        </div>

      </div>
    </div>
  )
}

export default InviteEventPopup
