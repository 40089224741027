import React, { useRef, useState } from "react";
import { BsCheckCircle, BsPlusLg } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  SetProfileBusiness,
  SetProfilePersonal,
} from "../../../redux/actions/auth/auth.actions";
import InputTags from '@semcore/ui/input-tags';
import Tooltip from "@semcore/tooltip";


function ProfileSetting() {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const dispatch = useDispatch();
  const [AboutMe, SetAboutME] = useState(
    userData?.type === false ? userData?.profile?.about_me : ""
  );
  const [Interests, SetInterests] = useState(
    userData?.type === false ? userData?.profile?.interests : ""
  );
  const [value, updateValue] = useState("");
  const [value2, updateValue2] = useState("");
  const [value3, updateValue3] = useState("");
  const [Language, SetLanguage] = useState(
    userData?.type === false ? userData?.profile?.languages : ""
  );

  const [Summary, SetSummary] = useState(
    userData?.type === true ? userData?.business_info?.business_summary : ""
  );
  const [Contact, SetContact] = useState(
    userData?.type === true ? userData?.business_info?.phone_number : ""
  );
  const [CountryCode, setCountryCode] = useState(
    userData?.type === true ? userData?.business_info?.phone_number : ""
  );
  const [URL, SetURL] = useState(
    userData?.type === true ? userData?.business_info?.address : ""
  );
  const [Offers, SetOffers] = useState(
    userData?.type === true ? userData?.business_info?.about_company : ""
  );
  const [popup, setpopup] = useState(false)
  const ref = useRef(null);
  const reflng = useRef(null);
  // console.log(reflng,"reflng")

  const handleOnChange = (value, data) => {
    SetContact(value);
    setCountryCode(data.dialCode);
  };
  const PayloadProfile = {
    email: "",
    about_me: "",
    interests: "",
    languages: "",
  };
  const PayloadProfileFunc = () => {
    PayloadProfile.email = userData?.email;
    PayloadProfile.about_me = AboutMe;
    PayloadProfile.interests = Interests;
    PayloadProfile.languages = Language;
  };
  const ApiUsers = () => {
    PayloadProfileFunc();
    dispatch(SetProfilePersonal(PayloadProfile)).then((res) => {
      if (res.data) {
        setpopup(true)
        setTimeout(() => {
          setpopup(false)
        }, 3000);
      }
    })
  };
  // Bussiness API START
  const PayloadProfileB = {
    email: "",
    business_summary: "",
    phone_number: "",
    country_code: "",
    address: "",
    about_company: "",
  };
  const PayloadProfilBFunc = () => {
    PayloadProfileB.email = userData?.email;
    PayloadProfileB.business_summary = Summary;
    PayloadProfileB.phone_number = Contact;
    PayloadProfileB.country_code = CountryCode;
    PayloadProfileB.address = URL;
    PayloadProfileB.about_company = Offers;
  };

  const ApiBussiness = () => {
    PayloadProfilBFunc();
    dispatch(SetProfileBusiness(PayloadProfileB)).then((res) => {
      if (res.data) {
        setpopup(true)
        setTimeout(() => {
          setpopup(false)
        }, 3000);
      }
    })
  };
  // Intersts Func Start Input Tags
  const handleAppendTags = (newTags) => {
    SetInterests((Interests) => [...Interests, ...newTags]);
    updateValue("");
  };
  const handleRemoveTag = () => {
    if (Interests.length === 0) return;
    SetInterests(Interests.slice(0, -1));
    updateValue(Interests.slice(-1)[0] + ` ${value}`);
  };
  const handleCloseTag = (e) => {
    e.preventDefault();
  };

  const handleEditTag = (e) => {
    const { dataset } = e.currentTarget;
    let allTags = [...Interests];
    if (value) {
      allTags = [...allTags, value];
    }
    SetInterests(allTags.filter((tag, ind) => ind !== Number(dataset.id)));
    if (!e.defaultPrevented) {
      updateValue(Interests[dataset.id]);
    }
    return false;
  };

  const handleBlurInput = (e) => {
    const { value } = e.currentTarget;
    if (value) handleAppendTags([value]);
  };
  const handleClickInt = () => {
    handleBlurInput()
  };
  // Language Func Start
  const handleAppendLangs = (newTags) => {
    SetLanguage((Language) => [...Language, ...newTags]);
    updateValue2("");
  };
  const handleRemoveLangs = () => {
    if (Language.length === 0) return;
    SetLanguage(Language.slice(0, -1));
    updateValue2(Language.slice(-1)[0] + ` ${value2}`);
  };
  const handleCloseLangs = (e) => {
    e.preventDefault();
  };

  const handleEditLangs = (e) => {
    const { dataset } = e.currentTarget;
    let allTags = [...Language];
    if (value2) {
      allTags = [...allTags, value2];
    }
    SetLanguage(allTags.filter((tag, ind) => ind !== Number(dataset.id)));
    if (!e.defaultPrevented) {
      updateValue2(Language[dataset.id]);
    }
    return false;
  };

  const handleBlurInputLangs = (e) => {
    const { value } = e.currentTarget;
    if (value) handleAppendLangs([value]);
  }; 
  const handleClicklang = () => {
    handleBlurInputLangs()
  };


  // Business Offers Func Input Tags

  const handleAppendOff = (newTags) => {
    SetOffers((Offers) => [...Offers, ...newTags]);
    updateValue3("");
  };
  const handleRemoveOff = () => {
    if (Offers.length === 0) return;
    SetOffers(Offers.slice(0, -1));
    updateValue3(Offers.slice(-1)[0] + ` ${value3}`);
  };
  const handleCloseOff = (e) => {
    e.preventDefault();
  };

  const handleEditOff = (e) => {
    const { dataset } = e.currentTarget;
    let allTags = [...Offers];
    if (value3) {
      allTags = [...allTags, value3];
    }
    SetOffers(allTags.filter((tag, ind) => ind !== Number(dataset.id)));
    if (!e.defaultPrevented) {
      updateValue3(Offers[dataset.id]);
    }
    return false;
  };

  const handleBlurInputOff = (e) => {
    const { value } = e.currentTarget;
    if (value) handleAppendOff([value]);
  }; 
  const handleClickOff = () => {
    handleBlurInputOff()
  };

  return (
    <>
      <div className="">
        <p className="ps-title text-center text-dark">My Profile</p>
        <div className="rounded-3 shadow mx-3 mb-3">
          <div className="mx-3">
            <span className="">
              {userData?.type === false ? "About Me" : "Business Summary"}
            </span>
            {userData?.type === false ? (
              <textarea
                value={AboutMe}
                className="w-100 aboutfield form-control"
                placeholder="Tell us a little bit about yourself"
                rows={3}
                onChange={(e) => SetAboutME(e.target.value)}
              />
            ) : (
              <textarea
                value={Summary}
                className="w-100 aboutfield form-control"
                placeholder="Tell us a little bit about Business"
                rows={3}
                onChange={(e) => SetSummary(e.target.value)}
              />
            )}
          </div>
          <div className="mx-3 ">
            <span className="pt-0 ">
              {userData?.type === false ? "My Interests" : "Business Contact"}
            </span>
            {userData?.type === false ? (
              <>
                <div id="inter">
                  <InputTags size="l" onAppend={handleAppendTags} onRemove={handleRemoveTag}>
                    {Interests.map((tag, idx) => (
                      <Tooltip key={idx}>
                        <Tooltip.Trigger
                          tag={InputTags.Tag}
                          theme="primary"
                          data-id={idx}
                          onClick={handleEditTag}
                        >
                          <InputTags.Tag.Text>{tag}</InputTags.Tag.Text>
                          <InputTags.Tag.Close onClick={handleCloseTag} />
                        </Tooltip.Trigger>
                        {/* <Tooltip.Popper>tag</Tooltip.Popper> */}
                      </Tooltip>
                    ))}
                    <InputTags.Value
                      ref={ref}
                      value={value}
                      onChange={updateValue}
                      onBlur={handleBlurInput}
                    />
                  </InputTags>
                </div>
                <div className="btnInterests">
                  <p
                    className="textInterests"
                    onClick={handleClickInt}
                  >
                    Add Interests
                    <BsPlusLg
                      className="BsPlusLg-icon"
                      color="white"
                      size={14}
                    />
                  </p>
                </div>
              </>
            ) : (
              <PhoneInput
                style={{ margin: "0px" }}
                value={Contact}
                name="Phone"
                specialLabel={""}
                country={"us"}
                className="Phoneinput"
                inputClass={""}
                placeholder="201-555-0123"
                onChange={handleOnChange}
              />
            )}
          </div>
          {userData?.type === true ? (
            <div className="">
              <span className="mx-3">Company Website URL</span>
              <div className="d-flex mt-1 ">
                <input
                  type="url"
                  value={URL}
                  placeholder=""
                  style={{ backgroundColor: "#ECECEC" }}
                  className="form-control mx-3"
                  aria-label="Search"
                  onChange={(e) => SetURL(e.target.value)}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {userData?.type === false ? (
            <div className="mx-3 pb-2">
              <span className="pt-0 ">My Languages</span>
              <InputTags size="l" onAppend={handleAppendLangs} onRemove={handleRemoveLangs}>
                    {Language.map((tag, idx) => (
                      <Tooltip key={idx}>
                        <Tooltip.Trigger
                          tag={InputTags.Tag}
                          theme="primary"
                          data-id={idx}
                          onClick={handleEditLangs}
                        >
                          <InputTags.Tag.Text>{tag}</InputTags.Tag.Text>
                          <InputTags.Tag.Close onClick={handleCloseLangs} />
                        </Tooltip.Trigger>
                      </Tooltip>
                    ))}
                    <InputTags.Value
                      ref={reflng}
                      value={value2}
                      onChange={updateValue2}
                      onBlur={handleBlurInputLangs}
                    />
                  </InputTags>
              <div className="btnInterests">
                <p
                  className="textInterests"
                  onClick={handleClicklang}
                >
                  Add Languages
                  <BsPlusLg className="BsPlusLg-icon" color="white" size={14} />
                </p>
              </div>
            </div>
          ) : (
            <div className="mx-3 pb-2">
              <span className="pt-0 ">What does your Business offers?</span>
              <InputTags size="l" onAppend={handleAppendOff} onRemove={handleRemoveOff}>
                    {Offers.map((tag, idx) => (
                      <Tooltip key={idx}>
                        <Tooltip.Trigger
                          tag={InputTags.Tag}
                          theme="primary"
                          data-id={idx}
                          onClick={handleEditOff}
                        >
                          <InputTags.Tag.Text>{tag}</InputTags.Tag.Text>
                          <InputTags.Tag.Close onClick={handleCloseOff} />
                        </Tooltip.Trigger>
                      </Tooltip>
                    ))}
                    <InputTags.Value
                      value={value3}
                      onChange={updateValue3}
                      onBlur={handleBlurInputOff}
                    />
                  </InputTags>
              <div className="btnInterests">
                <p
                  className="textInterests"
                  onClick={handleClickOff}
                >
                  Add Product or Service
                  <BsPlusLg className="BsPlusLg-icon" color="white" size={14} />
                </p>
              </div>
            </div>
          )}
        </div>
        {popup === true ?
          <div style={{ backgroundColor: "#d2f8d2", margin: "10px 20px", padding: "6px 15%", borderRadius: "8px" }}>
            <BsCheckCircle style={{ marginRight: "15px" }} color="#0b4a0b" size={18} />
            <span style={{ fontSize: "13px" }}>Profile has been updated</span>
          </div> : null
        }
        <div className="s-buttons d-flex pb-3 mx-3">
          <button
            className="btn fs-6 fw-bold shadow w-50 btn-lg me-2"
            type="button"
            data-bs-toggle="button"
            onClick={
              userData?.type === false ? () => ApiUsers() : () => ApiBussiness()
            }
          >
            Save
          </button>
          <button
            className="btn fs-6 fw-bold shadow w-50 btn-lg ms-2"
            type="button"
            data-bs-toggle="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileSetting;
