import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetNotifications } from "../../../redux/actions/auth/auth.actions";
import { BsCheckCircle } from "react-icons/bs";

const Notifications = () => {
const userData = useSelector((state) => state?.auth?.user?.user)
  const dispatch = useDispatch();
  const [Message, setMessage] = useState(userData?.notifications?.new_messages);
  const [Event, setEvent] = useState(userData?.notifications?.new_events);
  const [Request, setRequest] = useState(userData?.notifications?.friend_requests);
  const [Conversation, setConversation] = useState(userData?.notifications?.invitations_to_conversation);
  const [popup, setpopup] = useState(false)
  // Bussiness API START
  const PayloadNoti = {
    email: "",
    new_messages: "",
    new_events: "",
    friend_requests: "",
    invitations_to_conversation: "",
  };
  const PayloadNotifications = () => {
    PayloadNoti.email = userData.email;
    PayloadNoti.new_messages = Message;
    PayloadNoti.new_events = Event;
    PayloadNoti.friend_requests = Request;
    PayloadNoti.invitations_to_conversation = Conversation;
  };

  const Noti = () => {
    PayloadNotifications();
    dispatch(SetNotifications(PayloadNoti)).then((res) =>{
      if(res.data){
        setpopup(true)
        setTimeout(() =>{
          setpopup(false)
        },3000)
      }
    });

  };

  return (
    <div>
      <p className="ps-title text-center text-dark fs-5">Notifications</p>
      <div className=" m-3 rounded-3 shadow">
        <div className="m-3 pt-3">
          <div className="d-flex justify-content-between align-items-center rounded-3">
            <label className="privacy-label">Wall notifications</label>
            <label className="switch ">
              <input
                type="checkbox"
                checked={Message}
                onChange={(e) => setMessage(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex pt-3 justify-content-between align-items-center rounded-3">
            <label className="privacy-label">Invitations to events</label>
            <label className="switch ">
              <input
                type="checkbox"
                checked={Event}
                onChange={(e) => setEvent(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex pt-3 justify-content-between align-items-center rounded-3">
            <label className="privacy-label">Friend request notifications</label>
            <label className="switch ">
              <input
                type="checkbox"
                checked={Request}
                onChange={(e) => setRequest(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
          <div className="d-flex pb-3 pt-3 justify-content-between align-items-center rounded-3">
            <label className="privacy-label">
            Message notifications
            </label>
            <label className="switch ">
              <input
                type="checkbox"
                checked={Conversation}
                onChange={(e) => setConversation(e.target.checked)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>
      { popup === true ?
          <div style={{backgroundColor:"#d2f8d2" , margin:"10px 20px" , padding:"6px 13%" ,  borderRadius:"8px"}}>
          <BsCheckCircle style={{marginRight:"15px"}} color="#0b4a0b" size={18} />
          <span style={{fontSize:"13px"}}>Notifications has been updated</span>
          </div>:null
        }
      <div className="s-buttons d-flex pb-3 mx-3">
        <button
          className="btn fs-6 fw-bold shadow w-50 btn-lg me-2"
          type="button"
          data-bs-toggle="button"
          onClick={() => Noti()}
        >
          Save
        </button>
        <button
          className="btn fs-6 fw-bold shadow w-50 btn-lg ms-2"
          type="button"
          data-bs-toggle="button"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Notifications;
