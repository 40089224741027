import React from 'react'
import "./Tos.css"

const Tos = () => {
  return (
    <div className="w-100 bg-white pb-4">
      <div style={{ background: "white", maxWidth: 850 }}>
        <div style={{ background: "white" }}>


          <p
            className="s1 fw-bolder"
            style={{
              paddingTop: "40px",
              textIndent: "0pt",
              lineHeight: "12pt",
              textAlign: "center",
              paddingBottom: '3pt'
            }}
          >
            iBouge Terms of Service
          </p>
          <p
            className="s2 text-black" 
            style={{ textIndent: "0pt", lineHeight: "12pt", textAlign: "center" }}
          >
            Last Updated January 25th, 2024
          </p>
          <p
            className="s2"
            style={{
              paddingTop: "11pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            Welcome to iBouge! iBouge, Inc (“<u>iBouge</u>,”), owns and operates
            iBouge.com (the “<u>Website</u>”) and these Terms of Service govern your use
            or access of the Website and any applications, mobile apps, affiliated
            websites or other platforms through which we provide our services
            (collectively, the “<u>Platform</u>”). iBouge provides the Platform to
            facilitate geo- networking, communication, and commerce among our users.
          </p>
          <p
            className="s2"
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Geo-Networking allows individuals and businesses to network based on
            location. If you live in Sydney, Australia and are looking to meet people
            near you, or want to support businesses from a particular town in Tanzania,
            iBouge lets you do this. You may sign up on the platform for a personal
            account (such person an “<u>Individual</u>”) or a business account (such
            person a “<u>Business</u>”).
          </p>
          <p
            className="s2"
            style={{
              paddingTop: "11pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            Individuals who use the Platform are “Users”, and “you” and “your” refer to
            Users. If you access the Platform or accept these Terms on behalf of a
            company or other legal entity, you represent and warrant that you have the
            authority to bind that legal entity and, in such event, “you” and “your”
            will refer to that legal entity. “We”, “us”, or “our” refer to iBouge. In
            addition, in these Terms, unless the context requires otherwise, words in
            one gender include all genders and words in the singular include the plural
            and vice- versa. Users will be able to interact with each other in a variety
            of ways on the Platform, including purchasing goods from some Businesses who
            have set up their shops on the Platform.
          </p>
          <p
            className="s2"
            style={{
              paddingTop: "11pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            By using or visiting the Platform, or receiving any services therefrom, you
            agree to comply with and be legally bound by the terms, conditions and
            restrictions of these Terms of Service (the “<u>Terms</u>”). Please read
            these Terms and our Privacy Policy (located at 14241 NE Woodinville Duvall
            Rd, PO Box 153, Woodinville, WA 98072 and incorporated by reference into
            these Terms) carefully before using the Platform. If you do not agree to
            these Terms, including our Privacy Policy, you have no right to obtain
            information from or otherwise continue using the Platform. Please note that
            iBouge is merely a technology company facilitating social networking and
            commerce. iBouge is not responsible for your interactions with other Users,
            whether they are Individuals or Businesses, whether with respect to your
            social or business interactions with them, payment, delivery of goods or
            services, or otherwise. We do not verify any User’s, whether an Individual
            or Business, compliance with applicable laws or regulation.
          </p>
          <p
            className="s2"
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Furthermore, we have no responsibility or liability for acts by any
            third-parties (including other Users), other than as stated herein, nor
            control over the conduct of any third-party using the Platform. iBouge
            disclaims all liability with respect to the action or inaction of any
            third-parties, including Businesses, to the maximum extent permitted by law.
          </p>
          <p style={{ paddingTop: "11pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s2"
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            iBouge reserves the right to modify these Terms or its policies relating to
            the Service or Platform at any time, effective upon posting of an updated
            version of these Terms on the Platform. You are responsible for regularly
            reviewing these Terms. Continued use of the Platform after any such changes
            shall constitute your consent to such changes.
          </p>
          <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT
            INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. IF YOU
            RESIDE IN THE UNITED STATES, PLEASE NOTE: SECTION 23 OF THESE TERMS OF
            SERVICE CONTAINS AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER. IT AFFECTS
            HOW DISPUTES WITH LONCH ARE RESOLVED. BY ACCEPTING THESE TERMS OF SERVICE,
            YOU AGREE TO BE BOUND BY THIS ARBITRATION PROVISION. PLEASE READ IT
            CAREFULLY. IF YOU DO NOT WISH TO BE SUBJECT TO ARBITRATION, YOU MAY OPT OUT
            OF THE ARBITRATION PROVISION BY FOLLOWING THE INSTRUCTIONS PROVIDED IN THE
            ARBITRATION PROVISION BELOW.
          </p>
          <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l1">
            <li data-list-text={1}>
              <h5
                style={{ paddingLeft: "15pt", textIndent: "-10pt", textAlign: "left" }}
              >
                Applicability &amp; Acceptance of These Terms of Use
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                By viewing, using, accessing, browsing, creating an account with,
                submitting content to, accessing any services or features available or
                enabled by, or submitting any content or material via Platform, you
                agree to these Terms as a binding legal agreement between you and us.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={2}>
              <h5
                style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
              >
                User License
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                iBouge grants you permission (which may be revoked at any time for any
                reason or no reason) to view, use and access the Platform and to
                download, email, share via social networking or print (“<u>Share</u>”)
                individual pages from the Platform in accordance with these Terms,
                solely for your own personal, non- commercial use, provided you do not
                remove any trademark, copyright or other notice contained on such pages.
                If you are a Business, you may utilize the Platform to offer products or
                services for sale to other Users provided you comply with applicable
                law, and may Share content from, or links to, the Platform for the
                purposes of marketing your business’s presence on the Platform. No other
                use is permitted. You may not, for example, incorporate the information,
                content, or other material in any database, compilation, archive or
                cache. You may not modify, copy, distribute, re-publish, transmit,
                display, perform, reproduce, publish, reuse, resell, license, create
                derivative works from, transfer, or sell any information, content,
                material, software, products or services obtained from us or other
                Users, except as specifically noted above. Except as specifically
                authorized by iBouge, you may not deep-link to the Platform for any
                purpose or access the Platform manually or with any robot, spider, web
                crawler, extraction software, automated process or device to scrape,
                copy, or monitor any portion of the Platform or any information,
                content, or material on the Platform. We reserve all of our statutory
                and common law rights against any person or entity who violates this
                paragraph. You may not link or frame to any pages of the Platform or any
                content contained therein, whether in whole or in part, except as
                provided above or with the prior written consent of iBouge. Any rights
                not expressly granted herein are reserved.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={3}>
              <h5
                style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
              >
                User Conduct
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                You agree that your use of the Platform and or services on the Platform
                is subject to all applicable local, state and federal laws and
                regulations, as well as applicable non-US law. You also agree:
              </p>
              <ul id="l2">
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    to comply with US law and local laws or rules regarding online
                    conduct and acceptable material;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    not to use Platform or its services or submit content to the
                    Platform if you are under the age of 18;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    not to use the Platform to purchase alcohol unless you and the
                    alcohol recipient are 21 or older and present a valid photo
                    identification(s) verifying your age at the time of alcohol
                    delivery;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    not to access the Platform using a third-party's account
                    registration without the express consent of the account holder;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    not to use the Platform for illegal purposes;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    not to commit any acts of infringement on the Platform or with
                    respect to content on the Platform;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    not to use the Platform to engage in commercial activities apart
                    from sanctioned use of the Services;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    not to copy any content, including, but not limited to Business
                    inventory or branding content, for republication in print or
                    on-line;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    not to attempt to gain unauthorized access to other computer systems
                    from or through the Platform;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    not to interfere with another person's use and enjoyment of the
                    Platform or another entity's use and enjoyment of the Platform;
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "11pt",
                      textIndent: "-6pt",
                      textAlign: "left"
                    }}
                  >
                    not to upload or transmit viruses or other harmful, disruptive or
                    destructive files on the Platform; and or
                  </p>
                </li>
                <li data-list-text="•">
                  <p
                    className="s2"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    not to disrupt, interfere with, or otherwise harm or violate the
                    security of the Platform, or any services, system resources,
                    accounts, passwords, servers or networks connected to or accessible
                    through the Platform or affiliated or linked sites (including those
                    of our partners).
                  </p>
                  <p
                    style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}
                  >
                    <br />
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text={4}>
              <h5
                style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
              >
                Business Accounts
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                You may sign up as a Business through the approval of iBouge. iBouge
                will allow you to list your goods and services for sale on the Platform
                but does not facilitate these sales in any way. Payments will be
                processed using third party payment processors and you will otherwise
                interact directly with other Users. Business acknowledges and agrees
                that it shall remain the seller of its own goods and services to other
                Users, and that iBouge is not a reseller and does not accept liability
                relating to such goods and services.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Users understand that to the extent they make any purchases with a
                Business, they accept that iBouge is not a party to such transaction.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={5}>
              <h5
                style={{ paddingLeft: "15pt", textIndent: "-10pt", textAlign: "left" }}
              >
                Termination and Modifications to the Platform
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                iBouge reserves the right, in its sole and absolute discretion, to
                modify, suspend, or discontinue at any time, with or without notice, the
                Platform and/or services offered on or through Platform (or any part
                thereof), including but not limited to the Platform’s features, look and
                feel, and functional elements and related services, or termination of
                your access to any part thereof or of your account. You understand that
                iBouge may enact this right at any time, though we intend to do our best
                to offer you adequate notice to migrate any of your data in accordance
                with these Terms.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={6}>
              <h5
                style={{ paddingLeft: "15pt", textIndent: "-10pt", textAlign: "left" }}
              >
                Account Termination
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Any account may be closed by the account owner via means provided in the
                Platform. Any transactions already confirmed must be honored and
                completed by the User. In the event that such confirmed transactions are
                more than 24 hours out, the termination will not be effective at least
                until the last such confirmed transaction is completed.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                If you are paying for a Business account, you may terminate your account
                as outlined above but will not be entitled to a refund for any portion
                of your annual membership.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={7}>
              <h5
                style={{ paddingLeft: "15pt", textIndent: "-10pt", textAlign: "left" }}
              >
                Your Interactions with Other Users
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                The Company is not responsible for the conduct of any User. As noted in
                and without limiting the Sections on Links and Limitation on Liability
                below, in no event shall the Company, its affiliates or its partners be
                liable (directly or indirectly) for any losses or damages whatsoever,
                whether direct, indirect, general, special, compensatory, consequential,
                and/or incidental, arising out of or relating to the conduct of you or
                anyone else in connection with the use of the Platform including,
                without limitation, death, bodily injury, emotional distress, and/or any
                other damages resulting from your communications and/or interactions
                with other users. You should not provide your financial or other
                personal information to other users.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={8}>
              <h5
                style={{ paddingLeft: "15pt", textIndent: "-10pt", textAlign: "left" }}
              >
                Your Content
              </h5>
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                iBouge may provide you with interactive opportunities (i) on the
                Platform, including, without limitation, features such as user ratings
                and reviews, messaging Users, your profile page, other people’s wall on
                their profile pages, microblogs, events, saved favorites, liked items
                and bookmarked Businesses, user profiles and pictures, (ii) on social
                media pages maintained by iBouge, as well as (iii) through other
                communications with you, including, without limitation, through text
                (“SMS”) or multimedia (“<u>MMS</u>”) messages (collectively, "
                <u>Interactive Areas</u>"). You will also have the opportunity to post,
                upload, publish, link to, transmit, record, display or otherwise make
                available (collectively, “<u>Post</u>”) on the Platform or transmit to
                other Users in various forms, including messages, videos, photographs,
                profile text, each of
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                which may be Posted publicly or privately (“<u>Content</u>”). You
                represent and warrant that you are the owner of and/or otherwise have
                the right to provide all Content that you Post to the Platform,
                including, without limitation, information and materials provided or
                made available in connection with any Facebook, Google, or other third
                party login (“<u>Your Content</u>”). Your Content includes, without
                limitation, your username and/or other user profile information such as
                your ratings history and how long you have been a iBouge User, your
                location, textual, visual, or audio content and information, whether
                transmitted via the Platform, SMS or MMS message, or otherwise.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                A. Use of Your Content
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                You grant iBouge an irrevocable, transferable, paid up, royalty-free,
                perpetual, non-exclusive worldwide sublicensable license to use, copy,
                display, publish, modify, remove, publicly perform, translate, create
                derivative works from, distribute, and/or otherwise use Your Content in
                all forms of media now known or hereafter invented for the purpose of
                operating, promoting, and improving our Site, business, products and
                services, and developing new ones (collectively, the “<u>Uses</u>”). The
                Uses include, without limitation, use of your username and/or other user
                profile information such as your ratings history and how long you have
                been a iBouge diner, to attribute Your Content to you on the Platform,
                including in Interactive Areas and other public areas on our Platform,
                or otherwise in connection with our Services. All Uses will be made
                without notification to and/or approval by you and without the
                requirement of payment to you or any other person or entity. Further,
                you hereby grant iBouge a royalty-free, perpetual, irrevocable,
                transferable, sublicensable, worldwide, nonexclusive license to
                incorporate and use any of your suggestions, input, or other feedback
                relating to the Platform or the Services (collectively, the “
                <u>Feedback</u>”) for any purpose without notice to, approval by, or
                compensation to you.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                You further understand and agree that you may be exposed to third-party
                content that is inaccurate, objectionable, inappropriate for children,
                or otherwise unsuited to your purpose. iBouge and its parents,
                subsidiaries, affiliates, and each of their officers, directors,
                employees, successors, assigns, licensors, licensees, designees,
                business partners, contractors, agents and representatives
                (collectively, the "<u>Released</u> <u>Parties</u>") will not be
                responsible for, and you hereby expressly release the Released Parties
                from any and all liability for the action of any and all third parties
                with respect to Your Content, or for any damages you allege to incur as
                a result of or relating to any third-party content.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                You understand and agree that we may, but are not obligated to, monitory
                or review any Content which is Posted to the Platform. We may delete any
                Content, in whole or in part, which in our sole judgment violates this
                Agreement or which may harm the Platform in any way. Content may also be
                suspended if reported by Users.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                C. Conduct within Interactive Areas
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                By transmitting Your Content, you agree to follow the standards of
                conduct below, and any additional standards that may be stated on the
                Platform. We expect your cooperation in upholding our standards. You are
                responsible for all of Your Content. You agree that Your Content will
                not:
              </p>
              <ul id="l3">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    be unlawful, harmful to adults or minors, threatening, abusive,
                    harassing, tortious, defamatory, vulgar, obscene, profane,
                    offensive, invasive of another's privacy, hateful, and/or racially,
                    ethnically, and/or otherwise objectionable;
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    have a commercial, political, or religious purpose;
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    be false, misleading, and/or not written in good faith;
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    infringe any patent, trademark, trade secret, copyright, right of
                    privacy and/or publicity, and/or other proprietary rights of any
                    person and/or entity;
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    be illegal and/or promote illegal activity;
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    contain confidential information belonging to a third party;
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    contain unauthorized advertising and/or solicits users to a business
                    other than those on the Platform; and/or
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left"
                    }}
                  >
                    be intended to interrupt, destroy, or limit the functionality or
                    integrity of any computer software, hardware, or on the Platform or
                    other websites.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                We do our best to encourage civility and discourage disruptive
                communication on the Platform. We also do our best to discourage
                communications that incite others to violate our standards. iBouge may
                monitor any and all use of the Platform, including, without limitation,
                interactions between our users; however, we are under no obligation to
                do so. We may manage the Platform in a manner intended to protect our
                property and rights and to facilitate the proper functioning of the
                Platform. If any of Your Content or conduct on our Platform violates our
                standards, or any other terms of this Agreement; or interferes with
                other peoples' enjoyment of the Platform or Services; or is
                inappropriate in our judgment; we reserve the right, in our sole
                discretion and without notice to you, (i) to change, delete or remove,
                in part or in full, any of Your Content, (ii) to terminate or suspend
                access to any Interactive Areas or any other part of our Platform,
                and/or (iii) to terminate or suspend your Account; in each case, with or
                without notice. iBouge will cooperate with local, state, and/or federal
                authorities to the extent required by applicable law in connection with
                Your Content.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                D. Ratings and Reviews
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                The Platform and other Interactive Areas may allow you to rate (each, a
                “<u>Rating</u>”) and post reviews (each, a “<u>Review</u>
                <a href="http://www.ftc.gov/os/" className="s9"  >
                  ”) of Businesses. Such Ratings and Reviews are considered Your Content
                  and are governed by the terms and conditions of this Agreement.
                  Ratings and Reviews are not endorsed by iBouge, and do not represent
                  the views of iBouge or of any affiliate or partner of iBouge. iBouge
                  does not assume liability for Ratings and Reviews or for any claims,
                  liabilities, or losses resulting from any Ratings and Reviews. We
                  strive to maintain a high level of integrity with our Ratings and
                  Reviews and other aspects of Your Content. Therefore, all Ratings and
                  Reviews must comply with the following criteria, in addition to and
                  without limiting other requirements applicable to Your Content as set
                  forth in these Terms: (1) before posting a Rating or Review, you must
                  have had recent first-hand experience with the Business; (2) you may
                  not have a proprietary or other affiliation with either the Business
                  or any of their competitors; (3) you may not draw any legal
                  conclusions regarding the Business’s products, services, or conduct;
                  (4) your Review must otherwise comply with the terms of this Agreement
                  as well as all applicable laws, rules, and regulations, including
                  without limitation the Federal Trade Commission’s Guides Concerning
                  the Use of Endorsements and Testimonials in Advertising (which may be
                  found at{" "}
                </a>
                <span className="s6">http://www.ftc.gov/os/</span>{" "}
                <span className="s6">2009/10/091005revisedendorsementguides.pdf</span>),
                including that your Review must disclose any “material connection” you
                may have with iBouge or the Business; and (5) you will not submit a
                Rating or Review in exchange for payment, free food or beverage items,
                or other benefits from any Business or third party. Any Rating and/or
                Review that we determine, in our sole discretion, could diminish the
                integrity of the Ratings and Reviews and/or the Platform may be removed
                or excluded by us without notice.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={9}>
              <h5
                style={{ paddingLeft: "16pt", textIndent: "-11pt", textAlign: "left" }}
              >
                Indemnity
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                By using the Platform or taking advantage of the Services, you warrant
                that you and, if applicable, the company or organization you represent,
                while interacting with iBouge through the Platform or otherwise, agree
                to defend, indemnify and hold harmless iBouge and its subsidiaries,
                agents, licensors, managers, and other affiliated companies, and their
                employees, contractors, agents, officers and directors, from and against
                any and all claims, damages, obligations, losses, liabilities, costs or
                debt, and expenses (including but not limited to attorney’s fees)
                arising from: (i) your improper use of and access to the Platform
                including any data or content transmitted or received by you; (ii) your
                violation of any terms applicable to your use of the Platform, including
                but not limited to the Terms of Use or the Privacy Policy, including
                without limitation your breach of any of the representations and
                warranties you make in these agreements;
              </p>
              <p
                className="s2"
                style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
              >
                (iii) your violation of any third-party right, including without
                limitation any right of privacy or intellectual property rights; (iv)
                any foodborne illness or other issues relating to the preparation,
                packaging, delivery or consumption of food, due to your improper storage
                or handling of said food or any; (v) your violation of any applicable
                law, rule or regulation; or (vi) any claims against iBouge or its
                affiliates by a third-party involving your use of the Platform (whether
                as an Individual a Business, or otherwise).
              </p>
            </li>
            <li data-list-text={10}>
              <h5
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "21pt",
                  textIndent: "-16pt",
                  textAlign: "left"
                }}
              >
                Disclaimer of Warranties
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                THE INFORMATION, CONTENT, PRODUCTS, SERVICES, AND MATERIALS AVAILABLE
                THROUGH THE PLATFORM (WHETHER PROVIDED BY IBOUGE, YOU, OTHER USERS OR
                OTHER AFFILIATES/THIRD PARTIES), INCLUDING WITHOUT LIMITATION, FOOD/
                BEVERAGE ORDERS, SUBMISSIONS, TEXT, PHOTOS, GRAPHICS, AUDIO FILES,
                VIDEO, AND LINKS, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, IBOUGE DISCLAIMS ALL
                REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
                LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, NONINFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND
                IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF
                PERFORMANCE. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT
                OF YOUR USE OF THE PLATFORM AND SERVICES, AND ANY OFFERINGS REMAINS
                SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED BY LAW.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={11}>
              <h5
                style={{ paddingLeft: "20pt", textIndent: "-15pt", textAlign: "left" }}
              >
                Limitation of Liability
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                IBOUGE ALLOWS USERS TO CONNECT WITH ONE ANOTHER. YOU MAY INTERACT WITH
                OTHER USERES IN A VARIETY OF WAYS, INCLUDING BY PURCHASING GOODS OR
                SERVICES FROM OTHER USERS. IBOUGE IS NOT A RESELLER OF GOODS OR
                SERVICES. IBOUGE CANNOT GUARANTEE ANY ASPECTS OF YOUR INTERACTIONS WITH
                OTHER USERS, INCLUDING BUSINESSES FROM WHOM YOU PURCHASE SUCH GOODS OR
                SERVICES. IBOUGE DOES NOT CONTROL BUSINESSES OR OTHER USERS AND WILL IN
                NO EVENT BE HELD LIABLE FOR THEIR ACTION OR INACTION. IBOUGE DOES NOT
                VERIFY ANY USER’S COMPLIANCE WITH APPLICABLE LAWS OR REGULATION.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                YOU UNDERSTAND THAT BY USING THE PLATFORM TO INTERACT WITH OTHER USERES
                YOU MAY BE EXPOSED TO SITUATIONS THAT ARE POTENTIALLY DANGEROUS OR
                HARMFUL, UNSAFE OR OTHERWISE OBJECTIONABLE, AND THAT YOU USE THE
                PLATFORM AT YOUR OWN RISK.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                WITH THE EXCEPTION OF ITS CONFIDENTIALITY OBLIGATIONS, IN NO EVENT SHALL
                THE IBOUGE BE LIABLE TO YOU OR ANYONE FOR ANY DIRECT, INDIRECT, SPECIAL,
                PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OR
                DAMAGES WHATSOEVER (EVEN IF IBOUGE HAS BEEN PREVIOUSLY ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES), WHETHER IN AN ACTION UNDER CONTRACT,
                NEGLIGENCE, OR ANY OTHER THEORY, IN ANY MANNER ARISING OUT OF OR IN
                CONNECTION WITH THE USE, INABILITY TO USE, PERFORMANCE OF, OR SERVICES
                PROVIDED ON OR THROUGH THE PLATFORM. IBOUGE ASSUMES NO RESPONSIBILITY
                AND SHALL NOT BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT,
                YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO,
                USE OF, BROWSING OF, OR DOWNLOADING OF ANY MATERIAL FROM THE PLATFORM.
                IBOUGE ASSUMES NO RESPONSIBILITY OR LIABILITY IN ANY MANNER ARISING OUT
                OF OR IN CONNECTION WITH ANY INFORMATION, CONTENT, PRODUCTS, SERVICES,
                OR MATERIAL AVAILABLE ON OR THROUGH THE PLATFORM, AS WELL AS ANY THIRD
                PARTY WEBSITE PAGES OR ADDITIONAL WEBSITES LINKED TO THIS SITE, FOR ANY
                ERROR, DEFAMATION, LIBEL, SLANDER, OMISSION, FALSEHOOD, OBSCENITY,
                PORNOGRAPHY, PROFANITY, DANGER, INACCURACY CONTAINED THEREIN OR HARM TO
                PERSON OR PROPERTY CAUSED THEREBY. THESE LIMITATIONS SHALL APPLY
                NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
                BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATIONS
                MAY NOT APPLY TO YOU. WITH THE
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                EXCEPTION OF ITS CONFIDENTIALITY OBLIGATIONS, IN NO EVENT SHALL IBOUGE’S
                TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION,
                WHETHER IN CONTRACT OR TORT, EXCEED (A) THE AMOUNT PAID BY YOU TO
                IBOUGE, IF ANY IN THE PAST 12 MONTHS.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                YOU AND IBOUGE AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF
                LIABILITY IN THESE TERMS OF USE ARE MATERIAL, BARGAINED-FOR BASES OF
                THIS AGREEMENT, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN
                DETERMINING THE CONSIDERATION TO BE GIVEN BY EACH PARTY UNDER THIS
                AGREEMENT AND IN THE DECISION BY EACH PARTY TO ENTER INTO THIS
                AGREEMENT. YOU AND IBOUGE AGREE THAT THE WARRANTY DISCLAIMERS AND
                LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE FAIR AND REASONABLE.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                YOU WARRANT THAT AS A REPRESENTATIVE OF ANY COMPANY OR ORGANIZATION FOR
                WHICH YOU MAY BE PLACING ORDERS FOR, YOU HAVE THE AUTHORITY TO BIND THE
                COMPANY OR ORGANIZATION TO THE INDEMNIFICATION AGREEMENT AND THE ABOVE
                LIMITATIONS ON LIABILITY AND THAT YOU DO BIND TO THEM THROUGH YOUR USE
                OF THE PLATFORM.
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                IF YOU ARE DISSATISFIED WITH THE SITE OR DO NOT AGREE TO ANY PROVISIONS
                OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                USING THE PLATFORM.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={12}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Your Account, Password, and Security
              </h5>
              <p
                className="s7"
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "109%",
                  textAlign: "left"
                }}
              >
                Certain features of the Platform require that you register and/or create
                an account with the Platform. To register and create an account, you
                must select an account designation and password and provide certain
                personal information to iBouge as set forth in our privacy policy, which
                is located at 14241 NE Woodinville Duvall Rd, PO Box 153, Woodinville,
                WA 98072 and incorporated herein by reference. In consideration of the
                use of the Platform, you agree to: (a) provide true, accurate, current
                and complete information about yourself as prompted by the registration
                form, and (b) maintain and promptly update the personal information you
                provide to keep it true, accurate, current and complete. If you provide
                any information that is untrue, inaccurate, not current or incomplete,
                or iBouge has reasonable grounds to suspect that such information is
                untrue, inaccurate, not current or incomplete, iBouge has the right to
                refuse any and all current or future use of the Platform (or any portion
                thereof).
              </p>
              <p
                className="s2"
                style={{
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "10pt",
                  textAlign: "left"
                }}
              >
                You are responsible for maintaining the confidentiality and security of
                your account and password, and
              </p>
              <p
                className="s2"
                style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
              >
                you are fully responsible for all activities that occur under your
                password or account, and for any other actions taken in connection with
                the account or password. You agree to (a) immediately notify iBouge of
                any known or suspected unauthorized use(s) of your password or account,
                or any known or suspected breach of security, including loss, theft, or
                unauthorized disclosure of your password or credit card information.
                iBouge will not be liable for any injury, loss or damage of any kind
                arising from or relating to your failure to comply with (a) and (b) or
                for any acts or omissions by you or someone else using your account
                and/or password.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={13}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Links
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "justify"
                }}
              >
                As a courtesy to you, iBouge may offer links to other websites. Some of
                these websites may be affiliated with iBouge, while others are not. You
                may also be provided links by other Users, including Businesses. iBouge
                is not responsible for the contents of any website pages created and
                maintained by organizations independent of iBouge. Visiting any such
                third-party website pages is done at your own risk. iBouge has no
                control of these third-party website pages, nor can it guarantee the
                accuracy, completeness, or
              </p>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                timeliness of information in third-party website pages. Your use of such
                information is voluntary, and your reliance on such information should
                be made only after independent review. References to commercial products
                or services within any such third-party website pages do not constitute
                or imply an endorsement by iBouge. By using the Platform you acknowledge
                that iBouge is responsible neither for the availability of, nor the
                content located on or through any third-party website pages.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={14}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Trademarks
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Marks, logos, and names of iBouge used on or in connection with the
                Platform or any other service or product by the Platform may not be used
                in connection with any product or service that is not under iBouge’s
                ownership or control. Furthermore, such trademarks may not be used in
                any manner that is likely to cause confusion among customers or in any
                manner that disparages or discredits the Platform or iBouge. All other
                trademarks not owned by iBouge (or its affiliates) that appear on the
                Platform are the property of their respective owners, who may or may not
                be affiliated with, connected to, or sponsored by iBouge or its
                affiliates.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={15}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                INTELLECTUAL PROPERTY POLICY &amp; DMCA NOTICES
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                <a href="http://www.copyright.gov/" className="s8"  >
                  We are committed to respecting the intellectual property rights of
                  others. The Digital Millennium Copyright Act of 1998 (the "DMCA")
                  provides recourse for copyright owners who believe that material
                  appearing on the Internet infringes their rights under U.S. copyright
                  law. If you believe in good faith that any material used or displayed
                  on or through iBouge infringes your copyright, you (or your agent) may
                  send us a notice requesting that the material be removed, or access to
                  it blocked. The notice must include the following information: (a) a
                  physical or electronic signature of a person authorized to act on
                  behalf of the owner of an exclusive right that is allegedly infringed;
                  (b) identification of the copyrighted work claimed to have been
                  infringed (or if multiple copyrighted works are covered by a single
                  notification, a representative list of such works); (c) identification
                  of the material that is claimed to be infringing or the subject of
                  infringing activity, and information reasonably sufficient to allow us
                  to locate the material on iBouge; (d) the name, address, telephone
                  number and email address (if available) of the complaining party; (e)
                  a statement that the complaining party has a good faith belief that
                  use of the material in the manner complained of is not authorized by
                  the copyright owner, its agent or the law; and (f) a statement that
                  the information in the notification is accurate and, under penalty of
                  perjury, that the complaining party is authorized to act on behalf of
                  the owner of an exclusive right that is allegedly infringed. If you
                  believe in good faith that a notice of copyright infringement has been
                  wrongly filed against you, the DMCA permits you to send us a
                  counter-notice. Notices and counter-notices must meet the then-current
                  statutory requirements imposed by the DMCA; see{" "}
                </a>
                http://www.copyright.gov/ for details. DMCA notices and counter-notices
                regarding – the Platform should be sent to:
              </p>
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                iBouge, Inc.
              </p>
              <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                14241 NE Woodinville Duvall Rd, PO Box 153
              </p>
              <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                Woodinville, WA 98072 United States
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={16}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Consideration
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Each of the parties acknowledges that these Terms of Use are supported
                by reasonable and valuable consideration, the receipt and adequacy of
                which are hereby acknowledged. Without limiting the foregoing, you
                acknowledge that such consideration includes, without limitation, your
                use of the Platform and receipt or use of data, content, products and/or
                services through the Platform.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={17}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Jurisdiction, Applicable Law, and Limitations
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Each of the parties agrees that these Terms of Use will be governed by
                and construed in accordance with the laws of the United States of
                America and the State of California, without regard to its conflicts of
                law provisions. Use of the Platform is unauthorized in any jurisdiction
                that does not give effect to all provisions of these Terms of Use. The
                Platform may not be downloaded outside of the United States.
              </p>
              <p
                className="s2"
                style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
              >
                Each of the parties agrees that all legal proceedings arising out of or
                in connection with these Terms of Use, or services available on or
                through the Platform must be filed in a federal or state court located
                in Alameda County, California. Each of the parties expressly submits to
                the exclusive jurisdiction of said courts and consent to
                extraterritorial service of process.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={18}>
              <h5
                style={{
                  paddingLeft: "18pt",
                  textIndent: "-13pt",
                  textAlign: "justify"
                }}
              >
                Dispute Resolution
              </h5>
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                PLEASE READ THIS “DISPUTE RESOLUTION” SECTION CAREFULLY. IT LIMITS THE
                WAYS YOU CAN SEEK RELIEF FROM IBOUGE AND REQUIRES YOU TO ARBITRATE
                DISPUTES ON AN INDIVIDUAL BASIS. IN ARBITRATION, THERE IS NO JUDGE OR
                JURY AND THERE IS MORE CIRCUMSCRIBED DISCOVERY AND APPELLATE REVIEW THAN
                THERE IS IN COURT.
              </p>
              <ol id="l4">
                <li data-list-text="A.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "18pt",
                      textIndent: "-13pt",
                      textAlign: "left"
                    }}
                  >
                    Informal Dispute Resolution Procedure.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    <a href="mailto:info@iBouge.net" className="s9"  >
                      There might be instances when a Dispute (as defined below) arises
                      between you and iBouge. In those instances, iBouge is committed to
                      working with you to reach a reasonable resolution; however, we can
                      only do this if we know about and understand each other’s
                      concerns. Therefore, for any Dispute that arises between you and
                      iBouge, both parties acknowledge and agree that they will first
                      make a good faith effort to resolve it informally before
                      initiating any formal dispute resolution proceeding in arbitration
                      or otherwise. This includes first sending a written description of
                      the Dispute to the other party. For any Dispute you initiate, you
                      agree to send the written description of the Dispute along with
                      the email address associated with your account to the following
                      email address:{" "}
                    </a>
                    info@iBouge.net. Your written description must be on an individual
                    basis and also provide at least the following information: your
                    name; a detailed description of the nature and basis of the Dispute,
                    including any transaction details; and the specific relief sought
                    and how it was calculated. Your written description must be
                    personally signed by you. For any Dispute that iBouge raises, we
                    will send our written description of the Dispute to the email
                    address associated with your account.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    You and iBouge then agree to negotiate in good faith about the
                    Dispute. This might include an informal telephonic dispute
                    resolution conference between you and iBouge if such a conference is
                    requested by iBouge. If such an informal telephonic dispute
                    resolution conference takes place, it shall be individualized such
                    that a separate conference must be held each time either party
                    intends to commence individual arbitration; multiple individuals
                    initiating claims cannot participate in the same informal telephonic
                    dispute resolution conference. If either party is represented by
                    counsel, that party's counsel may participate in the informal
                    telephonic dispute resolution conference, but the party also must
                    appear at and participate in the conference. This should lead to
                    resolution, but if for some reason the Dispute is not resolved
                    satisfactorily within sixty (60) days after receipt of the complete
                    written description of the Dispute, you and iBouge agree to the
                    further dispute resolution provisions below.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    To reiterate, this informal dispute resolution process is a
                    prerequisite and condition precedent to commencing any formal
                    dispute resolution proceeding. The parties agree that any relevant
                    limitations period and filing fee or other deadlines will be tolled
                    while the parties engage in this informal dispute resolution
                    process. A court shall have the authority to enjoin the filing or
                    prosecution of arbitrations based on a failure to comply with this
                    Informal Dispute Resolution Procedure. A party may raise non-
                    compliance with this Informal Dispute Resolution Procedure in court
                    and/or in connection with the arbitration.
                  </p>
                </li>
                <li data-list-text="B.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "17pt",
                      textIndent: "-12pt",
                      textAlign: "left"
                    }}
                  >
                    Mutual Arbitration Agreement.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    You and iBouge agree that all claims, disputes, or disagreements
                    that may arise out of the interpretation or performance of this
                    Agreement or payments by or to iBouge, or that in any way relate to
                    your use of the Platform, the Services, and/or other content on the
                    Platform, your relationship with iBouge, or any other dispute with
                    iBouge, (whether based in contract, tort, statute, fraud,
                    misrepresentation, or any other legal
                  </p>
                  <p
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    theory) (each, a “Dispute”) shall be submitted exclusively to
                    binding arbitration. Dispute shall have the broadest possible
                    meaning. This includes claims that arose, were asserted, or involve
                    facts occurring before the existence of this or any prior Agreement
                    as well as claims that may arise after the termination of this
                    Agreement. This Mutual Arbitration Agreement is intended to be
                    broadly interpreted.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    Notwithstanding the foregoing, issues related to the scope,
                    validity, and enforceability of this Arbitration Agreement are for a
                    court to decide. Also, each party retains the right to (1) elect (at
                    any time prior to the appointment of an arbitrator) to have any
                    claims heard in small claims court on an individual basis for
                    disputes and actions within the scope of such court’s jurisdiction,
                    provided the proceeding remains in small claims court and is not
                    removed or appealed to a court of general jurisdiction, and (2) seek
                    injunctive or other equitable relief in a court of competent
                    jurisdiction to prevent the actual or threatened infringement,
                    misappropriation, or violation of a party's copyrights, trademarks,
                    trade secrets, patents, or other confidential or proprietary
                    information or intellectual property rights. For clarity, this
                    “Dispute Resolution” section does not alter, amend, or affect any of
                    the rights or obligations of the parties to any other written
                    agreement between you and iBouge.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    ARBITRATION MEANS THAT AN ARBITRATOR AND NOT A JUDGE OR JURY WILL
                    DECIDE THE DISPUTE. RIGHTS TO PREHEARING EXCHANGE OF INFORMATION AND
                    APPEALS MAY BE LIMITED IN ARBITRATION. YOU HEREBY ACKNOWLEDGE AND
                    AGREE THAT YOU AND IBOUGE ARE EACH WAIVING THE RIGHT TO A TRIAL BY
                    JURY TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                  </p>
                </li>
                <li data-list-text="C.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "17pt",
                      textIndent: "-12pt",
                      textAlign: "left"
                    }}
                  >
                    Class Action and Collective Relief Waiver.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT ALLOWED BY
                    LAW, EXCEPT AS SET OUT IN SECTION VII BELOW, THERE SHALL BE NO RIGHT
                    OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED OR LITIGATED ON A
                    CLASS, JOINT, COLLECTIVE OR CONSOLIDATED BASIS OR IN A PURPORTED
                    REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC, OR AS A
                    PRIVATE ATTORNEY GENERAL OR FOR PUBLIC INJUNCTIVE RELIEF. UNLESS
                    BOTH YOU AND IBOUGE OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY
                    NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS (EXCEPT AS SET OUT IN
                    SECTION 21G BELOW), AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF
                    ANY CLASS, JOINT, COLLECTIVE OR REPRESENTATIVE PROCEEDING. THE
                    ARBITRATOR MAY AWARD RELIEF (INCLUDING ANY DECLARATORY OR INJUNCTIVE
                    RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND
                    ONLY TO THE EXTENT NECESSARY TO RESOLVE AN INDIVIDUAL PARTY’S CLAIM.
                    THE ARBITRATOR MAY NOT AWARD RELIEF FOR OR AGAINST ANYONE WHO IS NOT
                    A PARTY TO THE PROCEEDING.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    THIS CLASS ACTION AND COLLECTIVE RELIEF WAIVER IS AN ESSENTIAL PART
                    OF THIS “DISPUTE RESOLUTION” SECTION, AND IF IT IS DEEMED INVALID OR
                    UNENFORCEABLE WITH RESPECT TO A PARTICULAR CLAIM OR DISPUTE, NEITHER
                    YOU NOR IBOUGE IS ENTITLED TO ARBITRATION OF SUCH CLAIM OR DISPUTE.
                    NOTWITHSTANDING THE FOREGOING, IF A COURT DETERMINES THAT THE CLASS
                    ACTION AND COLLECTIVE RELIEF WAIVER IS NOT ENFORCEABLE AS TO A
                    PARTICULAR CLAIM OR REQUEST FOR RELIEF OR A REQUEST FOR PUBLIC
                    INJUNCTIVE RELIEF AND ALL APPEALS FROM THAT DECISION HAVE BEEN
                    EXHAUSTED (OR THE DECISION IS OTHERWISE FINAL), THEN THE PARTIES
                    AGREE THAT THAT PARTICULAR CLAIM OR REQUEST FOR RELIEF MAY PROCEED
                    IN COURT BUT SHALL BE STAYED PENDING ARBITRATION OF THE REMAINING
                    CLAIMS AND REQUESTS FOR RELIEF.
                  </p>
                </li>
                <li data-list-text="D.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "17pt",
                      textIndent: "-12pt",
                      textAlign: "left"
                    }}
                  >
                    Arbitration Rules.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    The arbitration will be administered by the American Arbitration
                    Association ("AAA"). Except as modified by this “Dispute Resolution”
                    section, the AAA will administer the arbitration in accordance with
                    either (A) the Commercial Arbitration Rules then in effect, or (B)
                    the Consumer Arbitration Rules then in effect if the matter involves
                    a “consumer” agreement as defined by Consumer Arbitration Rule R-1
                  </p>
                  <p
                    className="s12"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    <span style={{ color: "#000" }}>
                      (together, the "Applicable AAA Rules"). The Applicable AAA Rules
                      are available at{" "}
                    </span>
                    <a href="http://www.adr.org/" className="s11"  >
                      https://
                    </a>
                    <u>www.adr.org/</u> <u>Rules</u>{" "}
                    <span style={{ color: "#000" }}>
                      or by calling the AAA at 1-800-778-7879. If AAA is unavailable or
                      unwilling to administer an arbitration consistent with this
                      “Dispute Resolution” section as written, the parties will select
                      an alternative arbitration provider that will administer the
                      arbitration consistent with this “Dispute Resolution” section as
                      written. If the parties cannot agree on an alternative arbitration
                      provider, the parties shall mutually petition a court of
                      appropriate jurisdiction to appoint an arbitration provider that
                      will administer an arbitration consistent with this “Dispute
                      Resolution” section as written.
                    </span>
                  </p>
                </li>
                <li data-list-text="E.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "16pt",
                      textIndent: "-11pt",
                      textAlign: "left"
                    }}
                  >
                    Arbitration Process.
                  </p>
                  <p
                    className="s5"
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    <span style={{ color: "#000" }}>
                      If after sixty (60) days the Informal Dispute Resolution Procedure
                      above is unsuccessful in resolving the parties’ dispute, a party
                      who desires to initiate arbitration must provide the other party
                      with a written Demand for Arbitration as specified in the
                      Applicable AAA Rules. (The AAA provides applicable forms for
                      Demands for Arbitration available at{" "}
                    </span>
                    <a
                      href="http://www.adr.org/sites/default/files/"
                      className="a"
                       
                    >
                      https://
                    </a>
                    <u>www.adr.org/sites/default/files/</u>{" "}
                    <u>Demand_for_Arbitration_0.pdf</u>{" "}
                    <span style={{ color: "#000" }}>
                      (Commercial Arbitration Rules) and{" "}
                    </span>
                    <a
                      href="http://www.adr.org/sites/default/"
                      className="a"
                       
                    >
                      https://
                    </a>
                    <u>www.adr.org/sites/default/</u>{" "}
                    <u>files/Consumer_Demand_for_Arbitration_Form_1.pdf</u>{" "}
                    <span style={{ color: "#000" }}>
                      (Consumer Arbitration Rules), and a separate affidavit for waiver
                      of fees for California residents only is available at{" "}
                    </span>
                    <u>https://adr.org/sites/default/files/</u>{" "}
                    <u>Waiver_of_Fees_CA_Only.pdf</u>
                    <span style={{ color: "#000" }}>
                      .) If you initiate arbitration, you shall certify that you have
                      complied with the Informal Dispute Resolution Procedure above and
                      personally sign your Demand for Arbitration. The arbitration will
                      be conducted by a single arbitrator. The arbitrator will be either
                      a retired judge or an attorney licensed to practice law in the
                      state or county in which you reside. The parties will first
                      attempt to agree on an arbitrator. If the parties are unable to
                      agree upon an arbitrator within twenty-one (21) days of receiving
                      the AAA’s list of eligible neutrals, then the AAA will appoint the
                      arbitrator in accordance with the AAA Rules.
                    </span>
                  </p>
                </li>
                <li data-list-text="F.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "15pt",
                      textIndent: "-10pt",
                      textAlign: "left"
                    }}
                  >
                    Arbitration Location and Procedure.
                  </p>
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    Unless you and iBouge otherwise agree, the arbitration will be
                    conducted in the county where you reside. If the amount in
                    controversy does not exceed $10,000 and you do not seek injunctive
                    or declaratory relief, then the arbitration will be conducted solely
                    on the basis of documents you and iBouge submit to the arbitrator,
                    unless a party requests a hearing and the arbitrator determines that
                    a hearing is necessary. If the amount in controversy exceeds $10,000
                    or seeks declaratory or injunctive relief, the right to a hearing
                    will be determined by the Applicable AAA Rules. Subject to the
                    Applicable AAA Rules, the arbitrator will have the discretion to
                    direct a reasonable exchange of information by the parties,
                    consistent with the expedited nature of the arbitration. Unless
                    otherwise prohibited by law or agreed by the parties, all
                    arbitration proceedings and all related records will be confidential
                    and closed to the public and any parties other than you and iBouge,
                    except as necessary to obtain court confirmation of the arbitration
                    award.
                  </p>
                </li>
                <li data-list-text="G.">
                  <p
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "18pt",
                      textIndent: "-13pt",
                      textAlign: "left"
                    }}
                  >
                    Batch Arbitration.
                  </p>
                </li>
              </ol>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                To increase the efficiency of administration and resolution of
                arbitrations, in the event 100 or more similar arbitration demands
                presented by or with the assistance or coordination of the same law firm
                or organization are submitted to AAA or another arbitration provider (if
                AAA is unavailable) against iBouge, the arbitration provider shall (i)
                administer the arbitration demands in batches of 100 demands per batch
                (to the extent there are fewer than 100 arbitration demands left over
                after the batching described above, a final batch will consist of the
                remaining demands); (ii) designate a single, different arbitrator for
                each batch (unless the parties agree otherwise); and (iii) provide for a
                single filing set of fees (for example, if AAA is the arbitration
                provider, one filing fee, case management fee, and arbitrator
                compensation fee) due per side per batch. You agree to cooperate in good
                faith with iBouge and the arbitration provider to implement such a
                “batch approach” or other similar approach to provide for an efficient
                resolution of claims, including the payment of single filing and
                administrative fees for batches of claims. This “Batch Arbitrations”
                provision shall in no way be interpreted as authorizing class
                arbitration of any kind. iBouge expressly reserves its right to raise
                unique defenses as to each claimant in connection with this process.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                E. Arbitrator's Decision.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                The arbitrator will render an award within the time frame specified in
                the applicable AAA Rules. The arbitrator's decision will include the
                essential findings and conclusions upon which the arbitrator based
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                the award. Judgment on the arbitration award may be entered in any court
                having jurisdiction thereof. The arbitrator will have the authority to
                award monetary damages on an individual basis and to grant, on an
                individual basis, any non-monetary remedy or relief available to an
                individual to the extent available under applicable law, the arbitral
                forum’s rules, and this Agreement. The arbitrator's award of damages
                and/or other relief must be consistent with section III above and also
                must be consistent with the terms of the "Limitation of Liability"
                section above as to the types and the amounts of damages or other relief
                for which a party may be held liable. The arbitrator’s award shall be
                binding only between the parties to the arbitration proceeding and shall
                have no preclusive effect in any other arbitration or other proceeding
                involving a different party.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Attorneys’ fees will be available to the prevailing party in the
                arbitration only if authorized under applicable substantive law
                governing the claims in the arbitration. The arbitrator shall apply the
                provisions of Federal Rule of Civil Procedure 68 after the arbitration
                award is entered. If the arbitrator finds that either the substance of a
                claim or the relief sought in a Demand for Arbitration was frivolous or
                was brought for harassment or an improper purpose (as measured by the
                standards set forth in Federal Rule of Civil Procedure 11(b)), the
                arbitrator may award attorneys’ fees, costs and expenses in favor of a
                party.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                F. Fees.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                Your responsibility to pay any AAA filing, administrative, and/or
                arbitrator fees will be solely as set forth in the applicable AAA Rules
                (as modified by section VII above). If, however, you are able to
                demonstrate that the costs of arbitration will be prohibitive for you as
                compared to the costs of litigation, iBouge will reimburse as much of
                the filing, administration, and/or arbitrator fees as the arbitrator
                deems necessary to prevent the arbitration from being cost-prohibitive
                to you.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                G. Right to Opt-Out of Arbitration.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                <a href="mailto:info@iBouge.net" className="s9"  >
                  iBouge’s updates to this Agreement do not provide you with a new
                  opportunity to opt out of the Mutual Arbitration Agreement if you
                  previously agreed to an Agreement and did not validly opt out of
                  arbitration. iBouge will continue to honor any valid opt outs if you
                  opted out of the Arbitration Agreement in a prior version of the
                  Agreement. You may opt out of this Mutual Arbitration Agreement. To
                  opt out, you must notify iBouge in writing no later than 30 days after
                  first becoming subject to this Mutual Arbitration Agreement. Your
                  notice must include your name and address, the email address you
                  currently use to access your iBouge Account (if you have one), and a
                  clear statement that you want to opt out of this Mutual Arbitration
                  Agreement. You must send your opt-out notice to:{" "}
                </a>
                info@iBouge.net. If you opt out of this Arbitration Agreement, all other
                parts of this Agreement will continue to apply to you. Opting out of
                this Arbitration Agreement has no effect on any other arbitration
                agreements that you may have entered into with iBouge or may enter into
                in the future with iBouge.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                XI. Changes.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                <a href="mailto:info@iBouge.net" className="s9"  >
                  iBouge reserves the right to change this "Dispute Resolution" section.
                  If iBouge changes this "Dispute Resolution" section after the date you
                  first accepted this Agreement (or accepted any subsequent changes to
                  this Agreement), you agree that your continued use of the Platform or
                  Services after such change will be deemed acceptance of those changes.
                  If you do not agree to such change, you may reject any such change by
                  providing iBouge written notice of such rejection by mail or hand
                  delivery to: iBouge, Attn: Dispute Resolutions, 95 Third Street, 2nd
                  Floor, San Francisco, CA 94103, or by email from the email address
                  associated with your account to:{" "}
                </a>
                info@iBouge.net, within 30 days of the date such change became
                effective, as indicated in the "Effective" date above. In order to be
                effective, the notice must include your full name and clearly indicate
                your intent to reject changes to this "Dispute Resolution" section. By
                rejecting changes, you are agreeing that you will arbitrate any dispute
                between you and iBouge in accordance with the provisions of this
                "Dispute Resolution" section as of the date you first accepted this
                Agreement (or accepted any subsequent changes to this Agreement, as
                applicable).
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={19}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Enforceability
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                If any portion of these Terms of Use is found to be void, invalid or
                otherwise unenforceable, then that portion shall be deemed to be
                superseded by a valid, enforceable provision that matches the intent of
                the original provision as closely as possible. The remainder of these
                Terms of Use shall continue to be enforceable and valid according to
                terms contained herein.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={20}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Entire Agreement
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                With the exception of the Privacy Policy and any other terms you
                explicitly agree to during your use of the Platform, these Terms of Use
                constitute the entire agreement between you and iBouge, superseding all
                prior agreements regarding the Platform.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={21}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                No Waiver
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                The failure of either party to exercise or enforce any right or
                provision of the Terms of Use shall not constitute a waiver of said
                right or provision. Neither party hereto shall be deemed to be in
                default of any provision of the Terms of Use or for failure in
                performance resulting from acts or events beyond the reasonable control
                of such party and arising without its fault or negligence, including,
                but not be limited to, acts of God, civil or military authority,
                interruption of electric or telecommunication services, civil
                disturbances, acts of war or terrorists, strikes, fires, floods or other
                catastrophes.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={22}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Headings &amp; Construction
              </h5>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "justify"
                }}
              >
                The section titles in the Terms of Use are for your convenience only and
                carry no contractual or legal effect whatsoever. The language in these
                Terms of Use shall be interpreted in accordance with its fair meaning
                and shall not be strictly interpreted for or against either party.
              </p>
              <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text={23}>
              <h5
                style={{ paddingLeft: "21pt", textIndent: "-16pt", textAlign: "left" }}
              >
                Comments &amp; Feedback
              </h5>
            </li>
          </ol>
          <p
            className="s2"
            style={{
              paddingTop: "7pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "justify"
            }}
          >
            <a href="mailto:info@iBouge.com" className="s8"  >
              In compliance with your rights under California Civil Code 1789.3, you
              have the right to contact iBouge with any complaints or to seek additional
              information. You may email iBouge at{" "}
            </a>
            info@iBouge.com. You may also call (315) 654-0311. For any physical
            documents, you may send mail to
          </p>
          <p
            style={{
              paddingTop: "6pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "12pt",
              textAlign: "justify"
            }}
          >
            iBouge Inc
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            14241 NE Woodinville Duvall Rd, PO Box 153
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            Woodinville, WA 98072{" "}
            <span style={{ color: "#4B4B4D" }}>United States</span>
          </p>
          <p
            className="s2"
            style={{
              paddingTop: "7pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            If California users have any questions or complaints about the Platform,
            they may also contact The Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs through
            writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at
            (916) 445-1254 or (800) 952-5210.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tos