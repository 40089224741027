import React from 'react'
import { FiX } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SearchBox = ({SearchUsers, setSearchToggle}) => {
  const userData = useSelector((state) => state?.auth?.user?.user)
    const navigate = useNavigate()
    
    const handleClick= (e) =>{
      if(e._id === userData._id){
        navigate(`/${userData.type?"business":"personal"}/${userData?._id}`)
      }else{
        navigate(`/Profiless/${e?._id}`, {
            state: {
              item: e._id,
            },
          });
      }
      setSearchToggle(false)
    }


  return (
    <div>
      <div className="Searchbox-Main p-0 m-3">
      <div className="nb-pointer"></div>
      <div className="text-start rounded-3 overflow-hidden Searchbox bg-white">
        <div className="notibox-Header text-white">
          <span className="py-3 fw-bolder fs-6 px-4">Searching...</span>
          <FiX
              className="fs-4 text-white me-3"
              role="button"
              onClick={() =>setSearchToggle(false)}
            />
        </div>
        <div className="notifications pt-2">
        {SearchUsers.map((noti,index) =>{
          const intrest = noti.profile.interests[0]?noti.profile.interests[0].length>20?noti.profile.interests[0].substring(0, 20)+ "...":noti.profile.interests[0]:""
            return(
          <div key={index} className="d-flex align-items-center mx-3 py-2 rounded-3 ps-3 bg-light mb-1" style={{cursor:"pointer"}} onClick={() => {handleClick(noti)}}>
            <div className="pe-3">
              <img src={
                      noti.profile_pic
                        ? noti.profile_pic :"/img/upload-photo.png"
                        
                    }
                height={40} 
              className="rounded-circle image-user" alt="ibouge"
              />
            </div>
            <div className="namees text-dark">
              <span className='fw-bold'>{noti.fname} {noti.lname}</span>
              <h6 className="info">{intrest}</h6>
            </div>
          </div>
              
            )
              
        }) }
        
        </div>

        </div>
      </div>
    </div>
  )
}

export default SearchBox
