import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiX } from "react-icons/fi";
import { Server2, Socket } from "../../../redux/actions/auth/auth.actions";
import moment from "moment";
const NotificatioBox = ({ setToggle, setPopupStatus, setPopupData, setNotifyIcon , setMicroStatus , setMicroData }) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const [NotiList, setNotiList] = useState([]);
  const [NotiData, setNotiData] = useState(false);
  const itemref = useRef(null);
  useEffect(() => {
    setNotiData(true);
    GetNoti();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const GetNoti = () => {
    var config = {
      method: "GET",
      url: Server2 + `/users/getNotifications?id=${userData._id}`,
      headers: { "Content-Type": "application/json" },
    };
    axios(config).then((response) => {
      if (response.data) {
        setNotiList([]);
        setNotiData(false);
        setNotiList(response.data);
        console.log(response.data,"response.data")
      }
    });
  };
  useEffect(() =>{
    let Arr = []
    if(NotiList.length > 0){
      NotiList.map((items) => {
        if(items.status === true){
          Arr.push(items._id)
        }
        return("");
      })
    }
    if(Arr.length>0){

      const data = {
        id: Arr
      }
      var config = {
        method: "POST",
        url: Server2 + "/users/UpdateNotificationStatus",
        headers: { 'Content-Type': 'application/json' },
        data: data
      };
      axios(config)
      .then((response) => {
        setNotifyIcon(false)
        GetNoti();
      })
      .catch(function (error) {
        console.log("error Updatenotiftiactionstatus", error);
      });
    }

  },[NotiList]) // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   // console.log("ia ma ")
  //   // Intersection Observer callback function
  //   // const handleIntersection = async (entry) => {
  //   //   console.log(entry[0],"enty")
  //   //   if (entry[0].isIntersecting) {
  //       // const notificationId = entry.target.id;
  //       // const notification = NotiList.find((notif) => notif._id === notificationId);
  //       // const notification = NotiList.find((notif) => console.log(notif,"notify"));
  //       // console.log(notification,"iamndjf")
  //       // if (notification && notification.status === 'unread') {
  //       //   try {
  //       //     // Mark the notification as "read" in the database when it becomes visible
  //       //     await axios.put(`/api/notifications/${notificationId}`, {
  //       //       status: 'read',
  //       //     });
  //       //     console.log(`Notification ${notificationId} status updated in the database.`);
  //       //   } catch (error) {
  //       //     console.error('Failed to update notification status:', error);
  //       //   }
  //       // }
  //     }
  //   };

  //   // Create an Intersection Observer instance for each notification
  //   const observers = [];
  //   NotiList.forEach((notification) => {
  //     const observer = new IntersectionObserver(handleIntersection, {
  //       root: null,
  //       rootMargin: '0px',
  //       threshold: 0.5, // The percentage of the notification box that needs to be visible to be considered "visible"
  //     });

  //     if (itemref.current) {
  //       observer.observe(itemref.current);
  //     }
  //     observers.push(observer);
  //   });

  //   // Clean up the observers when the component is unmounted
  //   return () => {
  //     observers.forEach((observer) => {
  //       observer.disconnect();
  //     });
  //   };
  // }, [NotiList]);
  const handleNoti = (noti) => {
    if (noti.type === "accept-friend-request" || noti.type === "friend-request" || noti.type === "business-followed") {
      const data = {id: noti.recipients[0]}
      Socket.emit("updateProfile" , data)
      navigate(`/Profiless/${noti.meta[0].value}`, {
        state: {
          item: noti.meta[0].value,
        },
      })
    } else if(noti.type === "invite-friend-to-microblog"){
      if(noti?.meta[2]?.value){
       // Get all users of microblogs
       var config = {
        method: "GET",
        url:
          Server2 +
          `/microblogs/room?room=${noti?.meta[1]?.value}`,
      };
      axios(config)
        .then((response) => {
          if (response.data) {
            setMicroData([response.data])
            setMicroStatus(true)
          }
        })
        .catch(function (error) {
          console.log("error micro chat get ", error);
        });
      }
    } else if(noti.type === "invite-friend-to-event"){
      navigate("/Event", {
        state: {
          item: {
            _id:noti?.meta[1]?.value
        },
      }
      })
    }else if(noti.type === "going-to-event"){
      navigate("/Event", {
        state: {
          item: {
            _id:noti?.meta[2]?.value
        },
      }
      })

    }else {
      setPopupData(noti.meta[1].value)
      setPopupStatus(true)
    }
    // const data = {
    //   id: noti._id
    // }
    // var config = {
    //   method: "POST",
    //   url: Server2 + "/users/UpdateNotificationStatus",
    //   headers: { 'Content-Type': 'application/json' },
    //   data: data
    // };
    // axios(config)
    //   .then((response) => {
    //     GetNoti()
    //   })
    //   .catch(function (error) {
    //     console.log("error Updatenotiftiactionstatus", error);
    //   });
    setToggle(false)
  };

  return (
    <div>
      <div className="notif-box-Main p-0">
        <div className="nb-pointer"></div>
        <div className="text-start rounded-3 overflow-hidden notibox bg-white" >
          <div className="notibox-Header text-white">
            <span className="py-3 fw-bolder fs-6 px-4">Notifications</span>
            <FiX
              className="fs-4 text-white me-3"
              role="button"
              onClick={() => setToggle(false)}
            />
          </div>
          {/* Notifications Div */}
         
          <div id="myDIV" className="notifications "   
          >
            {NotiData === true ? <div style={{ display: "flex", justifyContent: "center", margin: "5%" }}>
              <div className="loading2">
              </div>
            </div>
              :
              NotiList.length <= 0 ?
                <div className="w-100 text-center mt-5"  >
                  <p className="text-dark m-auto fs-5 fw-bold">No Notifications</p></div> :
                NotiList.map((noti, index) => {
                  return (
                    <div
                      key={index}
                      data-notification-id={noti.id}
                      ref={(ref) => (itemref.current = ref)}
                      id={noti?._id}
                      className="d-flex align-items-center rounded-3 px-3"
                      style={{ height:60, cursor: "pointer", backgroundColor: noti.status === true ? "#dee2e6" : "f8f9fa" }}  
                      onClick={() => handleNoti(noti)}
                    >
                      
                      <div className="pe-3 ">
                        <img
                          src={
                            !noti.image
                              ? "/img/upload-photo.png"
                              : noti.image
                          }
                          height={40}
                          style={{width:"40px" , height:"40px" , borderRadius:50 , objectFit:"cover"}}
                          
                          alt="ibouge"
                        />
                      </div>
                      <div 
                      className="py-2 h-100 text-dark d-flex flex-column"
                       style={{}}>
                        <span style={{fontWeight:"600" , fontSize:12 , color:"black"}}>{noti.text}</span>
                        <span style={{paddingTop:3 , fontSize:10 , fontWeight:"300", color:"black"}}>
                        {
                          moment(noti.date).format('MMMM Do, YYYY  h:mm A')
                        }
                        </span>
                      </div>

                    </div>
                  );
                })}
                </div>

          {/* ^ Notifications Div*/}
        </div>
        {/* ^ Main Div */}
      </div>
    </div>
  );
};

export default NotificatioBox;
  // const [currentindex, setCurrentIndex] = useState(0);
  // const [colors, setColors] = useState(["red", "black", "grey", "blue", "green"]);
  
  // const nextIndex = () => {
  //   if (currentindex == colors.length - 1) {
  //     return setCurrentIndex(0)
  //   }
  //   return setCurrentIndex((prev)=>prev+1)
  // };
  // const prevIndex = () => {
  //   if (currentindex === 0) {
  //     return setCurrentIndex(colors.length-1)
  //   }
  //   return setCurrentIndex((prev)=>prev-1)
  // };