import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};
const presistedReducer = persistReducer(persistConfig, rootReducer);
const initialState = {};

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//updated tesing
if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}
const store = createStore(
  // rootReducer,
  presistedReducer,
  initialState,
  // updated testing
  //  applyMiddleware(...middleware)
  // general code
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
export const persistor = persistStore(store);
