import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import { SetUpdateLoc } from "../../../redux/actions/auth/auth.actions";
import { useEffect, useRef, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";

const mapContainerStyle = {
  maxWidth: "300px",
  width: "100%",
  height: "250px",
  borderRadius: "8px",
  margin: "auto",
};
// const LocationMap = ({map , mapContainerRef , setcoords , coords }) => {
const LocationMap = () => {
  const userData = useSelector((state) => state?.auth?.user?.user)
  const dispatch = useDispatch()
  const [coords, setcoords] = useState(null);
  const [popup, setpopup] = useState(false)
  const mapContainerRef = useRef(null);
  const maps = useRef(null);
  useEffect(() => {
    if (maps.current) return;
    maps.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: process.env.REACT_APP_MAPBOX_KEY,
      style: `${process.env.REACT_APP_MAPBOX_STYLE}?key=${process.env.REACT_APP_MAPBOX_API_KEY}`,
        center:[
          userData.location.coordinates[0].coords.lng,
          userData.location.coordinates[0].coords.lat,
        ],
      // zoom: 6,
      minZoom:6,
      maxZoom:9,
    });
    const NavigationControl = new mapboxgl.NavigationControl({
      showCompass:false,
    })
    maps.current.addControl(NavigationControl, "bottom-right");
  maps.current.scrollZoom.disable()
  maps.current.dragPan.disable()
  if(userData.location.coordinates[0].coords != null){
  const marker = new mapboxgl.Marker()
        .setLngLat([
         userData.location.coordinates[0].coords.lng,
          userData.location.coordinates[0].coords.lat,
        ])
        .addTo(maps.current);
        setcoords({
          lng:userData.location.coordinates[0].coords.lng,
          lat:userData.location.coordinates[0].coords.lat
        })
        function onDragEnd(event) {
          setcoords(null)
          const lngLat = event.lngLat;
          const lat = lngLat.lat
          const lng = lngLat.lng
          console.log({ lng , lat },"lng , lat")
          marker.setLngLat([lng , lat])
          setcoords({ lng , lat })
          }
           
          maps.current.on('click', onDragEnd);
        }
    return () => maps.current.remove();

},[]); // eslint-disable-line react-hooks/exhaustive-deps
  const PayloadLoc ={
    email :"",
    coordinates:"",
  }
  const PayloadLocationFunc = () =>{
    PayloadLoc.email= userData.email;
    PayloadLoc.coordinates={coords};
  }
  const AccLoction = () =>{
    PayloadLocationFunc()
    dispatch(SetUpdateLoc(PayloadLoc)).then((res) =>{
      if(res.data){
        setpopup(true)
        setTimeout(() =>{
          setpopup(false)
        },3000)
      }
    })
  } 
  
  return (
    <div>
    <div className="mx-3 pb-3 rounded-3 shadow">
            <div className="general-body">
           
      {/* map start */}
      <div className="map-wrap-s mx-auto">
        <div ref={mapContainerRef} style={mapContainerStyle} className="map-s mt-2" />
      </div>
            </div>
          </div>
          { popup === true ?
          <div style={{backgroundColor:"#d2f8d2" , margin:"10px 20px" , padding:"6px 15%" ,  borderRadius:"8px"}}>
          <BsCheckCircle style={{marginRight:"15px"}} color="#0b4a0b" size={18} />
          <span style={{fontSize:"13px"}}>Location has been updated</span>
          </div>:null
        }
          <div className="s-buttons d-flex pb-3 mx-3 mt-3">
        <button
          className="btn fw-bold text-white shadow w-50 me-2"
          type="button"
          data-bs-toggle="button"
          onClick={() => AccLoction()}
        >
          Save
        </button>
        <button
          className="btn fw-bold text-white shadow w-50 ms-2"
          type="button"
          data-bs-toggle="button"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default LocationMap;