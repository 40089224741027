import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../css/pagination.css";
import { GrFormPrevious } from "react-icons/gr";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import { LoadBoot } from "../../components/LoadBoot";
import { useNavigate } from "react-router-dom";
import MicroblogActivities from "../../Pages/home/AddMicroblogPopup/MicroblogActivities";
import moment from "moment/moment";
import { BsCaretDownFill } from "react-icons/bs";

const PER_PAGE = 25;

const UserActivitesTable = ({ setPopupStatus, setPopupData }) => {
  const [activities, setActivities] = useState([]);
  const [MicroData, setMicroData] = useState([]);
  const [ActivitiesOpen, setActivitiesOpen] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [q, setQ] = useState("");

  //search function
  function searchData() {
    // return activities;
    return activities.filter((user) => {
      if (q === "") {
        return user;
      } else if (user.fname.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user.lname.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user.email.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if (user.activities.type.toLowerCase().includes(q.toLowerCase())) {
        return user;
      } else if ((user?.activities?.activity_platform?user?.activities?.activity_platform:'web').toLowerCase().includes(q.toLowerCase())) {
        return user;
      }
    });
  }
  const handleView = (e) => {
    if (e.activities.type === "Geo Social") {
      setPopupData(e.activities.activity_id);
      setPopupStatus(true);
    } else if (e.activities.type === "Microblogs") {
      var config = {
        method: "GET",
        url: Server2 + `/microblogs/room?room=${e.activities.activity_id}`,
      };
      axios(config)
        .then((response) => {
          if (response.data) {
            setMicroData([response.data]);
            setActivitiesOpen(true);
          }
        })
        .catch(function (error) {
          console.log("error micro chat get ", error);
        });
    } else {
      navigate("/Event", {
        state: {
          item: {
            _id: e.activities.activity_id,
          },
        },
      });
    }
  };
  // Get User data Api
  const getUsersData = () => {
    axios
      .get(Server2 + `/admin/get-activities`)
      .then((response) => {
        console.log(response.data.activities,"response.data.activities")
        response.data.activities.sort(
          (b, c) => new Date(c.activities.time) - new Date(b.activities.time)
        );
        setActivities(response.data.activities);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false);
      });
  };
  // const handleDeletePost = (e) => {
  //   var data = JSON.stringify({
  //     id: e,
  //     from: userData._id,
  //     disable:true
  //   });
  //   var config = {
  //     method: "POST",
  //     url: Server2 + "/status/delete-status",
  //     data: data,
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   axios(config)
  //     .then((response) => {
  //       getUsersData();
  //     })
  //     .catch((error) => {
  //       console.log("er", error);
  //     });
  // };
  // API'S Approved request Starts
  const handleDeletePost = (e) =>{
    const endPoint = e.activities.type === "Geo Social"?"/status/delete-status":e.activities.type === "Microblogs"?"/microblogs/disable":"/events/disable"
      const data = {
        from: userData._id,
        by:userData._id,
        room: e?.activities?.activity_id,
        id: e?.activities?.activity_id,
        eventId: e?.activities?.activity_id,
        disable:true
      };
      let config = {
        method: "post",
        url: Server2 + endPoint,
        data: data,
      };
        axios(config)
          .then((response) => {
            getUsersData();
          })
          .catch((error) => console.error("Error fetching data:", error));
  }
  const handelDeleteRequest = (e) => {
    console.log(e);
    const data = {
      id: e,
    };
    let config = {
      method: "post",
      url: Server2 + "/admin/disable-user",
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "i am res---");
        getUsersData();
      })
      .catch(function (error) {
        console.log(error, "error Delete User");
      });
  };

  useEffect(() => {
    setLoading(true);
    getUsersData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Pagination handel
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //pagination
  const offset = currentPage * PER_PAGE;
  const currentPageData =
    activities.length === 0
      ? null
      : searchData(activities)
          .slice(offset, offset + PER_PAGE)
          .map((user, index) => (
            <tbody key={index}>
              <tr>
                <td>
                  <div className="d-flex px-3 py-1">
                    <div>
                      <img
                        src={
                          user.profile_pic
                            ? user.profile_pic
                            : "/img/upload-photo.png"
                        }
                        className="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm text-capitalize ">
                        {user.fname} {user.lname}
                      </h6>
                      <p className="text-xs text-secondary mb-0">
                        {user.email}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <span
                    className={`badge badge-sm ${
                      user.activities.type === "Geo Social"
                        ? "bg-gradient-primary"
                        : user.activities.type === "Microblogs"
                        ? "bg-gradient-success"
                        : "bg-gradient-secondary"
                    } text-center`}
                  >
                    {user.activities.type}
                  </span>
                </td>
                <td className="text-center">
                  <span
                    className={`badge badge-sm ${
                      user.activities.activity_platform === "web"
                        ? "bg-gradient-primary"
                        : user.activities.activity_platform === "ios"
                        ? "bg-gradient-success"
                        : "bg-gradient-secondary"
                    } text-center text-caplatized`}
                  >
                    {user.activities.activity_platform?user.activities.activity_platform:"web"}
                  </span>
                </td>
               
                <td className="align-middle ">
                  <span style={{ fontSize: "14px", fontWeight: 500 }}>
                    {moment(user.activities.time).format(
                      "MMMM Do, YYYY  h:mm A"
                    )}
                  </span>
                </td>

                {/* <td  className="align-middle ">
            <span className={`badge badge-sm ${user.deactivated === true ? "bg-gradient-danger" : "bg-gradient-success w-50"}`} >{user.deactivated? "Disabled": "Live"}</span>
          </td> */}
                <td className="allign-middle">
                  <div className="d-flex justify-content-center">
                    <Button
                      style={{ background: "#6148a1", border: "none" }}
                      onClick={() => {
                        handleView(user);
                      }}
                    >
                      View{" "}
                      {user.activities.type === "Geo Social"
                        ? "Post"
                        : user.activities.type === "Microblogs"
                        ? "Microblog"
                        : "Event"}
                    </Button>
                    {/* <Button style={{ background: '#6148a1', border: 'none' }} onClick={() => handelAprovedRequest(user)}></Button> */}
                    <Dropdown className="border-0 rounded-2 add-btn fw-bold ms-2">
                      <Dropdown.Toggle
                        className="border-0"
                        id="dropdown-basic"
                        aria-expanded="false"
                        style={{ backgroundColor: "#6148a1" }}
                      >
                        <button
                          type="button"
                          className="bg-transparent border-0 text-white"
                          data-bs-toggle="button"
                        >
                          <spam className="d-inline postition-relative text-white">
                            Take Action
                          </spam>
                        </button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100 text-center">
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            handleDeletePost(user);
                          }}
                          style={{ fontSize: 13 }}
                        >
                          Delete{" "}
                          {user.activities.type === "Geo Social"
                            ? "Post"
                            : user.activities.type === "Microblogs"
                            ? "Microblog"
                            : "Event"}
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            handelDeleteRequest(user?.activities.activity_id);
                          }}
                          style={{ fontSize: 13 }}
                        >
                          Disable User Account
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          ));

  const pageCount = Math.ceil(activities.length / PER_PAGE);

  return (
    <>
      {loading ? (
        <LoadBoot />
      ) : (
        <>
          <div className="container-fluid  ">
            <div className="row ">
              <div className="col-12 ">
                <div className="card  ">
                  <div className="card-header pb-0 d-flex justify-content-between">
                    <h6 className="pt-2"> Global Users Activities </h6>
                    <div className="wrapper  ">
                      <div className="search-wrappe  ">
                        <label htmlFor="search-form">
                          <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="form-control border-0 rounded  admin_search_bar"
                            style={{ backgroundColor: "#ECECEC" }}
                            placeholder=" Type to Search ..."
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body px-0 pt-0 pb-2 task "
                    style={{ height: "73vh", overflow: "auto" }}
                  >
                    <div className="table-responsive p-0">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase">User</th>
                            <th className="">
                              <Dropdown className="">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase ms-5"
                                    style={{ color: "#67748e" }}
                                  >
                                    Activity Type
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("");
                                    }}
                                  >
                                    <span className="fw-bold">All</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("Geo Social");
                                    }}
                                  >
                                    <span className="fw-bold">Geo Socials</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("E-Commerce");
                                    }}
                                  >
                                    <span className="fw-bold">E-Commerce</span>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("Microblogs");
                                    }}
                                  >
                                    <span className="fw-bold">Microblogs</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("Events");
                                    }}
                                  >
                                    <span className="fw-bold">Events</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                            <th className="">
                              <Dropdown className="">
                                <Dropdown.Toggle
                                  className=" border-0"
                                  variant=""
                                  id="dropdown-basic"
                                  aria-expanded="false"
                                >
                                  <span
                                    className="fs-6 fw-bold text-uppercase ms-5"
                                    style={{ color: "#67748e" }}
                                  >
                                    Device{" "}
                                    <BsCaretDownFill size={12}/>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("");
                                    }}
                                  >
                                    <span className="fw-bold">All</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("web");
                                    }}
                                  >
                                    <span className="fw-bold">Web</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("ios");
                                    }}
                                  >
                                    <span className="fw-bold">IOS</span>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setQ("android");
                                    }}
                                  >
                                    <span className="fw-bold">Android</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                            <th className="text-uppercase ps-5">
                              <span className="ms-n3 mt-2 fs-6 fw-bold">
                                Timestamp
                              </span>
                            </th>
                            <th className="text-uppercase">
                              <span className="fs-6 fw-bold">
                                Action
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {currentPageData}
                      </table>
                    </div>

                    {activities.length > 24 ? (
                      <div
                        className=" mt-4"
                        style={{
                          padding: 2,
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handlePageClick}
                          activeClassName={"item active "}
                          breakClassName={"item break-me "}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          disabledClassName={"disabled-page"}
                          marginPagesDisplayed={2}
                          nextClassName={"item next "}
                          nextLabel={
                            <MdOutlineNavigateNext
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                          pageCount={pageCount}
                          pageClassName={"item pagination-page "}
                          pageRangeDisplayed={2}
                          previousClassName={"item previous"}
                          previousLabel={
                            <GrFormPrevious
                              style={{ fontSize: 18, width: 150 }}
                            />
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {ActivitiesOpen && (
            <div className="main-event-bg-dash" style={{ left: 0 }}>
              <MicroblogActivities
                ActivitiesClose={(e) => setActivitiesOpen(e)}
                MicroData={MicroData}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserActivitesTable;
