// markers.js
import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { BsDot } from "react-icons/bs";

const Marker = ({ map, place, type, isActiv,MicroData }) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  // const color =
  //   type == "user"
  //     ? "#0079CB"
  //     : type == "event"
  //     ? "#984399"
  //     : type == "micro"
  //     ? "#E40090"
  //     : "#40B307";
  const back =
    type === "user"
      ? "url(/img/blue.png)"
      : type === "event"
      ? "url(/img/purple.png)"
      : type === "micro"
      ? "url(/img/pink.png)"
      : "url(/img/green.png)";
  const profilePic =
    type === "user"
      ? place?.profile_pic
      : type === "event"
      ? place?.eventImage
      : type === "micro"
      ? place?.microblog_img
      : place?.profile_pic;
  const name =
    type === "user"
      ? place?.fname
      : type === "event"
      ? place?.name
      : type === "micro"
      ? place?.name
      : place?.fname;
  const lname = type === "user" ? place?.lname : "";
  // const online = 
  // type == "user"?
  //    place?.is_online == true?
  //   "3px solid #00FF00":"3px solid yellow":""
  const intrest =
    type === "user"
      ? place?.profile?.interests[0]
        ? place?.profile?.interests[0].length > 20
          ? place?.profile?.interests[0].substring(0, 10) + "..."
          : place?.profile?.interests[0]:""
        :type === "event"?""
        : type === "micro"?""
      : place?.business_category;
  useEffect(() => {
    var trainStationIcon = document.createElement("div");
    trainStationIcon.style.width = "11px";
    trainStationIcon.style.height = "11px";
    // Explicitly set scaleFactor=2 in the call
    // and backgroundSize=contain to get better
    // Marker Icon quality with MapLibre GL
    trainStationIcon.style.backgroundSize = "contain";
    trainStationIcon.style.backgroundImage = back;
    trainStationIcon.style.cursor = "pointer";
    
    if (type) {
      const marker = new mapboxgl.Marker(trainStationIcon)
        .setLngLat([
          place?.location?.coordinates?.[0]?.coords?.lng,
          place?.location?.coordinates?.[0]?.coords?.lat,
        ])
        .addTo(map);
      // var popupIcon = document.createElement("div");
      // popupIcon.style.width = "110px";
      // popupIcon.style.height = "110px";
      // popupIcon.style.backgroundColor = "white";


      const popup = new mapboxgl.Popup({
        className: "apple-popup",
        closeButton: false,
        closeOnClick: false,
      }).setHTML(`
      <aside></aside>
      <img
      id="img"
      src=${profilePic === null || profilePic === undefined ? "img/upload-photo.png" : profilePic}
      alt="user"
      />
      <div>
      <h6>${name}  ${lname}</h6>
      <span>${intrest}</span> 
      </div>
      `);
      const markerDiv = marker.getElement();
      markerDiv.addEventListener("mouseenter", () => {
        marker.setPopup(popup);
        marker.togglePopup();
        document.getElementById("img").style.border=place?.is_online=== true ?"2px solid #A4DA14":"2px solid #FFA800"
      });
      markerDiv.addEventListener("mouseleave", () => marker.togglePopup());
      markerDiv.addEventListener(
        "click",
        type === "user" || type === "business"
          ? () => {
            if(place._id=== userData?._id){
              navigate(`/${userData.type?"business":"personal"}/${userData?._id}`)
            }else{
              navigate(`/Profiless/${place._id}`, {
                state: {
                  item: place._id,
                },
              });
            }
          }
          : type === "micro"
          ? () =>{
          MicroData([place])
          isActiv()
          }
          : type === "event"
          ?() =>{
              navigate("/Event", {
            state: {
              item: place,
            },
          })
        }
          :null
      );
      return () => marker.remove();
    }
  });
  return <div className="Marker"></div>;
};

const Markers = ({ map, places, data, type, isActiv,MicroData }) => {
  return (
    <>
      {data &&
        data.map((place) =>
            <Marker
              key={place._id}
              map={map}
              place={place}
              type={type}
              isActiv={isActiv}
              MicroData={MicroData}
            />
          
        )}
    </>
  );
};

export default Markers;
